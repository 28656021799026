import { useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { AddSpeciesDropdown } from "../../../type/addSpeciesDropDown";
import { SNames } from "../../../type/sNames";
import AddSpeciesForm from "./AddSpeciesForm";
import { useTranslation } from "react-i18next";
import { BotanicalFamily } from "../../../type/botanicalFamily";

const AddSpecies = () => {
  const [dropDown, setDropDown] = useState<AddSpeciesDropdown>({
    abundance_choices_transes: [],
    month_choices_transes: [],
    species_botanical_family_transes: [],
    species_type_transes: [],
  });
  const [sNames, setSNames] = useState<SNames[]>([]);
  const [botanicalFamilies, setBotanicalFamilies] = useState<BotanicalFamily[]>(
    []
  );
  const { t } = useTranslation();
  const { response: baseResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/base_data/",
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: namesResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/scientific_names/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: botanicalFamilyResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/botanical_families/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const {
    fetchData: addSpecies,
    response: addResponse,
    error: addError,
  } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  useEffect(() => {
    if (baseResponse) {
      setDropDown(baseResponse.data);
    }
    if (namesResponse) {
      setSNames(namesResponse.data);
    }
    if (botanicalFamilyResponse) {
      setBotanicalFamilies(botanicalFamilyResponse.data);
    }
  }, [baseResponse, namesResponse, botanicalFamilyResponse]);

  return (
    <AddSpeciesForm
      names={sNames}
      botanicalFamilies={botanicalFamilies}
      dropDown={dropDown}
      send={(data) => {
        addSpecies(data);
      }}
      sendResponse={addResponse}
      sendError={addError}
      textButton={t("Tree Species.Add tree species")}
    />
  );
};

export default AddSpecies;
