import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workshopFilters } from "../../../store";
import { workshopFilterAction } from "../../../store/workshopFilter-slice";
import AppliedItem from "../../Register/RegisterList/AppliedItem";

const WorkshopAppliedFilter = () => {
  const filters = useSelector(workshopFilters);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
      <h1 className="text-ph-gray font-medium text-sm">
        {t("Tree Species.Applied Filters")}
      </h1>
      <div className="flex flex-wrap items-center">
        {(filters.partiFrom !== "" || filters.partiTo !== "") && (
          <AppliedItem
            type={t("PlantingFilter.Participants")}
            value={`${filters?.partiFrom || 0} ${
              filters.partiTo !== "" ? "-" : ""
            } ${filters.partiTo}`}
            removeFilter={() => {
              dispatch(workshopFilterAction.removeParticipantsNum());
              dispatch(workshopFilterAction.setURL());
              dispatch(workshopFilterAction.isFilterSet());
            }}
          />
        )}
        {(filters.dateFrom !== "" || filters.dateTo !== "") && (
          <AppliedItem
            type={t("Workshops.Date")}
            value={`${filters.dateFrom} ${filters.dateTo !== "" ? "-" : ""} ${
              filters.dateTo
            }`}
            removeFilter={() => {
              dispatch(workshopFilterAction.removeDate());
              dispatch(workshopFilterAction.setURL());
              dispatch(workshopFilterAction.isFilterSet());
            }}
          />
        )}
      </div>
    </div>
  );
};

export default WorkshopAppliedFilter;
