import React, { useEffect, useState, Fragment, FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import TableHeader from "./TableHeader";
import Loading from "./Loading";
import { TableThem } from "../../styles/TableThem";

interface GenericTableProps {
  dataList: any[];
  titles: { name: string; value: string }[];
  loading: boolean;
  renderItem: (
    item: any,
    fetchData: () => void,
    lastIndex: number
  ) => JSX.Element;
  fetchData: () => void;
  setOrderingList: (value: string) => void;
  showAction?: boolean;
}

const GenericTable: FC<GenericTableProps> = ({
  dataList,
  titles,
  loading,
  renderItem,
  setOrderingList,
  fetchData,
  showAction = true,
}) => {
  const [ordering, setOrdering] = useState<string>("");

  useEffect(() => {
    setOrderingList(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering, loading]);

  const order = (value: string) => {
    setOrdering(value);
  };

  return (
    <Fragment>
      {dataList.length > 0 ? (
        <TableContainer>
          <ThemeProvider theme={TableThem}>
            <Table>
              <TableHeader
                setOrdering={order}
                titles={titles}
                showAction={showAction}
              />
              <TableBody>
                {dataList.map((item, index) => (
                  <TableRow key={index}>
                    {renderItem(
                      item,
                      fetchData,
                      0 <= dataList.length && dataList.length <= 7
                        ? 0
                        : index >= Math.floor(dataList.length / 2)
                        ? index
                        : -1
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ThemeProvider>
        </TableContainer>
      ) : (
        <Loading loading={loading} />
      )}
    </Fragment>
  );
};

export default GenericTable;
