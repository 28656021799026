import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddBotanicalFamilyForm from "../../BotanicalFamily/AddBotanicalFamily/AddBotanicalFamilyForm";
import Loading from "../../Global/Loading";

const EditIncident = () => {
  const { id } = useParams<any>();
  const { fetchData: editIncident, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/incidents/${id}/edit/`,
    "PATCH",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const {
    response: getEditResponse,
    fetchData: getIncident,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/incidents/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { t } = useTranslation();

  useEffect(() => {
    getIncident({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Incidents.Edit incident")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Incidents.Incidents"), url: "/setting/incidents" },
          { name: t("Incidents.Edit incident"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddBotanicalFamilyForm
              link="/setting/incidents"
              title={t("Incidents.Incident")}
              send={editIncident}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
            />
          </div>
          <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
            <img
              src="/img/Add incident.svg"
              alt="Edit incident"
              className="mt-4 ml-2 xl:ml-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default EditIncident;
