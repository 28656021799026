import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import Loading from "../../Global/Loading";
import CommunityTab from "./CommunityTab";
import Responsible from "../../Responsible/ResponsibleList/Responsible";


const ViewCommunity = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [tab, setTab] = useState<string>("com");


  const {
    fetchData: getCommunities,
    response: breadCrumb,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/communities/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    getCommunities({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Community.View community")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Community.Communities"), url: "/setting/communities" },
          { name: `${breadCrumb?.data?.name}`, url: "" },
          { name: t("Community.View community"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap flex-col mt-4">
          <div className="flex flex-row cursor-pointer">
            <p
              className={`${
                tab === "com"
                  ? "text-ph-btn border-2 border-ph-white bg-ph-white rounded-t-[8px]"
                  : "text-default-text border-2 border-ph-map-bg"
              } py-4 px-2 h-[57px] w-[117px] text-[14px] text-center`}
              onClick={() => setTab("com")}
            >
              {t("Community.Community")}
            </p>
            <p
              className={`${
                tab === "res"
                  ? "text-ph-btn border-2 border-ph-white bg-ph-white rounded-t-[8px]"
                  : "text-default-text border-2 border-ph-map-bg"
              } py-4 px-2 h-[57px] w-[117px] text-[14px] text-center`}
              onClick={() => setTab("res")}
            >
              {t("Register.Responsible")}
            </p>
          </div>
          <div className="bg-ph-white">
            {tab === "com" ? <CommunityTab response={breadCrumb?.data} /> : <Responsible />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCommunity;
