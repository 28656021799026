import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useRef, useState } from "react";
import Divider from "@mui/material/Divider";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Dropdown = () => {
  const [dropClick, isDropClick] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isDropClick(false);
    }
  };

  const toggle = () => {
    isDropClick(!dropClick);
  };

  const englishSelect = () => {
    i18next.changeLanguage("en");
    isDropClick(false);
  };

  const spanishSelect = () => {
    i18next.changeLanguage("es");
    isDropClick(false);
  };

  return (
    <div className="mr-8 w-[86px] mt-4 sm:mt-0">
      <button
        ref={btnRef}
        className="font-normal text-default-text text-sm"
        onClick={toggle}
      >
        {i18n.language === "en" ? "English" : "Spanish"}
        <span className="ml-4 text-ph-icon">
          <FontAwesomeIcon icon={dropClick ? faAngleUp : faAngleDown} />
        </span>
      </button>
      <div ref={divRef} className="absolute z-10">
        <div
          className={`${
            dropClick ? "block" : "hidden"
          } relative bg-white mt-1 w-131 h-159 shadow-md rounded-md`}
        >
          <button
            onClick={englishSelect}
            className="px-8 text-sm flex items-center py-3  mt-1"
          >
            <img
              src="/img/United_Kingdom.svg"
              alt="unitedKingdom"
              className="mr-3"
            />
            English
          </button>
          <Divider />
          <button
            onClick={spanishSelect}
            className="px-8 py-3 text-sm flex items-center"
          >
            <img src="/img/spanish.svg" alt="spanish" className="mr-3" />
            Spanish
          </button>
          <Divider />
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
