import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import { ScientificName } from "../../../type/scientificName";
import useAxios from "../../../hook/useAxios";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import GenericTable from "../../Global/GenericTable";
import ScientificNameTableItem from "./ScientificNameTableItem";

const ScientificNames = () => {
  const [sNames, setsNames] = useState<ScientificName[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/scientific_names/?ordering=${ordering}&page=${page}&search=${sendSearch}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setsNames(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Navbar.Scientific names")}
        </h1>
        <Link
          to="/setting/scientific-names/add-scientific-name"
          className="green-btn px-[23px] py-[10px] text-sm"
        >
          {t("Scientific name.Add scientific name")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Navbar.Scientific names"), url: "" },
        ]}
      />
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          dataList={sNames}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Tree Species.Name"), value: "name" },
            { name: t("Tree Species.Scientific name"), value: "" },
          ]}
          renderItem={(sName, fetchData, lastIndex) => (
            <ScientificNameTableItem
              names={sName}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        {sNames.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-default-text">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + sNames.length
              } ${t("Message.of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default ScientificNames;
