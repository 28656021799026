import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { faArrowTurnDownLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SearchField: React.FC<{
  searchValue: string;
  change: (event: React.ChangeEvent<HTMLInputElement>) => void;
  applySearch: (e: React.FormEvent<HTMLFormElement>) => void;
}> = ({ searchValue, applySearch, change }) => {
  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    applySearch(e as unknown as React.FormEvent<HTMLFormElement>);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 200);
  };

  return (
    <form
      onSubmit={applySearch}
      className="relative transition-all duration-300 ease-in-out"
    >
      <input
        type="text"
        onChange={(e) => change(e)}
        value={searchValue}
        placeholder={t("Navbar.Search") + ""}
        className="py-2 rounded-md mt-2 border border-white w-full focus:outline-none focus:border focus:border-default-highlight text-sm pl-10"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {isInputFocused && (
        <FontAwesomeIcon
          className="text-gray-400 right-4 absolute top-[19px]  hover:text-gray-700 hover:cursor-pointer"
          icon={faArrowTurnDownLeft}
          onClick={handleIconClick}
        />
      )}

      <FontAwesomeIcon
        className={`text-gray-400 absolute left-3 top-[18px]`}
        icon={faMagnifyingGlass}
      />
    </form>
  );
};

export default SearchField;
