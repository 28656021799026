import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddVideosForm from "./AddVideosForm";

const AddVideos = () => {
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/help_videos/new/",
    "POST",
    false,
    "",
    true,
    true
  );

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Help videos.Add help videos")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Help videos.Tools"), url: "" },
          { name: t("Help videos.Help videos"), url: "/tools/help-videos" },
          { name: t("Help videos.Add help videos"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddVideosForm
            send={fetchData}
            sendResponse={response}
            textButton={t("Help videos.Add help videos")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add help video.svg"
            alt="addEvent"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddVideos;
