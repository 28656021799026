import { useState } from "react";
import { Controller } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import dayjs from "dayjs";
import { colors } from "../../styles/colors";
const minDate = dayjs("2000-01-01");

const CustomDate: React.FC<{
  clearError?: any;
  control: any;
  name: any;
  label: string;
  rules?: any;
}> = ({ control, name, label, rules }, props) => {
  const [isDateValid, setIsDateValid] = useState(true);
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            components={{
              OpenPickerIcon: CalendarMonthOutlinedIcon,
            }}
            inputFormat="dd/MM/yyyy"
            label={value === undefined && label}
            onChange={(newValue) => {
              const isValidDate = dayjs(newValue).isAfter(minDate);
              setIsDateValid(isValidDate);

              onChange(newValue);
              if (props?.clearError) {
                props?.clearError(name);
              }
            }}
            value={value || null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: `1px solid ${
                    isDateValid ? colors.gray_white : "red"
                  }`,
                  borderRadius: "4px",
                  "& fieldset": { border: "none" },
                }}
                InputLabelProps={{ shrink: false }}
              />
            )}
            minDate={minDate.toDate()}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default CustomDate;
