import { ThemeProvider } from "@emotion/react";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { communityFilterDelete, communityFilterSet } from "../../../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faFilterList } from "@fortawesome/pro-light-svg-icons";
import { communityFilterAction } from "../../../store/communityFilter-slice";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";

type FormValues = {
  isActive: string;
  department: Department;
  municipality: Department;
};

const CommunityFilterBox = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset, resetField, watch } =
    useForm<FormValues>();
  const isFilterSet = useSelector(communityFilterSet);
  const deleteItem = useSelector(communityFilterDelete);
  const [selectOption, setSelectOption] = useState<{
    municipality: Department[];
    department: Department[];
  }>({ department: [], municipality: [] });
  const departmentValue = watch("department");
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: municipalityRes, fetchData: getMunicipality } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/${departmentValue?.slug}/municipalities/all/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (departmentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          department: departmentRes.data,
        };
      });
    }
    if (municipalityRes) {
        setSelectOption((prevState) => ({
          ...prevState,
          municipality: municipalityRes.data,
        }));
    }
  }, [departmentRes, municipalityRes]);

  useEffect(() => {
    if (departmentValue?.slug) {
      getMunicipality({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue?.slug]);

  const [advanceFilter, isAdvanceFilter] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (deleteItem === "isActive") {
      resetField("isActive");
    } else if (deleteItem === "department") {
      resetField("department");
    } else if (deleteItem === "municipality") {
      resetField("municipality");
    } else if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);

  const applyFilter = (data: FormValues) => {
    dispatch(
      communityFilterAction.setFilter({
        isActive: data?.isActive || "",
        department: data?.department || "",
        municipality: data?.municipality || "",
      })
    );
    dispatch(communityFilterAction.setURL());
    dispatch(communityFilterAction.isFilterSet());
  };

  const clearFilter = () => {
    reset();
    dispatch(communityFilterAction.clearFilter());
    dispatch(communityFilterAction.setURL());
  };
  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <span
          title={t("Tree Species.Advanced filter") + ""}
          className={`${
            advanceFilter ? "text-ph-grayD" : "text-ph-btn"
          } float-right  cursor-pointer`}
          onClick={() => {
            isAdvanceFilter(!advanceFilter);
          }}
        >
          <FontAwesomeIcon
            className="w-[21px] h-[21px]"
            icon={advanceFilter ? faXmark : faFilterList}
          />
        </span>
        <br />
        <form onSubmit={handleSubmit(applyFilter)}>
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px]">{t("Department.Status")}</p>
              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, value } }) => (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink={false}>
                      {!value && `${t("Department.Status")}`}
                    </InputLabel>
                    <Select
                      style={{ height: "38px" }}
                      IconComponent={ExpandMoreIcon}
                      variant="outlined"
                      value={value || null}
                      onChange={(e) => {
                        onChange(e as ChangeEvent<Element>);
                      }}
                      sx={{
                        border: "1px solid #eeecec",
                        borderRadius: "4px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="true">{t("Department.Active")}</MenuItem>
                      <MenuItem value="false">
                        {t("Department.Inactive")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm text-default-text mb-[6px]">
                {t("PlantingFilter.Department")}
              </p>
              <CustomAutocomplete
                control={control}
                name="department"
                selectOptions={selectOption.department}
                placeholder={t("PlantingFilter.Department")}
                getOptionLabel={(option) => option.name}
                onCustomChange={() => {
                  resetField("municipality");
                }}
              />
            </div>
          </div>
          {advanceFilter && (
            <Fragment>
              <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
                <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
                  <p
                    className={`${
                      departmentValue?.slug === "" || !departmentValue?.slug
                        ? "text-ph-light-gray"
                        : "text-default-text"
                    } text-sm mb-[6px] mt-7 sm:mt-0`}
                  >
                    {t("Community.Municipality")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="municipality"
                    selectOptions={selectOption.municipality}
                    placeholder={t("Community.Municipality")}
                    getOptionLabel={(option) => option.name}
                    disabled={
                      departmentValue?.slug === "" || !departmentValue?.slug
                    }
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-ph-input mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}
            <button
              type="submit"
              className=" hover:bg-ph-hover hover:text-white text-ph-btn py-2 px-6 border border-ph-btn text-sm font-medium rounded-3xl"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default CommunityFilterBox;
