import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TranslationField from "../../../type/translationField";
import { useTranslation } from "react-i18next";

const RegisterTableItem: React.FC<{
  name_transes: TranslationField;
  type_transes: TranslationField;
  trees_count: number;
  dead_trees?:number;
}> = ({ name_transes, type_transes, trees_count,dead_trees }) => {
  const { i18n } = useTranslation();
  const name = name_transes?.en === "" ? name_transes.es : name_transes.en;
  return (
    <Fragment>
      <TableCell>{i18n.language === "en" ? name : name_transes.es}</TableCell>
      <TableCell>
        {i18n.language === "en" ? type_transes.en : type_transes.es}
      </TableCell>
      <TableCell align="center">{trees_count}</TableCell>
      <TableCell align="center">{dead_trees}</TableCell>
    </Fragment>
  );
};

export default RegisterTableItem;
