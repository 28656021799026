import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hook/useAxios';
import BreadCrumb from '../../Global/BreadCrumb';
import Loading from '../../Global/Loading';
import DepartmentTab from './DepartmentTab/DepartmentTab';
import MunicipalityTab from './MunicipalityTab/MunicipalityList/MunicipalityTab';

const ViewDepartment = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [tab, setTab] = useState<string>('department');

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/departments/${id}/edit/`,
    'GET',
    false,
    '',
    true,
    true
  );

  useEffect(() => {
    fetchData({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='parent-container'>
      <h1 className='font-semibold text-default-text text-[16px] mt-5'>
        {t('Department.View department')}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t('Navbar.Settings'), url: '' },
          { name: t('Navbar.Departments'), url: '/setting/departments' },
          { name: `${response?.data?.name}`, url: '' },
          { name: t('Department.View department'), url: '' },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className='flex flex-wrap flex-col mt-4'>
          <div className='flex flex-row cursor-pointer'>
            <p
              className={`${
                tab === 'department'
                  ? 'text-ph-btn border-2 border-ph-white bg-ph-white rounded-t-[8px]'
                  : 'text-default-text border-2 border-ph-map-bg'
              } py-4 px-2 h-[57px] w-[117px] text-[14px] text-center`}
              onClick={() => setTab('department')}
            >
              {t('Navbar.Departments')}
            </p>
            <p
              className={`${
                tab === 'municipality'
                  ? 'text-ph-btn border-2 border-ph-white bg-ph-white rounded-t-[8px]'
                  : 'text-default-text border-2 border-ph-map-bg'
              } py-4 px-2 h-[57px] w-[117px] text-[14px] text-center`}
              onClick={() => setTab('municipality')}
            >
              {t('Navbar.Municipalities')}
            </p>
          </div>
          <div className='bg-ph-white flex'>
            {tab === 'department' ? (
              <DepartmentTab response={response} />
            ) : (
              <MunicipalityTab id={id} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewDepartment;
