import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hook/useAxios';
import BreadCrumb from '../../Global/BreadCrumb';
import AddDepartmentForm from '../AddDepartment/AddDepartmentForm';
import Loading from '../../Global/Loading';

const EditDepartment = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/departments/${id}/edit/`,
    'PUT',
    false,
    '',
    true,
    true
  );

  const {
    fetchData: getDepartment,
    response: getEditResponse,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/departments/${id}/edit/`,
    'GET',
    false,
    '',
    true,
    true
  );

  useEffect(() => {
    getDepartment({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='parent-container'>
      <h1 className='font-semibold text-default-text text-[16px] mt-5'>
        {t('Department.Edit department')}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t('Navbar.Settings'), url: '' },
          { name: t('Navbar.Departments'), url: '/setting/departments' },
          { name: t('Department.Edit department'), url: '' },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className='flex flex-wrap justify-between mt-4'>
          <div className=' w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative'>
            <AddDepartmentForm
              send={fetchData}
              sendResponse={response}
              getInfo={getEditResponse}
              textButton={t('Tree Species.Update')}
            />
          </div>
          <div className='w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0'>
            <img
              src='/img/Add department.svg'
              alt='Add scientific name'
              className='mt-4 ml-2 xl:ml-10'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDepartment;
