import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddBotanicalFamilyForm from "../../BotanicalFamily/AddBotanicalFamily/AddBotanicalFamilyForm";

const AddIncident = () => {
  const { t } = useTranslation();
  const { fetchData: addIncident, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/incidents/new/`,
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Incidents.Add incident")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Incidents.Incidents"), url: "/setting/incidents" },
          { name: t("Incidents.Add incident"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddBotanicalFamilyForm
            link="/setting/incidents"
            title={t("Incidents.Incident")}
            send={addIncident}
            sendResponse={response}
            textButton={t("Incidents.Add incident")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add incident.svg"
            alt="Add scientific name"
            className="mt-4 ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default AddIncident;
