import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Municipality } from "../type/municipality";
import { Department } from "../type/department";
import { Community } from "../type/community";
import { ResponsibleNames } from "../type/responsibleNames";
import { Nursery } from "../type/Nursery";
import SpeciesList from "../type/speciesList";

type TaggingToolFilter = {
  community: Community | null | undefined;
  responsible: ResponsibleNames | null | undefined;
  nursery: Nursery | null | undefined;
  species: SpeciesList | null | undefined;
  isUsed: string;
};
const initialState: {
  filterItem: TaggingToolFilter;
  url: string;
  filterSet: boolean;
  deleteFilter:
    | "community"
    | "responsible"
    | "nursery"
    | "species"
    | "isUsed"
    | "";
} = {
  filterItem: {
    community: null,
    responsible: null,
    nursery: null,
    species: null,
    isUsed: "",
  },
  url: "/tags/?",
  filterSet: false,
  deleteFilter: "",
};

const TaggingToolFilterSlice = createSlice({
  name: "TaggingToolFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<TaggingToolFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    setURL: (state) => {
      state.url = `/tags/?used=${state.filterItem.isUsed}&community=${
        state.filterItem.community ? state.filterItem.community.slug : ""
      }&responsible=${
        state.filterItem.responsible ? state.filterItem.responsible.slug : ""
      }&nursery=${
        state.filterItem.nursery ? state.filterItem.nursery.slug : ""
      }&species=${
        state.filterItem.species ? state.filterItem.species.slug : ""
      }`;
    },
    removeCommunity: (state) => {
      state.filterItem.community = null;
      state.deleteFilter = "community";
    },
    removeResponsible: (state) => {
      state.filterItem.responsible = null;
      state.deleteFilter = "responsible";
    },
    removeNursery: (state) => {
      state.filterItem.nursery = null;
      state.deleteFilter = "nursery";
    },
    removeSpecies: (state) => {
      state.filterItem.species = null;
      state.deleteFilter = "species";
    },
    removeIsUsed: (state) => {
      state.filterItem.isUsed = "";
      state.deleteFilter = "isUsed";
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.community = null;
      state.filterItem.responsible = null;
      state.filterItem.nursery = null;
      state.filterItem.species = null;
      state.filterItem.isUsed = "";
    },
    isFilterSet: (state) => {
      if (
        !state.filterItem.community &&
        !state.filterItem.responsible &&
        !state.filterItem.nursery &&
        !state.filterItem.species &&
        !state.filterItem.isUsed
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const TaggingToolFilterAction = TaggingToolFilterSlice.actions;
export default TaggingToolFilterSlice;
