import { Link } from "react-router-dom";

const InvalidPage: React.FC<{
  src: string;
  alt: string;
  header: string;
  link?: { to: string; title: string };
}> = ({ src, alt, header, link }) => {
  return (
    <div className="w-full h-full p-4 bg-white flex flex-col justify-center items-center">
      <img src={src} alt={alt} />
      {link ? (
        <Link
          to={link.to}
          className="bg-ph-btn text-white text-xs rounded-3xl px-6 py-2 hover:bg-ph-hover"
        >
          {link.title}
        </Link>
      ) : (
        <h1 className="text-base sm:text-3xl font-semibold mt-12 text-default-text">
          {header}
        </h1>
      )}
    </div>
  );
};

export default InvalidPage;
