import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "../../Global/slider.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([Navigation, Pagination]);
const WorkshopSlider: React.FC<{
  images: {
    id: number;
    file_address: string;
    thumbnail_address: string;
  }[];
}> = ({ images }) => {
  return (
    <div className="slider-container">
      <Swiper
        navigation
        speed={1000}
        pagination={true}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} style={{ zIndex: "10" }}>
            <img
              src={image.thumbnail_address}
              alt="picture1"
              className="w-full h-[269px] object-contain rounded-md mt-2"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WorkshopSlider;
