import { useTranslation } from "react-i18next";
import BreadCrumb from "../../Global/BreadCrumb";
import { useEffect, useState } from "react";
import EditSlider from "../../Global/EditSlider";
import { AddSpeciesDropdown } from "../../../type/addSpeciesDropDown";
import { SNames } from "../../../type/sNames";
import useAxios from "../../../hook/useAxios";
import { useParams } from "react-router-dom";
import AddSpeciesForm from "../AddTreeSpecies/AddSpeciesForm";
import { BotanicalFamily } from "../../../type/botanicalFamily";
import Loading from "../../Global/Loading";

const EditTreeSpecies = () => {
  const [dropDown, setDropDown] = useState<AddSpeciesDropdown>({
    abundance_choices_transes: [],
    month_choices_transes: [],
    species_botanical_family_transes: [],
    species_type_transes: [],
  });
  const [names, setNames] = useState<SNames[]>([]);
  const [botanicalFamilies, setBotanicalFamilies] = useState<BotanicalFamily[]>(
    []
  );
  const { id } = useParams<any>();
  const { t } = useTranslation();

  const { response: namesResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/scientific_names/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: baseResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/base_data/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: botanicalFamilyResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/botanical_families/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const {
    fetchData: editSpecies,
    response: editResponse,
    error: editError,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/species/${id}/edit/`,
    "PATCH",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const {
    response: getEditResponse,
    fetchData: getTree,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/species/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    getTree({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (baseResponse) {
      setDropDown(baseResponse.data);
    }
    if (namesResponse) {
      setNames(namesResponse.data);
    }
    if (botanicalFamilyResponse) {
      setBotanicalFamilies(botanicalFamilyResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseResponse, namesResponse, botanicalFamilyResponse]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree Species.Edit species")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree Species.Tree species"),
            url: "/setting/tree-species",
          },
          { name: t("Tree Species.Edit species"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddSpeciesForm
              names={names}
              botanicalFamilies={botanicalFamilies}
              dropDown={dropDown}
              send={(data) => {
                editSpecies(data);
              }}
              sendResponse={editResponse}
              sendError={editError}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
            />
          </div>
          <EditSlider
            images={["/img/addSpe1.svg", "/img/addSpe2.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default EditTreeSpecies;
