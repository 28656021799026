import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Status: React.FC<{
  active: boolean;
  isActive: (value: boolean) => void;
}> = ({ active, isActive }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="w-full sm:w-1/2  flex-col flex justify-between">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.Workshop status")}
        </p>
        <div className="rounded-xl w-fit bg-ph-input text-ph-light-gray text-sm border border-ph-iborder flex items-center">
          <button
            type="button"
            onClick={() => {
              isActive(false);
            }}
            className={`${
              !active &&
              "bg-white text-default-highlight shadow-[0_0_2px_rgba(0,0,0,0.16)]"
            } rounded-xl px-2 py-1`}
          >
            {t("Department.Inactive")}
          </button>
          <button
            type="button"
            onClick={() => {
              isActive(true);
            }}
            className={`${
              active &&
              "bg-default-highlight text-white shadow-[0_0_2px_rgba(0,0,0,0.16)]"
            } px-2 rounded-xl py-1`}
          >
            {t("Department.Active")}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Status;
