import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import EditSlider from "../../Global/EditSlider";
import PlantingView from "./PlantingView";
import Loading from "../../Global/Loading";

const ViewPlanting = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();

  const {
    fetchData: getEvent,
    response: getEditResponse,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/plantings/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    getEvent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Plantings.View planting")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Plantings.Plantings"), url: "/plantings" },
          { name: t("Plantings.View planting"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <PlantingView getInfo={getEditResponse} />
          <EditSlider
            images={["/img/Add Event.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default ViewPlanting;
