import { useTranslation } from "react-i18next";

const ScientificName: React.FC<{
  sName: string;
  index: number;
  sNameChange: (index: number, value: string) => void;
}> = ({ sName, index, sNameChange }) => {
  const { t } = useTranslation();
  return (
    <div>
      <input
        onChange={(e) => {
          sNameChange(index, e.target.value);
        }}
        value={sName}
        type="text"
        className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
        placeholder={t("Tree Species.Scientific name") + ""}
      />
    </div>
  );
};

export default ScientificName;
