import axios from "axios";
import { useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Footer from "../Footer";
import Logo from "./Logo";

const CheckEmail: React.FC<{ email: string }> = ({ email }) => {
  const [recaptchToken, setRecaptchToken] = useState<string>("");
  const { i18n, t } = useTranslation();
  const onVerify = useCallback((token: string) => {
    setRecaptchToken(token);
  }, []);
  const sendEmail = () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/forget-password/",
        {
          email: email,
          google_recaptcha: recaptchToken,
        },
        {
          headers: {
            "Content-Type": `application/json`,
            "Accept-Language": i18n.language,
          },
        }
      )
      .then(function (response) {
        toast.success(
          t(
            "Login.Another email has been sent to you. Please also check the Spam/Junk folder."
          )
        );
      })
      .catch(function (err) {
        if (err.response.status === 400) {
          toast.error(t("Message.Our system thinks you might be a robot!"));
        }
      });
  };
  return (
    <div className="bg-white w-[451px] h-fit shadow-lg rounded-md text-sm p-9 relative">
      <Logo />
      <h1 className="font-semibold text-center mt-8 text-default-text">
        {t("Login.Check your email")}
      </h1>
      <p className="text-center mt-9 text-default-text">
        {t("Login.If you have entered a registered email")},<br />
        {t("Login.you should have received an email")}.
      </p>
      <p className="text-center mt-6 text-default-text">
        {t("Login.Please check your email and follow")} <br />
        {t("Login.the instructions.")}
      </p>
      <div className="mt-16 text-center login">
        <GoogleReCaptcha onVerify={onVerify} />
        <button
          onClick={sendEmail}
          type="button"
          className="text-ph-hover font-semibold"
        >
          {t("Login.Didn’t receive any emails?")}
        </button>
      </div>

      <div className="text-center mt-14">
        <Footer />
      </div>
    </div>
  );
};

export default CheckEmail;
