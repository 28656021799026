import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { plantingFilters } from "../../../store";
import AppliedItem from "../../Register/RegisterList/AppliedItem";
import AppliedCollaborator from "../../Register/RegisterList/AppliedCollaborator";
import { plantingFilterAction } from "../../../store/plantingFilter-slice";

const PlantingAppliedFilter = () => {
  const filters = useSelector(plantingFilters);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
      <h1 className="text-ph-gray text-sm">
        {t("Tree Species.Applied Filters")}
      </h1>
      <div className="flex flex-wrap items-center font-medium">
        {(filters.dateFrom !== "" || filters.dateTo !== "") && (
          <AppliedItem
            type={t("Plantings.Date of planting")}
            value={`${filters.dateFrom} ${filters.dateTo !== "" ? "-" : ""} ${
              filters.dateTo
            }`}
            removeFilter={() => {
              dispatch(plantingFilterAction.removeDate());
              dispatch(plantingFilterAction.setURL());
              dispatch(plantingFilterAction.isFilterSet());
            }}
          />
        )}
        {(filters.treeFrom !== "" || filters.treeTo !== "") && (
          <AppliedItem
            type={t("Plantings.Trees")}
            value={`${filters?.treeFrom || 0} ${
              filters.treeTo !== "" ? "-" : ""
            } ${filters.treeTo}`}
            removeFilter={() => {
              dispatch(plantingFilterAction.removeTree());
              dispatch(plantingFilterAction.setURL());
              dispatch(plantingFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.department && (
          <AppliedItem
            type={t("PlantingFilter.Department")}
            value={filters.department?.name}
            removeFilter={() => {
              dispatch(plantingFilterAction.removeDepartment());
              dispatch(plantingFilterAction.setURL());
              dispatch(plantingFilterAction.isFilterSet());
            }}
          />
        )}
        {(filters.partiFrom !== "" || filters.partiTo !== "") && (
          <AppliedItem
            type={t("PlantingFilter.Participants")}
            value={`${filters?.partiFrom || 0} ${
              filters.partiTo !== "" ? "-" : ""
            } ${filters.partiTo}`}
            removeFilter={() => {
              dispatch(plantingFilterAction.removeParticipants());
              dispatch(plantingFilterAction.setURL());
              dispatch(plantingFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.collaboratorType && (
          <AppliedItem
            type={t("Plantings.Organizer type")}
            value={`${
              filters.collaboratorType === "ind" ? t("Register.Individual") : ""
            } ${
              filters.collaboratorType === "ins"
                ? t("Register.Institution")
                : ""
            } ${
              filters.collaboratorType === "psa" ? t("Register.PSA group") : ""
            }`}
            removeFilter={() => {
              dispatch(plantingFilterAction.removeCollaboratorType());
              dispatch(plantingFilterAction.setURL());
              dispatch(plantingFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.collaborator.length > 0 && (
          <AppliedCollaborator
            type={t("Plantings.Organizer name")}
            value={filters.collaborator}
            removeFilter={() => {
              dispatch(plantingFilterAction.removeCollaborator());
              dispatch(plantingFilterAction.setURL());
              dispatch(plantingFilterAction.isFilterSet());
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PlantingAppliedFilter;
