import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileInfo } from "../../../store";
import { faUserGear } from "@fortawesome/pro-regular-svg-icons";

const UserActionItem: React.FC<{
  editLink: string;
  deleteAction: () => void;
  index: boolean;
  scroll: boolean;
  rootAdminAlert: () => void;
  userAdmin: boolean;
}> = ({ editLink, deleteAction, index, scroll, rootAdminAlert, userAdmin }) => {
  const [showAction, isShowAction] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const toggleAction = () => {
    isShowAction(!showAction);
  };

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowAction(false);
    }
  };
  return (
    <div className="relative">
      <button
        ref={btnRef}
        onClick={toggleAction}
        className="text-ph-gray px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {showAction && (
        <div
          ref={divRef}
          className={`absolute z-10 w-fit right-1 py-3 
          } grid-flow-col text-left shadow-lg rounded-md bg-white px-4 ${
            scroll
              ? "top-[20px]"
              : "bottom-[0px]" && index
              ? "bottom-[20px]"
              : "top-[20px]"
          }`}
        >
          {profile.is_root_admin && userAdmin && (
            <button
              onClick={() => rootAdminAlert()}
              className="text-sm text-default-text flex items-center py-[5px] w-[200px]"
            >
              <FontAwesomeIcon className="mr-2" icon={faUserGear} />
              {t("Users.Assign root admin")}
            </button>
          )}

          <Fragment>
            <div className="w-[184px]">
              <Link
                to={editLink!!}
                className="text-sm text-default-text flex items-center py-[5px]"
              >
                <FontAwesomeIcon className="mr-2" icon={faPencil} />
                {t("PlantingFilter.Edit")}
              </Link>
              <button
                onClick={() => {
                  isShowAction(false);
                  deleteAction();
                }}
                className="text-sm text-default-text flex items-center py-[5px]"
              >
                <FontAwesomeIcon icon={faTrashCan} className="mr-2" />{" "}
                {t("PlantingFilter.Delete")}
              </button>
            </div>
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default UserActionItem;
