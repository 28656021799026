import { Chrono } from "react-chrono";
import { GrowthHistory } from "../../type/growthHistory";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { colors } from "../../styles/colors";

const Timeline: React.FC<{
  activeItemIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  onItemSelected: (record: { id: string; title: string }) => void;
  values: GrowthHistory[];
}> = ({ activeItemIndex, setSelectedIndex, values, onItemSelected }) => {
  const [itemWidth, setItemWidth] = useState<number>();
  const [key, setKey] = useState<string>("key");

  // Below function gets histories and extracts the dates (with yyyy-mm-dd format)
  // into list of date objects (with dd months yyyy format).
  type item = { id: string; title: string };
  const getTitles = (values: GrowthHistory[]) => {
    const items: item[] = [];
    for (let i = 0; i < values.length; i++) {
      const date = new Date(values[i].date);
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      items.push({
        id: values[i].slug,
        title: date.toLocaleDateString("en-GB", options),
      });
    }
    return items;
  };

  useEffect(() => {
    const widthLookup: { [key: number]: number } = {
      1: 1030,
      2: 515,
      3: 345,
      4: 250,
      5: 210,
      6: 175,
      7: 150,
      8: 130,
    };

    // Fallback width for cases where values.length > 8
    const defaultWidth = 115;

    const itemWidth = widthLookup[values.length] || defaultWidth;
    setItemWidth(itemWidth);

    setKey(`${itemWidth}-${values.length}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handlePrev = () => {
    if (activeItemIndex !== 0) {
      const newIndex = activeItemIndex - 1;
      setSelectedIndex(newIndex);
    }
  };

  const handleNext = () => {
    if (activeItemIndex !== values.length - 1) {
      const newIndex = activeItemIndex + 1;
      setSelectedIndex(newIndex);
    }
  };

  return (
    <div>
      <div className="pt-1">
        <div className="timeline-container">
          <button onClick={handlePrev}>
            <FontAwesomeIcon
              className="text-ph-light-gray pt-[52px] pr-4"
              icon={faChevronLeft}
            />
          </button>
          <Chrono
            items={getTitles(values)}
            mode="HORIZONTAL"
            disableNavOnKey
            allowDynamicUpdate
            activeItemIndex={activeItemIndex}
            onItemSelected={onItemSelected}
            key={key}
            itemWidth={itemWidth}
            theme={{
              primary: colors.btn_hover,
              secondary: colors.white,
              titleColor: colors.light_gray,
              titleColorActive: colors.btn_hover,
            }}
            fontSizes={{
              title: "14px",
            }}
          />
          <button onClick={handleNext}>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-ph-light-gray pt-[52px] pl-4"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
