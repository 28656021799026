import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import { Workshops } from "../../../type/workshops";
import WorkshopParticipants from "./WorkshopParticipants";
import ActionItem from "../../Global/ActionItem";
import { Link } from "react-router-dom";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const WorkshopTableItem: React.FC<{
  workshopItem: Workshops;
  fetchData: () => void;
  lastIndex: number;
}> = ({ workshopItem, lastIndex, fetchData: propFetchData }) => {
  const { t, i18n } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/workshop/${workshopItem.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  const name =
    workshopItem.name_transes.en === ""
      ? workshopItem.name_transes.es
      : workshopItem.name_transes.en;
  const desc =
    workshopItem.description_transes.en === ""
      ? workshopItem.description_transes.es
      : workshopItem.description_transes.en;
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  return (
    <Fragment>
      <TableCell align="left">
        <Link
          className="hover:text-ph-hover"
          to={`/workshops/view-workshop/${workshopItem.slug}`}
        >
          <div
            title={i18n.language === "en" ? name : workshopItem.name_transes.es}
            className="name-overflow"
          >
            {i18n.language === "en" ? name : workshopItem.name_transes.es}
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">
        {workshopItem.date_from} <br />
        {workshopItem.time_from}
      </TableCell>
      <TableCell align="left">
        {workshopItem.date_to}
        <br />
        {workshopItem.time_to}
      </TableCell>
      <TableCell align="left">{workshopItem.department}</TableCell>
      <TableCell align="left">{workshopItem.municipality}</TableCell>
      <TableCell align="left">{workshopItem.community}</TableCell>
      <TableCell align="left">
        <div
          title={
            i18n.language === "en" ? desc : workshopItem.description_transes.es
          }
          className="desc-tooltip"
        >
          {i18n.language === "en" ? desc : workshopItem.description_transes.es}
        </div>
      </TableCell>
      <TableCell align="left">
        <WorkshopParticipants
          totalNum={workshopItem.participants_total}
          others={workshopItem.other_participants}
          psaS={workshopItem.psa_students}
          psaT={workshopItem.psa_tutors}
          schoolS={workshopItem.school_students}
          schoolT={workshopItem.school_tutors}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`/workshops/edit-workshop/${workshopItem.slug}`}
          deleteAction={deleteAction}
          view={t("Workshops.View workshop")}
          viewLink={`/workshops/view-workshop/${workshopItem.slug}`}
          historyLink=""
          viewHistory=""
          index={index}
          scroll={scroll}
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Workshops.Workshop")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default WorkshopTableItem;
