import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import PlantingTableContent from './WorkshopTableContent';
import { colors } from '../../../styles/colors';

const WorkshopStatistics: React.FC<{ response: any }> = ({ response }) => {
  const [workshopStatistics, setWorkshopStatistics] = useState<{
    department_count: number;
    total_workshop: number;
    total_participants: number;
    psa_students: number;
    school_students: number;
    psa_tutors: number;
    school_tutors: number;
    other_participants: number;
    workshop_list: {
      department_name: string;
      workshop_count: number;
      total_participants: number;
    }[];
  }>({
    department_count: 0,
    total_workshop: 0,
    total_participants: 0,
    psa_students: 0,
    school_students: 0,
    psa_tutors: 0,
    school_tutors: 0,
    other_participants: 0,
    workshop_list: [],
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (response) {
      setWorkshopStatistics(response?.data);
    }
  }, [response]);
  const data = {
    labels: [
      t('Workshops.Other participants'),
      t('Workshops.School teachers'),
      t('Workshops.PSA tutors'),
      t('Workshops.School students'),
      t('Workshops.PSA students'),
    ],
    datasets: [
      {
        data: [
          workshopStatistics?.other_participants,
          workshopStatistics?.school_tutors,
          workshopStatistics?.psa_tutors,
          workshopStatistics?.school_students,
          workshopStatistics?.psa_students,
        ],
        backgroundColor: [
          '#FFC1C5',
          '#B9E2FF',
          '#CDC4F0',
          '#F4DB7D',
          '#B9E0C5',
        ],
        hoverBackgroundColor: [
          '#FFC1C5',
          '#B9E2FF',
          '#CDC4F0',
          '#F4DB7D',
          '#B9E0C5',
        ],
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        titleColor: (tooltipItem: any) => {
          if (tooltipItem.tooltipItems[0]) {
            let color = colors.red_warning;
            if (tooltipItem.tooltipItems[0].dataIndex === 0) {
              color = colors.red_warning;
            } else if (tooltipItem.tooltipItems[0].dataIndex === 1) {
              color = '#72AEFF';
            } else if (tooltipItem.tooltipItems[0].dataIndex === 2) {
              color = '#8269D7';
            } else if (tooltipItem.tooltipItems[0].dataIndex === 3) {
              color = colors.default_highlight;
            } else if (tooltipItem.tooltipItems[0].dataIndex === 4) {
              color = '#7EB48B';
            }
            return color;
          }
        },
        TitleFont: {
          size: 14,
        },
        backgroundColor: (tooltipItem: any) => {
          if (tooltipItem.tooltipItems[0]) {
            let color = '#FEEAEC';
            if (tooltipItem.tooltipItems[0].dataIndex === 0) {
              color = '#FEEAEC';
            } else if (tooltipItem.tooltipItems[0].dataIndex === 1) {
              color = '#F0F8FE';
            } else if (tooltipItem.tooltipItems[0].dataIndex === 2) {
              color = '#F5F2FE';
            } else if (tooltipItem.tooltipItems[0].dataIndex === 3) {
              color = '#FCF1C7';
            } else if (tooltipItem.tooltipItems[0].dataIndex === 4) {
              color = '#EEF8F1';
            }
            return color;
          }
        },
        bodyFont: {
          size: 14,
          weight: 'bold',
        },
        callbacks: {
          labelTextColor: function (context: any) {
            let bodyColor = colors.red_warning;
            if (context.dataIndex === 0) {
              bodyColor = colors.red_warning;
            } else if (context.dataIndex === 1) {
              bodyColor = '#72AEFF';
            } else if (context.dataIndex === 2) {
              bodyColor = '#8269D7';
            } else if (context.dataIndex === 3) {
              bodyColor = colors.default_highlight;
            } else if (context.dataIndex === 4) {
              bodyColor = '#7EB48B';
            }
            return bodyColor;
          },
        },
      },
    },
    cutout: '52%',
    rotation: 270,
    circumference: 180,
  };

  const data1 = {
    labels: [t('Statistics.Total participants')],
    datasets: [
      {
        data: [workshopStatistics?.total_participants],
        backgroundColor: ['#FFC889'],
        hoverBackgroundColor: ['#FFC889'],
      },
    ],
  };

  const options1: any = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        titleColor: '#FE9D2B',
        TitleFont: {
          size: 14,
        },
        backgroundColor: '#FFDCB4',
        bodyColor: '#FE9D2B',
        bodyFont: {
          size: 14,
          weight: 'bold',
        },
      },
    },
    cutout: '84%',
    rotation: 270,
    circumference: 180,
  };

  return (
    <Fragment>
      <h1 className='font-semibold text-default-text text-sm'>
        {t('Workshops.Workshops')}
      </h1>
      <div className='flex flex-wrap justify-between mt-5'>
        <div className='w-full sm:w-[26.42%] bg-white rounded-md flex flex-wrap flex-col text-center py-10 justify-between'>
          <div>
            <p className='text-sm text-ph-toggle-text-green font-bold'>
              {workshopStatistics.department_count
                ? workshopStatistics.department_count
                : 0}
            </p>
            <p className='text-sm text-ph-toggle-text-green font-medium'>
              {t('Navbar.Departments')}
            </p>
          </div>
          <div className='mt-8 sm:mt-[93px]'>
            <p className='text-sm text-[#8268D7] font-bold'>
              {workshopStatistics.total_workshop
                ? workshopStatistics.total_workshop
                : 0}
            </p>
            <p className='text-sm text-[#8268D7] font-medium'>
              {t('Workshops.Workshops')}
            </p>
          </div>
          <div className='mt-8 sm:mt-[93px]'>
            <p className='text-sm text-ph-orange-alert font-bold '>
              {workshopStatistics.total_participants
                ? workshopStatistics.total_participants
                : 0}
            </p>
            <p className='text-sm text-ph-orange-alert font-medium'>
              {t('PlantingFilter.Participants')}
            </p>
          </div>
        </div>
        <div className='w-full sm:w-[71.8%] bg-white rounded-md body pb-3 relative'>
          <div className=' w-[280px] m-auto absolute right-1/2 top-0 transform translate-x-1/2 sm:translate-y-[-50px] translate-y-[-85px]'>
            <Doughnut data={data1} options={options1} />
          </div>
          <div className='w-[221px] m-auto relative mt-[-40px] sm:mt-[-5px]'>
            <Doughnut data={data} options={options} />
          </div>

          <div className='flex items-center text-sm justify-center mt-[-20px]'>
            <div className='w-[10px] h-[24px] bg-[#FFC889] rounded-md mr-2'></div>
            <p className='w-[160px]'>{t('Statistics.Total participants')}</p>
            <p className='ml-4 w-8'>
              {workshopStatistics.total_participants
                ? workshopStatistics.total_participants
                : 0}
            </p>
          </div>

          <div className='flex items-center text-sm justify-center mt-2'>
            <div className='w-[10px] h-[24px] bg-[#B9E0C5] rounded-md mr-2'></div>
            <p className='w-[160px]'>{t('Workshops.PSA students')}</p>
            <p className='ml-4 w-8'>
              {workshopStatistics.psa_students
                ? workshopStatistics.psa_students
                : 0}
            </p>
          </div>

          <div className='flex items-center text-sm justify-center mt-2'>
            <div className='w-[10px] h-[24px] bg-[#F4DB7D] rounded-md mr-2'></div>
            <p className='w-[160px]'> {t('Workshops.School students')}</p>
            <p className='ml-4 w-8'>
              {workshopStatistics.school_students
                ? workshopStatistics.school_students
                : 0}
            </p>
          </div>

          <div className='flex items-center text-sm justify-center mt-2'>
            <div className='w-[10px] h-[24px] bg-[#CDC4F0] rounded-md mr-2'></div>
            <p className='w-[160px]'>{t('Workshops.PSA tutors')}</p>
            <p className='ml-4 w-8'>
              {workshopStatistics.psa_tutors
                ? workshopStatistics.psa_tutors
                : 0}
            </p>
          </div>

          <div className='flex items-center text-sm justify-center mt-2'>
            <div className='w-[10px] h-[24px] bg-[#B9E2FF] rounded-md mr-2'></div>
            <p className='w-[160px]'>{t('Workshops.School teachers')}</p>
            <p className='ml-4 w-8'>
              {workshopStatistics.school_tutors
                ? workshopStatistics.school_tutors
                : 0}
            </p>
          </div>

          <div className='flex items-center text-sm justify-center my-2'>
            <div className='w-[10px] h-[24px] bg-[#FFC1C5] rounded-md mr-2'></div>
            <p className='w-[160px]'>{t('Workshops.Other participants')}</p>
            <p className='ml-4 w-8'>
              {workshopStatistics.other_participants
                ? workshopStatistics.other_participants
                : 0}
            </p>
          </div>
        </div>
      </div>

      <div className='w-full mt-2 bg-white rounded-md'>
        {workshopStatistics?.workshop_list && (
          <PlantingTableContent workshops={workshopStatistics?.workshop_list} />
        )}
      </div>
    </Fragment>
  );
};

export default WorkshopStatistics;
