import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";

const WorkshopTableItem: React.FC<{
  department_name: string;
  workshop_count: number;
  total_participants: number;
}> = ({ department_name, workshop_count, total_participants }) => {
  return (
    <Fragment>
      <TableCell align="left">{department_name}</TableCell>
      <TableCell align="center">{workshop_count}</TableCell>
      <TableCell align="center">{total_participants}</TableCell>
    </Fragment>
  );
};

export default WorkshopTableItem;
