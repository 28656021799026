import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faCircleCheck,
  faTrashCan,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import Timeline from "../Timeline";
import AddGrowthHistory from "../AddHistory/AddGrowthHistory";
import EditHistory from "../EditHistory/EditHistory";
import { GrowthHistory } from "../../../type/growthHistory";
import SpeciesList from "../../../type/speciesList";
import FileResource from "../../Global/FileResource";
import HistoryBreadcrumb from "../HistoryBreadcrumb";
import { useSelector } from "react-redux";
import { profileInfo } from "../../../store";
import Loading from "../../Global/Loading";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const ViewGrowthHistory: React.FC<{}> = () => {
  const [histories, setHistories] = useState<GrowthHistory[]>([]);
  const [isAddView, setIsAddView] = useState<boolean>(false);
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [activeDate, setActiveDate] = useState<string>("");
  const [isNew, setIsNew] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectOption, setSelectOption] = useState<{
    growth_stage: SpeciesList[];
    incident: SpeciesList[];
  }>({ growth_stage: [], incident: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const [incidentsHeight, setIncidentsHeight] = useState(0);
  const [observationHeight, setObservationHeight] = useState(0);
  const [resourcesHeight, setResourcesHeight] = useState(0);
  const profile = useSelector(profileInfo);
  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const { response: getListResponse, fetchData: getHistory } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/histories/`,
    "GET",
    true,
    "",
    true,
    false
  );

  const { response: growthStageRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/growth_stages/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "GET",
    true,
    "",
    false,
    false
  );

  const { fetchData: getPlantingId, response: breadCrumbResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${getEditResponse?.data?.planting_slug}/breadcrumb-data/?register_slug=${id}`,
    "GET",
    false,
    "",
    false,
    true
  );

  const { response: deleteR, fetchData: deleteHistory } = useAxios(
    "",
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    false,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      getPlantingId({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditResponse]);

  useEffect(() => {
    if (getListResponse) {
      setHistories(getListResponse.data);
      setLoading(false);
    }
    if (growthStageRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          growth_stage: growthStageRes.data,
        };
      });
    }
    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incident: incidentRes.data,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListResponse, growthStageRes, incidentRes]);

  useEffect(() => {
    const incidentsContainer = document.getElementById("incidentsContainer");
    if (incidentsContainer) {
      const height = incidentsContainer.clientHeight;
      setIncidentsHeight(height);
    }
  }, [histories, []]);

  useEffect(() => {
    const observationContainer = document.getElementById(
      "observationContainer"
    );
    if (observationContainer) {
      const height = observationContainer.clientHeight;
      setObservationHeight(height);
    }
  }, [histories, []]);

  useEffect(() => {
    const resourcesContainer = document.getElementById("resourcesContainer");
    if (resourcesContainer) {
      const height = resourcesContainer.clientHeight;
      setResourcesHeight(height);
    }
  }, [histories, []]);

  useEffect(() => {
    setSelectedIndex(histories.length - 1);
  }, [histories]);

  useEffect(() => {
    const date = new Date(activeDate);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    handleRecordClick({ title: date.toLocaleDateString("en-GB", options) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histories]);

  useEffect(() => {
    if (deleteR) {
      getHistory({});
      if (getListResponse) {
        setHistories(getListResponse.data);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const addButtonHandler = () => {
    setIsAddView(true);
    setIsEditView(false);
  };

  // Below function gets the date with yyyy-mm-dd format and convert it into dd/mm/yyyy format.
  const convertDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  // Below function gets the date with dd months yyyy format and convert it into yyyy-mm-dd format.
  const parseDate = (formattedDate: string): string => {
    const date = new Date(formattedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleActiveItemChange = (newActiveItem: string) => {
    setActiveDate(newActiveItem);
    setIsNew(true);
  };

  const handleRecordClick = (record: { title: string }) => {
    if (isNew && !isEdit) {
      const index = histories.findIndex(
        (history) => history.date === activeDate
      );
      if (index !== -1) {
        setSelectedIndex(index);
        setIsNew(false);
      }
    } else if (!isNew && isEdit) {
      const index = histories.findIndex(
        (history) => history.date === parseDate(record.title)
      );
      setIsEdit(false);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    } else {
      const index = histories.findIndex(
        (history) => history.date === parseDate(record.title)
      );
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  };

  const deleteHandler = () => {
    if (histories[selectedIndex].is_deletable) {
      setLoading(true);
      deleteHistory(
        { id: histories[selectedIndex].id },
        process.env.REACT_APP_API_URL +
          `/api/admin/registers/histories/${histories[selectedIndex].id}/delete/`
      );
      isShowDelete(false);
    } else {
      toast.error(`${t("Message.Not allowed delete register history")}`);
    }
  };

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  return (
    <div className="parent-container">
      <HistoryBreadcrumb breadCrumb={breadCrumbResponse} />
      <div className="flex flex-wrap justify-center mt-4">
        <div className="w-full h-fit sc:w-[1200px] bg-ph-white rounded-md p-7 relative">
          <div>
            {profile.role !== "V" && (
              <button
                type="button"
                onClick={addButtonHandler}
                className="green-btn text-sm px-6 py-2 float-right font-medium"
              >
                {t("Growth history.Add tree growth history")}
              </button>
            )}

            <br />
            {loading ? (
              <Loading loading={true} />
            ) : (
              <Fragment>
                {histories.length > 0 && (
                  <Timeline
                    activeItemIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    onItemSelected={handleRecordClick}
                    values={histories}
                  />
                )}
                {histories.length === 0 && !isAddView && !isEditView && (
                  <Loading loading={false} />
                )}
              </Fragment>
            )}
          </div>
          {!isAddView &&
            !isEditView &&
            histories.length > 0 &&
            histories[selectedIndex] && (
              <div className="sm:px-[50px]">
                <div className="w-full h-fit sc:w-[500px] mx-auto">
                  <p className="text-base text-ph-light-gray font-bold pb-6">
                    {convertDate(histories[selectedIndex].date)}
                    {profile.role !== "V" && (
                      <span title={t("Message.Edit") + ""}>
                        <button
                          type="button"
                          onClick={() => {
                            setIsEditView(true);
                          }}
                          className="text-sm text-default-text items-center pl-[22px]"
                        >
                          <FontAwesomeIcon
                            className="mr-2 text-default-text hover:text-ph-hover"
                            icon={faPencil}
                            size="sm"
                          />
                        </button>
                      </span>
                    )}
                    {profile.role !== "V" && profile.role !== "O" && (
                      <span title={t("PlantingFilter.Delete") + ""}>
                        <button
                          type="button"
                          onClick={() => {
                            deleteAction();
                          }}
                          className="text-sm text-default-text items-center pl-[13px]"
                        >
                          <FontAwesomeIcon
                            className="mr-2 text-default-text hover:text-ph-red-warning"
                            icon={faTrashCan}
                            size="sm"
                          />
                        </button>
                      </span>
                    )}
                  </p>
                </div>
                <div className="flex flex-wrap justify-center">
                  <div className="w-full h-fit sc:w-[500px] bg-ph-bg-white rounded-md px-6 py-2 relative">
                    <div className="flex">
                      <div>
                        <p className="text-sm text-default-text w-40 py-3">
                          {t("Register.Tree height")}{" "}
                          <span className="text-ph-gray-text">(cm)</span>
                        </p>
                        <p className="text-sm text-default-text w-40 py-3">
                          {t("Register.Diameter")}{" "}
                          <span className="text-ph-gray-text">(mm)</span>
                        </p>
                        <p className="text-sm text-default-text w-40 py-3">
                          {t("Register.Age")}
                        </p>
                        <p className="text-sm text-default-text w-40 py-3">
                          {t("Growth stages.Growth stage")}
                        </p>

                        <p className="text-sm text-default-text w-40 py-3">
                          {t("Incidents.Incident")}
                        </p>
                        <p
                          className="text-sm text-default-text w-40 py-3"
                          style={{
                            paddingTop: `${
                              incidentsHeight >= 12 ? incidentsHeight - 30 : 12
                            }px`,
                            paddingBottom: `${
                              incidentsHeight <= 12 ? 12 : null
                            }px`,
                          }}
                        >
                          {t("Growth history.Dead")}
                        </p>
                        <p className="text-sm text-default-text w-40 py-3">
                          {t("Growth history.Observation")}
                        </p>
                        <p
                          className="text-sm text-default-text w-40"
                          style={{
                            paddingTop: `${
                              observationHeight >= 12
                                ? observationHeight - 22
                                : 12
                            }px`,
                            paddingBottom: `${
                              observationHeight <= 12 ? 12 : null
                            }px`,
                          }}
                        >
                          {t("Growth history.Relevant resources")}
                        </p>
                        <p
                          className="text-sm text-default-text w-40"
                          style={{
                            paddingTop: `${
                              resourcesHeight >= 12 ? resourcesHeight - 10 : 12
                            }px`,
                            paddingBottom: `${
                              resourcesHeight >= 12 ? 12 : null
                            }px`,
                          }}
                        >
                          {t("Tree Species.Description")}
                        </p>
                      </div>
                      <div className="pl-16 w-[300px]">
                        <p className="text-sm text-default-text font-medium py-3">
                          {histories[selectedIndex].height || "-"}
                        </p>
                        <p className="text-sm text-default-text font-medium py-3">
                          {histories[selectedIndex].diameter || "-"}
                        </p>
                        <p className="text-sm text-default-text font-medium py-3">
                          {histories[selectedIndex].age_year > 0 ? (
                            `${histories[selectedIndex].age_year} ${t(
                              "Register.Year"
                            )}` +
                            (histories[selectedIndex].age_month > 0
                              ? `, ${histories[selectedIndex].age_month} ${t(
                                  "Register.Month"
                                )}`
                              : "")
                          ) : histories[selectedIndex].age_year < 0 ? (
                            <span className="age-btn">
                              <FontAwesomeIcon
                                className="text-lg"
                                icon={faInfoCircle}
                              />
                              <div className={`age-text shadow-2xl`}>
                                <p>
                                  {i18n.language === "en"
                                    ? "The age of the tree is wrong. Please check the planting date."
                                    : "La edad del árbol es incorrecta. Por favor verifique la fecha de siembra."}
                                </p>
                              </div>
                            </span>
                          ) : histories[selectedIndex].age_year === 0 ||
                            histories[selectedIndex].age_month === 0 ? (
                            "-"
                          ) : null}
                        </p>
                        <p className="text-sm text-default-text font-medium py-3">
                          {i18n.language === "en"
                            ? histories[selectedIndex]?.growth_stage_transes
                                ?.en ||
                              histories[selectedIndex]?.growth_stage_transes
                                ?.es ||
                              "-"
                            : histories[selectedIndex]?.growth_stage_transes
                                ?.es || "-"}
                        </p>
                        <div id="incidentsContainer">
                          {histories[selectedIndex]?.incidents.length > 0 ? (
                            <div className="flex max-w-[300px] flex-wrap py-3">
                              {histories[selectedIndex]?.incidents.map(
                                (incident, index) => (
                                  <div
                                    className="pr-2 whitespace-nowrap text-default-text text-sm font-medium flex items-center"
                                    key={incident.slug}
                                  >
                                    <p>
                                      {i18n.language === "en"
                                        ? incident.name_transes.en === ""
                                          ? incident.name_transes.es
                                          : incident.name_transes.en
                                        : incident.name_transes.es}
                                    </p>
                                    {index <
                                      histories[selectedIndex]?.incidents
                                        .length -
                                        1 && ","}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <p className="text-sm text-default-text font-medium py-3">
                              -
                            </p>
                          )}
                        </div>
                        <p className="text-sm text-default-text font-medium py-3">
                          {histories[selectedIndex].is_dead ? (
                            <FontAwesomeIcon
                              className="text-lg text-ph-hover "
                              icon={faCircleCheck}
                            />
                          ) : (
                            <p className="text-sm text-default-text font-medium ">
                              -
                            </p>
                          )}
                        </p>
                        <div id="observationContainer">
                          <p
                            className={`text-sm text-default-text py-3 mb-3 font-medium max-w-[250px] max-h-[250px] break-words overflow-y-auto`}
                          >
                            {histories[selectedIndex].observation_transes.en !==
                              "" ||
                            histories[selectedIndex].observation_transes.es !==
                              "" ? (
                              <span className="">
                                {i18n.language === "en"
                                  ? histories[selectedIndex].observation_transes
                                      .en ||
                                    histories[selectedIndex].observation_transes
                                      .es
                                  : histories[selectedIndex].observation_transes
                                      .es}
                              </span>
                            ) : (
                              <p className="text-sm text-default-text font-medium">
                                -
                              </p>
                            )}
                          </p>
                        </div>
                        <div id="resourcesContainer">
                          <p
                            className={`text-sm text-default-text py-3 mb-3 font-medium max-w-[250px] max-h-[250px] break-words overflow-y-auto`}
                          >
                            {histories[selectedIndex].relevant_resources
                              .length > 0 ? (
                              histories[selectedIndex].relevant_resources.map(
                                (file: any) => (
                                  <span className="">
                                    <FileResource
                                      address={file.file_address}
                                      id={file.id}
                                      key={file.id}
                                    />
                                  </span>
                                )
                              )
                            ) : (
                              <p className="text-sm text-default-text font-medium">
                                -
                              </p>
                            )}
                          </p>
                        </div>
                        <p className="text-sm text-default-text font-medium py-3 max-w-[250px] max-h-[250px] break-words overflow-y-auto">
                          {i18n.language === "en"
                            ? histories[selectedIndex].description_transes.en ||
                              histories[selectedIndex].description_transes.es
                            : histories[selectedIndex].description_transes.es}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <br />
          {isAddView && (
            <AddGrowthHistory
              onAdd={getHistory}
              setActiveDate={handleActiveItemChange}
              addViewHandler={() => {
                setIsAddView(!isAddView);
              }}
              selectOptions={selectOption}
            />
          )}
          {isEditView && (
            <EditHistory
              onUpdate={getHistory}
              setActiveDate={handleActiveItemChange}
              setIsEdit={setIsEdit}
              histories={histories}
              selectedIndex={selectedIndex}
              editViewHandler={() => {
                setIsEditView(!isEditView);
              }}
              selectOptions={selectOption}
            />
          )}
          {showDelete && (
            <WarningRedAlert
              type={t("Growth history.Growth history")}
              hide={hide}
              action={deleteHandler}
              isDelete={true}
              isSignOut={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ViewGrowthHistory;
