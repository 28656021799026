import { ThemeProvider } from "@emotion/react";
import { theme } from "./FormTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  faCircleInfo,
  faLock,
  faLockOpen,
} from "@fortawesome/pro-light-svg-icons";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import exifr from "exifr";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import SpeciesList from "../../../type/speciesList";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import NumberField from "../../Global/NumberField";
import AddRegisterButton from "./AddRegisterButton";
import LocationPage from "../GetLocation/LocationPage";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import GeneralAlert from "../../ErrorAndAlert/GeneralAlert";
import PlantingList from "../../../type/PlantingList";
import { colors } from "../../../styles/colors";
import { Tags } from "../../../type/tag";
import { ResponsibleNames } from "../../../type/responsibleNames";

type FormValues = {
  treeSpecies: SpeciesList;
  incidents: SpeciesList[];
  height: number;
  diameter: number;
  ageYear: string;
  ageMonth: string;
  latitude: number;
  longitude: number;
  nursery: SpeciesList;
  responsible: ResponsibleNames;
  category: string;
  type: string;
  tags: Tags;
  unknownTags: string;
  planting: PlantingList;
};

const AddRegisterForm: React.FC<{
  send: (data: any) => void;
  selectOptions: {
    treeSpecies: SpeciesList[];
    incidents: SpeciesList[];
    nursery: SpeciesList[];
    plantings: PlantingList[];
    tags: Tags[];
    resName: ResponsibleNames[];
  };
  sendResponse: any;
  sendError: any;
  getInfo?: any;
  handleUnknown?: (unknown: boolean) => void;
}> = ({
  send,
  sendError,
  sendResponse,
  selectOptions: selectOption,
  getInfo,
  handleUnknown,
}) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>("ES");
  const [description, setDescription] = useState<TranslationField>({
    en: "",
    es: "",
  });
  const [resources, setResources] = useState<file[]>([]);
  const [replanting, isReplanting] = useState<boolean>(false);
  const [tagUnknown, isTagUnknown] = useState<boolean>(false);
  const [addNewRegister, isAddRegister] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [cellPhoneImage, setCellPhoneImage] = useState<file[]>([]);
  const [oldCellPhoneImage, setOldCellPhoneImage] = useState<string>("");
  const [upload, isUpload] = useState<boolean>(false);
  const [oldResource, setOldResource] = useState<
    {
      id: number;
      file_address: string;
      thumbnail_address: string;
      is_image: boolean;
    }[]
  >([]);
  const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
  const [requestNumber, setRequestNumber] = useState<number>(1);
  const [dirty, isDirty] = useState<boolean>(true);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [locationView, isLocationView] = useState<boolean>(false);
  const [alert, isAlert] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [unlockAlert, isUnlockAlert] = useState<boolean>(false);
  const { id, initials } = useParams<any>();

  const { fetchData: sendFile, response: fileResp } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/files/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const { fetchData: deleteImage } = useAxios(
    "",
    "DELETE",
    false,
    "",
    true,
    true
  );

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    resetField,
    clearErrors,
    watch,
    control,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  const responsibleData = watch("responsible");
  const categoryData = watch("category");
  const typeData = watch("type");
  const plantingData = watch("planting");
  const speciesData = watch("treeSpecies");
  const nurseryData = watch("nursery");
  const unknownTagData = watch("unknownTags");

  useEffect(() => {
    if (handleUnknown) {
      handleUnknown(tagUnknown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagUnknown]);

  useEffect(() => {
    if (sendResponse) {
      setRequestNumber((prevState) => prevState - 1);
      if (resources.length > 0) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex].data);
        formData.append("model_name", "Register");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
      if (deleteRecourse.length > 0) {
        deleteRecourse.forEach((r) => {
          setRequestNumber((prevState) => prevState - 1);
          deleteImage(
            { id: r },
            process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (sendError) {
      if (sendError.response.status === 400) {
        isUpload(false);
      }
    }
  }, [sendError]);

  useEffect(() => {
    if (getInfo) {
      setOldResource(getInfo.data.files);
      setOldCellPhoneImage(getInfo.data.planted_tree_image);
      setDescription({
        en: getInfo.data.brief_description_transes.en,
        es: getInfo.data.brief_description_transes.es,
      });
      setValue("category", getInfo.data.responsible_category);
      setValue("type", getInfo.data.responsible_type_name);
      setValue("responsible", {
        id: getInfo.data.responsible_type_id,
        name: getInfo.data.responsible_type_name,
        slug: getInfo.data.responsible_name_slug,
        full_name: getInfo.data.responsible_name,
        initials: getInfo.data.responsible_name_initials,
        responsible_category: getInfo.data.responsible_category,
        responsible_type: getInfo.data.responsible_type_name,
        responsible_type_slug: getInfo.data.responsible_type_slug,
      });
      setValue("treeSpecies", {
        slug: getInfo.data.species_slug,
        name_transes: getInfo.data.species_name_transes,
      });
      setValue("nursery", {
        slug: getInfo.data.origin_slug,
        name_transes: getInfo.data.origin_name_transes,
      });
      setValue("height", getInfo.data.last_height);
      setValue("diameter", getInfo.data.last_diameter);
      setValue("ageYear", getInfo.data.age_year);
      setValue("ageMonth", getInfo.data.age_month);
      setValue("latitude", getInfo.data.latitude);
      setValue("longitude", getInfo.data.longitude);
      setValue("incidents", getInfo.data.incidents);
      setValue("planting", {
        slug: getInfo.data.planting_slug,
        name_transes: getInfo.data.planting_name_transes,
        date: getInfo.data.planting_date,
        all_dates: getInfo.data.planting_all_date,
        is_register_completed: getInfo.data.is_register_completed,
        community_initials: getInfo.data.community_initials,
        community_slug: getInfo.data.community_slug,
      });
      setValue("tags", {
        slug: getInfo.data.tag_slug,
        code: getInfo.data.tag,
        unknown: getInfo.data.is_tag_unknown,
        species_slug: getInfo.data.species_slug,
        species_name_transes: getInfo.data.species_name_transes,
        nursery_slug: getInfo.data.nursery_slug,
        nursery_name_transes: getInfo.data.nursery_name_transes,
        responsible_category: getInfo.data.responsible_category,
        responsible_type_name: getInfo.data.responsible_type_name,
        responsible_type_slug: getInfo.data.responsible_type_slug,
        responsible_type_id: getInfo.data.responsible_type_id,
        responsible_name_slug: getInfo.data.responsible_name_slug,
        responsible_name: getInfo.data.responsible_name,
        responsible_name_initials: getInfo.data.responsible_name_initials,
      });
      isTagUnknown(getInfo.data.is_tag_unknown);
      setValue("unknownTags", getInfo.data.tag);
      isReplanting(getInfo.data.is_replanting);
      setLoading(false);
      setIsReadOnly(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    if (fileResp) {
      setRequestNumber((prevState) => prevState - 1);
      setFileIndex(fileIndex + 1);
      if (resources.length - 1 >= fileIndex + 1) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex + 1].data);
        formData.append("model_name", "Register");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResp]);

  useEffect(() => {
    if (requestNumber === 0) {
      resetField("height");
      resetField("diameter");
      resetField("ageYear");
      resetField("ageMonth");
      resetField("latitude");
      if (!tagUnknown) {
        resetTagData(true);
      }
      resetField("longitude");
      resetField("incidents");
      setDescription({ es: "", en: "" });
      setCellPhoneImage([]);
      setFileIndex(0);
      setResources([]);
      toast.success(
        `${
          getInfo
            ? `${t("Message.The record updated successfully")}`
            : `${t("Message.The record added successfully")}`
        }`
      );
      isUpload(false);
      if (getInfo) {
        history.goBack();
      }
      if (addNewRegister) {
        history.replace(`/registers/${id}`);
      }
      setRequestNumber(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber]);

  const resetTagData = (clearTag: boolean) => {
    setValue("treeSpecies", {
      slug: "",
      name_transes: { en: "", es: "" },
    });
    setValue("nursery", {
      slug: "",
      name_transes: { en: "", es: "" },
    });
    setValue("responsible", {
      id: 0,
      name: "",
      slug: "",
      full_name: "",
      initials: "",
      responsible_category: "",
      responsible_type: "",
      responsible_type_slug: "",
    });
    setValue("category", "");
    setValue("type", "");
    if (clearTag) {
      setValue("tags", {
        code: "",
        slug: "",
        species_slug: "",
        species_name_transes: { es: "", en: "" },
        nursery_slug: "",
        nursery_name_transes: { es: "", en: "" },
        responsible_category: "",
        responsible_type_name: "",
        responsible_type_slug: "",
        responsible_type_id: 0,
        responsible_name_slug: "",
        responsible_name: "",
        responsible_name_initials: "",
      });
    }
  };

  const insertFile = async (newFile: file[]) => {
    if (!newFile[0].type.includes("image")) {
      toast.error(`${t("Message.The supported formats are “jpg/jpeg, png”")}`);
    } else if (newFile[0].size > 10000000) {
      toast.error(`${t("Message.Maximum file size is 10 MB")}`);
    } else {
      setCellPhoneImage([newFile[0]]);
      try {
        const exifData = await exifr.gps(newFile[0].data);
        if (exifData?.latitude) {
          setValue("latitude", exifData.latitude);
          setValue("longitude", exifData.longitude);
        } else {
          isAlert(true);
        }
      } catch (error) {
        isAlert(true);
      }
      setOldCellPhoneImage("");
    }
  };

  const removeFile = (index: number) => {
    setCellPhoneImage([]);
  };

  const removeOldFile = (index: number) => {
    setOldCellPhoneImage("");
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setDescription({ en: event.target.value, es: description.es });
    } else if (lang === "ES") {
      setDescription({ es: event.target.value, en: description.en });
    }
  };

  const insertResources = (files: file[]) => {
    files.forEach((f) => {
      if (f.size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      } else {
        setRequestNumber((prevState) => prevState + 1);
        setResources((prevState) => [...prevState, f]);
      }
    });
  };

  const removeOldResource = (fileId: number) => {
    setDeleteResource((prevState) => [...prevState, fileId]);
    setOldResource((prevState) =>
      prevState.filter((prevItem) => prevItem.id !== fileId)
    );
    setRequestNumber((prevState) => prevState + 1);
  };

  const removeResource = (indexFile: number) => {
    setRequestNumber((prevState) => prevState - 1);
    setResources((prevState) =>
      prevState.filter((prevItem, index: number) => index !== indexFile)
    );
  };

  const replantingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isReplanting(event.target.checked);
  };

  const addRegisterItem = (data: FormValues) => {
    if (description.en && !description.es) {
      toast.error(
        `${t(
          "Message.While other languages have a translation, the default language cannot be empty."
        )}`
      );
    }

    isDirty(false);
    const inci: string[] = data.incidents?.map((incident) => incident.slug);
    const formData = new FormData();

    if (!getInfo) {
      if (tagUnknown) {
        formData.append("is_tag_unknown", tagUnknown + "");
        formData.append("responsible_name", data.responsible.slug);
      } else {
        formData.append("tag", data.tags.slug);
        formData.append("is_tag_unknown", "");
        formData.append("responsible_name", "");
      }
    } else {
      if (tagUnknown) {
        if (getInfo && data.planting.slug !== getInfo.data.planting_slug) {
          formData.append("planting", data.planting.slug);
        }
        if (
          (getInfo && !getInfo.data.is_tag_unknown) ||
          (getInfo &&
            getInfo.data.is_tag_unknown &&
            data.responsible.slug !== getInfo.data.responsible_name_slug)
        ) {
          formData.append("responsible_name", data.responsible.slug);
        }
      } else {
        if (getInfo.data.tag_slug !== data.tags.slug) {
          formData.append("tag", data.tags.slug);
        }
        if (getInfo && data.planting.slug !== getInfo.data.planting_slug) {
          formData.append("planting", data.planting.slug);
        }
      }
    }
    formData.append("species", data.treeSpecies.slug);
    formData.append("origin", data.nursery.slug);
    formData.append("is_replanting", replanting + "");
    formData.append("age_year", `${data.ageYear ? data.ageYear + "" : ""}`);
    formData.append("age_month", `${data.ageMonth ? data.ageMonth + "" : ""}`);
    if (!getInfo) {
      if (inci?.length > 0) {
        inci.forEach((incid: string, index: number) => {
          formData.append("incidents", incid);
        });
      }
      formData.append("height", `${data.height ? data.height + "" : ""}`);

      formData.append("diameter", `${data.diameter ? data.diameter + "" : ""}`);
    }
    formData.append("latitude", data.latitude + "");
    formData.append("longitude", data.longitude + "");
    if (!oldCellPhoneImage && cellPhoneImage.length !== 0) {
      formData.append("planted_tree_image", cellPhoneImage[0]?.data);
    } else if (!oldCellPhoneImage && cellPhoneImage.length === 0) {
      formData.append("planted_tree_image", "");
    }
    formData.append("brief_description_transes", JSON.stringify(description));
    isUpload(true);
    if ((getInfo && !data.planting.is_register_completed) || !getInfo) {
      send(formData);
    } else {
      toast.error(
        t(
          "Message.The number of registered trees shouldn’t exceed the planted trees by up to 20."
        )
      );
      isUpload(false);
    }
  };

  const closeMap = () => {
    isLocationView(false);
  };

  const latLongChange = (newLat: number, newLong: number) => {
    isLocationView(false);
    clearErrors("latitude");
    clearErrors("longitude");
    const floatPattern = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
    if (floatPattern.test(String(newLat))) {
      setValue("latitude", newLat);
    } else {
      setError("latitude", {
        type: "pattern",
        message: `${t("Message.Invalid number")}`,
      });
    }
    if (floatPattern.test(String(newLong))) {
      setValue("longitude", newLong);
    } else {
      setError("longitude", {
        type: "pattern",
        message: `${t("Message.Invalid number")}`,
      });
    }
  };

  const handleAddLocation = () => {
    setIsAdd(true);
    isLocationView(true);
    isAlert(false);
  };

  const unlockHandler = () => {
    isUnlockAlert(true);
  };

  const handleConfirm = () => {
    isUnlockAlert(false);
    setIsReadOnly(false);
  };

  const handleCancel = () => {
    isUnlockAlert(false);
  };

  let latErrorDisplayed = false;
  let longErrorDisplayed = false;

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addRegisterItem)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-default-text mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            {getInfo && (
              <div className="w-full sm:w-1/2 md:pr-2 mt-7">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Help videos.Planting event")}
                </p>
                <CustomAutocomplete
                  control={control}
                  name="planting"
                  selectOptions={selectOption.plantings}
                  placeholder={t("Help videos.Planting event")}
                  getOptionLabel={(option) =>
                    i18n.language === "en"
                      ? option.name_transes?.en === ""
                        ? option.name_transes?.es + " - " + option.date
                        : option.name_transes?.en + " - " + option.date
                      : option.name_transes?.es + " - " + option.date
                  }
                  onCustomChange={(newValue: any) => {
                    if(newValue.community_slug !== getInfo.data.community_slug){
                      setValue("planting", {
                        slug: getInfo.data.planting_slug,
                        name_transes: getInfo.data.planting_name_transes,
                        date: getInfo.data.planting_date,
                        all_dates: getInfo.data.planting_all_date,
                        is_register_completed: getInfo.data.is_register_completed,
                        community_initials: getInfo.data.community_initials,
                        community_slug: getInfo.data.community_slug,
                      });
                      toast.error(
                        t(
                          "Message.You can not move register to another community."
                        )
                      );

                    }
                  }}
                />
              </div>
            )}
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tagging tool.Tag")} *
                </p>
                {!tagUnknown ? (
                  <CustomAutocomplete
                    rules={{
                      required: `${t("Message.Required field")}`,
                      validate: (value: any) => {
                        if (!value || value.slug === "") {
                          return `${t("Message.Required field")}`;
                        }
                        return true;
                      },
                    }}
                    control={control}
                    name="tags"
                    selectOptions={selectOption.tags}
                    placeholder={t("Tagging tool.Tag")}
                    getOptionLabel={(option) => option?.code}
                    onCustomChange={(newValue: any) => {
                      setValue("treeSpecies", {
                        slug: newValue ? newValue?.species_slug : "",
                        name_transes: newValue
                          ? newValue?.species_name_transes
                          : { en: "", es: "" },
                      });
                      setValue("nursery", {
                        slug: newValue ? newValue?.nursery_slug : "",
                        name_transes: newValue
                          ? newValue?.nursery_name_transes
                          : { en: "", es: "" },
                      });
                      setValue("responsible", {
                        id: newValue ? newValue?.responsible_type_id : "",
                        name: newValue ? newValue?.responsible_type_name : "",
                        slug: newValue ? newValue?.responsible_name_slug : "",
                        full_name: newValue ? newValue?.responsible_name : "",
                        initials: newValue
                          ? newValue?.responsible_name_initials
                          : "",
                        responsible_category: newValue
                          ? newValue?.responsible_category
                          : "",
                        responsible_type: newValue
                          ? newValue?.responsible_type_name
                          : "",
                        responsible_type_slug: newValue
                          ? newValue?.responsible_type_slug
                          : "",
                      });
                      setValue(
                        "category",
                        newValue ? newValue?.responsible_category : ""
                      );
                      setValue(
                        "type",
                        newValue ? newValue?.responsible_type_name : ""
                      );
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    value={unknownTagData}
                    placeholder={`${t("Tagging tool.Tag")}`}
                    readOnly
                    className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
                  />
                )}
                {errors.tags && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-full sm:w-[48.5%]">
                <p className="text-sm text-default-text ml-1 mb-2">
                  {t("Tagging tool.Tag index unknown")}
                </p>

                <Switch
                  color="warning"
                  checked={tagUnknown}
                  onClick={() => {
                    setValue("treeSpecies", {
                      slug: "",
                      name_transes: { en: "", es: "" },
                    });
                    setValue("nursery", {
                      slug: "",
                      name_transes: { en: "", es: "" },
                    });
                    setValue("responsible", {
                      id: 0,
                      name: "",
                      slug: "",
                      full_name: "",
                      initials: "",
                      responsible_category: "",
                      responsible_type: "",
                      responsible_type_slug: "",
                    });
                    setValue("tags", {
                      code: "",
                      slug: "",
                      species_slug: "",
                      species_name_transes: { es: "", en: "" },
                      nursery_slug: "",
                      nursery_name_transes: { es: "", en: "" },
                      responsible_category: "",
                      responsible_type_name: "",
                      responsible_type_slug: "",
                      responsible_type_id: 0,
                      responsible_name_slug: "",
                      responsible_name: "",
                      responsible_name_initials: "",
                    });
                    setValue("category", "");
                    setValue("type", "");
                    setValue(
                      "unknownTags",
                      `${
                        getInfo ? plantingData.community_initials : initials
                      }-XXX-Unknown`
                    );
                    isTagUnknown(!tagUnknown);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Navbar.Tree species")} *
                </p>
                {tagUnknown ? (
                  <Fragment>
                    <CustomAutocomplete
                      rules={{
                        required: `${t("Message.Required field")}`,
                        validate: (value: any) => {
                          if (!value || value.slug === "") {
                            return `${t("Message.Required field")}`;
                          }
                          return true;
                        },
                      }}
                      control={control}
                      name="treeSpecies"
                      selectOptions={selectOption.treeSpecies}
                      placeholder={t("Navbar.Tree species")}
                      getOptionLabel={(option) =>
                        i18n.language === "en"
                          ? option.name_transes?.en === ""
                            ? option.name_transes?.es
                            : option.name_transes?.en
                          : option.name_transes?.es
                      }
                    />
                    {errors.treeSpecies && (
                      <p className="error-text">
                        {t("Message.Required field")}
                      </p>
                    )}
                  </Fragment>
                ) : (
                  <input
                    type="text"
                    value={
                      i18n.language === "en"
                        ? speciesData?.name_transes?.en === ""
                          ? speciesData?.name_transes?.es
                          : speciesData?.name_transes?.en
                        : speciesData?.name_transes?.es
                    }
                    placeholder={t("Navbar.Tree species") + ""}
                    readOnly
                    className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
                  />
                )}
              </div>

              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tagging tool.Nursery")} *
                </p>
                {tagUnknown ? (
                  <CustomAutocomplete
                    rules={{
                      required: `${t("Message.Required field")}`,
                      validate: (value: any) => {
                        if (!value || value.slug === "") {
                          return `${t("Message.Required field")}`;
                        }
                        return true;
                      },
                    }}
                    control={control}
                    name="nursery"
                    selectOptions={selectOption.nursery}
                    placeholder={t("Tagging tool.Nursery")}
                    getOptionLabel={(option) =>
                      i18n.language === "en"
                        ? option.name_transes?.en === ""
                          ? option.name_transes?.es
                          : option.name_transes?.en
                        : option.name_transes?.es
                    }
                  />
                ) : (
                  <input
                    type="text"
                    value={
                      i18n.language === "en"
                        ? nurseryData?.name_transes.en === ""
                          ? nurseryData?.name_transes.es
                          : nurseryData?.name_transes.en
                        : nurseryData?.name_transes.es
                    }
                    placeholder={t("Tagging tool.Nursery") + ""}
                    readOnly
                    className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
                  />
                )}

                {errors.nursery && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>

            <p className="hr-lines-responsible mt-7">
              {t("Register.Responsible")}
            </p>
            <p className="text-sm text-default-text mt-4 mb-2">
              {t("Register.Responsible")} *
            </p>
            {tagUnknown ? (
              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                  validate: (value: any) => {
                    if (!value || value.slug === "") {
                      return `${t("Message.Required field")}`;
                    }
                    return true;
                  },
                }}
                control={control}
                name="responsible"
                selectOptions={selectOption.resName}
                placeholder={t("Register.Responsible")}
                getOptionLabel={(option) => option?.full_name}
                onCustomChange={(newValue: any) => {
                  setValue(
                    "unknownTags",
                    `${getInfo ? plantingData.community_initials : initials}-${
                      newValue ? newValue?.initials : "XXX"
                    }-Unknown`
                  );
                  setValue("category", newValue?.responsible_category);
                  if (newValue?.responsible_category === "ind") {
                    setValue("type", "");
                  } else {
                    setValue(
                      "type",
                      newValue ? newValue?.responsible_type : ""
                    );
                  }
                }}
              />
            ) : (
              <input
                type="text"
                value={responsibleData?.full_name}
                placeholder={t("Register.Responsible") + ""}
                readOnly
                className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
              />
            )}

            {errors.responsible && (
              <p className="error-text">{t("Message.Required field")}</p>
            )}
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Category")} *
                </p>
                <input
                  type="text"
                  value={`${
                    categoryData === "fun"
                      ? "FUNDAEC"
                      : categoryData === "grp"
                      ? t("Register.Group")
                      : categoryData === "ind"
                      ? t("Register.Individual")
                      : categoryData === "ins"
                      ? t("Register.Institution")
                      : ""
                  }`}
                  placeholder={`${t("Register.Category")}`}
                  readOnly
                  className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tree Species.Type")} *
                </p>
                <input
                  type="text"
                  value={typeData}
                  placeholder={`${t("Tree Species.Type")}`}
                  readOnly
                  className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
                />
              </div>
              <div className="w-full md:w-[48%] mt-7">
                <div className="mb-[6px] mt-[-4px] flex justify-between items-center">
                  <p className="text-sm">{t("Register.Planting age")}</p>

                  {getInfo && isReadOnly && (
                    <button type="button" onClick={unlockHandler}>
                      <FontAwesomeIcon
                        icon={faLock}
                        className="text-default-text"
                      />
                    </button>
                  )}
                  {getInfo && !isReadOnly && (
                    <FontAwesomeIcon
                      icon={faLockOpen}
                      className="text-default-text"
                    />
                  )}
                </div>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="ageYear"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel
                          shrink={false}
                          style={{
                            color: isReadOnly ? colors.white : undefined,
                          }}
                        >
                          {!value && `${t("Register.Year")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "48px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          disabled={isReadOnly}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": {
                              border: "none",
                            },
                            "& .MuiSvgIcon-root": {
                              color: isReadOnly
                                ? "#FFFFFF !important"
                                : undefined,
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: isReadOnly
                                ? colors.bg_gray
                                : undefined,
                            },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <span className="px-2 text-default-text text-xs">
                    &#9866;
                  </span>
                  <Controller
                    control={control}
                    name="ageMonth"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel
                          shrink={false}
                          style={{
                            color: isReadOnly ? colors.white : undefined,
                          }}
                        >
                          {!value && `${t("Register.Month")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "48px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          disabled={isReadOnly}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                            "& .MuiSvgIcon-root": {
                              color: isReadOnly
                                ? "#FFFFFF !important"
                                : undefined,
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: isReadOnly
                                ? "#A2A1A1 !important"
                                : undefined,
                            },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="11">11</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Tree height")}{" "}
                  <span className="text-ph-gray-text">in “cm”</span>
                </p>
                <NumberField
                  onChange={() => {
                    clearErrors("height");
                  }}
                  getValues={getValues}
                  inputStyle={`py-3 ${
                    !getInfo
                      ? "placeholder-ph-light-gray"
                      : "placeholder-ph-white"
                  }`}
                  name="height"
                  placeholder={t("Register.Tree height") + ""}
                  register={register("height", {
                    pattern: /^[+]?\d+/,
                  })}
                  setValue={setValue}
                  styleClass="w-full"
                  readOnl={getInfo ? true : false}
                />
                {errors.height?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
              </div>
              <div className="w-full md:w-[48%] mt-7">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Diameter")}{" "}
                  <span className="text-ph-gray-text">in “mm”</span>
                </p>
                <NumberField
                  onChange={() => {
                    clearErrors("diameter");
                  }}
                  getValues={getValues}
                  inputStyle={`py-3 ${
                    !getInfo
                      ? "placeholder-ph-light-gray"
                      : "placeholder-ph-white"
                  }`}
                  name="diameter"
                  placeholder={t("Register.Diameter") + ""}
                  register={register("diameter", {
                    pattern: /^[+]?\d+/,
                  })}
                  setValue={setValue}
                  styleClass="w-full"
                  readOnl={getInfo ? true : false}
                />
                {errors.diameter?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7">
                <p className="text-sm text-default-text  mb-[6px]">
                  {t("Incidents.Incidents")}
                </p>
                <CustomAutocomplete
                  control={control}
                  name="incidents"
                  isMulti={true}
                  defaultValue={getValues("incidents")}
                  selectOptions={selectOption.incidents}
                  placeholder={t("Incidents.Incidents")}
                  getOptionLabel={(option) =>
                    i18n.language === "en"
                      ? option.name_transes?.en === ""
                        ? option.name_transes?.es
                        : option.name_transes?.en
                      : option.name_transes?.es
                  }
                  readonly={getInfo ? true : false}
                  disabled={getInfo ? true : false}
                  incident={getInfo ? true : false}
                />
              </div>
              <div className="w-full sm:w-[48.5%] mt-7">
                <p className="text-sm text-default-text ml-1">
                  {t("Register.Replanting")}
                </p>
                <Switch
                  color="warning"
                  checked={replanting}
                  onChange={replantingChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </ThemeProvider>
          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Register.Planted tree image")}
            <span
              title={
                t("Register.An image of the planted tree in the location") + ""
              }
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <DragDropField
            files={cellPhoneImage}
            format="png, jpg, jpeg (max 10 MB)"
            insertFile={insertFile}
            removeFile={removeFile}
          />

          {oldCellPhoneImage && (
            <OldFile
              address={oldCellPhoneImage}
              id={0}
              delete={true}
              removeOldFile={removeOldFile}
            />
          )}

          <div className="flex flex-wrap justify-between mt-3">
            <div className="w-1/2 pr-2">
              <p className="text-sm text-default-text mb-[6px]">
                {t("AddPlanting.Latitude")} *
              </p>
              <input
                {...register("latitude", {
                  required: `${t("Message.Required field")}`,
                  pattern: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/,
                })}
                onChange={() => {
                  clearErrors("latitude");
                }}
                type="text"
                className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                placeholder={t("AddPlanting.Latitude") + ""}
              />
              {errors.latitude?.type === "pattern" && !latErrorDisplayed && (
                <>
                  {(latErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Invalid number")}</p>
                </>
              )}
              {errors.latitude && !latErrorDisplayed && (
                <>
                  {(latErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Required field")}</p>
                </>
              )}
            </div>
            <div className="w-1/2 pl-2">
              <p className="text-sm text-default-text mb-[6px]">
                {t("AddPlanting.Longitude")}*
              </p>
              <input
                {...register("longitude", {
                  required: `${t("Message.Required field")}`,
                  pattern: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/,
                })}
                onChange={() => {
                  clearErrors("longitude");
                }}
                type="text"
                className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                placeholder={t("AddPlanting.Longitude") + ""}
              />
              {errors.longitude?.type === "pattern" && !longErrorDisplayed && (
                <>
                  {(longErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Invalid number")}</p>
                </>
              )}
              {errors.longitude && !longErrorDisplayed && (
                <>
                  {(longErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Required field")}</p>
                </>
              )}
            </div>
          </div>
          <button
            onClick={() => {
              isLocationView(true);
              setIsAdd(false);
            }}
            type="button"
            className="text-sm text-ph-btn mb-[6px] mt-5"
          >
            <FontAwesomeIcon className="pr-1" icon={faLocationDot} />
            {t("Register.Edit location on the map")}
          </button>

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("AddPlanting.Additional resources")}
            <span
              title={
                t("Register.Tree image or any other relevant resources") + ""
              }
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <DragDropField
            files={resources}
            format="jpg, jpeg, png, txt, doc, docx, pdf, xls, xlsx (max 10 MB)"
            insertFile={insertResources}
            removeFile={removeResource}
          />
          {oldResource.map((or) => (
            <OldFile
              address={or.file_address}
              id={or.id}
              delete={true}
              removeOldFile={removeOldResource}
              key={or.id}
            />
          ))}

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("AddPlanting.Brief description")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={descriptionChange}
            value={lang === "EN" ? description.en : description.es}
            rows={7}
            className="input-field resize-none text-sm  pl-4 py-[10px] placeholder-ph-light-gray"
            placeholder={t("AddPlanting.Brief description") + ""}
          />
          <AddRegisterButton
            update={getInfo}
            uploading={upload}
            isAddPlanting={() => {
              isAddRegister(true);
            }}
          />
        </form>
      )}
      {locationView && (
        <LocationPage
          lat={getValues("latitude")}
          long={getValues("longitude")}
          isAdd={isAdd}
          closeMap={closeMap}
          latLongChange={latLongChange}
        />
      )}
      {alert && (
        <GeneralAlert
          alert={true}
          handleConfirm={handleAddLocation}
          handleCancel={() => {}}
          body={t(
            "Message.The latitude and longitude were not read successfully"
          )}
          confirmText={t("Message.Add location") + ""}
        />
      )}
      {unlockAlert && (
        <GeneralAlert
          alert={true}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          body={t("Message.Unlock age alert")}
          confirmText={t("Message.Confirm") + ""}
          cancelText={t("Message.Cancel") + ""}
        />
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddRegisterForm;
