import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faTrashCan, faUpload } from "@fortawesome/pro-regular-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";

const AddProfilePic: React.FC<{
  profile_picture: string;
  closePopUP: () => void;
  insertProfilePic: (newFile: any) => void;
}> = ({
  profile_picture,
  closePopUP,
  insertProfilePic: propInsertProfilePic,
}) => {
  const [newImage, setNewImage] = useState<string>("");
  const [image, setImage] = useState<any>();
  const [displayImage, isDisplayImage] = useState<boolean>(true);
  const { t } = useTranslation();

  const insertProfilePic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length) {
      if (!files[0].type.includes("image")) {
        toast.error(
          `${t("Message.The supported formats are “jpg/jpeg, png”")}`
        );
      } else if (files[0].size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      } else {
        setNewImage(URL.createObjectURL(files[0]));
        isDisplayImage(true);
        setImage(files[0]);
      }
    }
  };

  const removeProfilePic = () => {
    isDisplayImage(false);
    setImage(null);
    setNewImage("");
  };

  const saveHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    propInsertProfilePic(image);
  };

  return (
    <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
      <div className="bg-white w-[391px] h-[365px] px-9 py-12 rounded-md shadow-md">
        <h2 className="font-bold text-default-text">
          {t("Profile.Change profile picture")}
        </h2>
        <form onSubmit={saveHandler}>
          <div className="flex flex-wrap justify-between">
            <div className="w-1/2 pl-0">
              <div className="relative flex items-center justify-center rounded-md  mt-7 w-[140px] h-[140px] overflow-hidden object-cover bg-ph-input">
                {displayImage && (newImage || profile_picture) ? (
                  <img alt="preview" src={newImage || profile_picture} />
                ) : (
                  <FontAwesomeIcon
                    className="text-[#9D9A9ABD] text-8xl"
                    icon={faUser}
                  />
                )}
              </div>
            </div>
            <div className="w-1/2 pl-6">
              <div className="pt-16 pb-6 group">
                <label
                  htmlFor="file"
                  className="text-sm text-default-text group-hover:text-ph-btn"
                >
                  <FontAwesomeIcon
                    className="text-[#353535] text-1xl pr-2 group-hover:text-ph-btn"
                    icon={faUpload}
                  />
                  {t("Profile.Upload picture")}{" "}
                </label>
                <input
                  style={{ display: "none" }}
                  id="file"
                  onChange={insertProfilePic}
                  type="file"
                />
              </div>
              <div className="group">
                <button
                  type="button"
                  onClick={removeProfilePic}
                  className="text-default-text text-sm group-hover:text-ph-btn"
                >
                  <FontAwesomeIcon
                    className="text-[#353535] text-1xl pr-2 font-light group-hover:text-ph-btn"
                    icon={faTrashCan}
                  />
                  {t("Profile.Remove picture")}
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center border-b-2 border-ph-pborder pb-8" />
          <button
            type="submit"
            className="bg-ph-btn float-right text-sm mt-7 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover font-medium"
          >
            {t("Profile.Save")}
          </button>
          <button
            onClick={() => {
              closePopUP();
            }}
            type="button"
            className="text-ph-dark-gray-text mr-4 float-right text-sm mt-7 rounded-3xl px-6 py-2 hover:bg-ph-input font-medium"
          >
            {t("Message.Cancel")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProfilePic;
