import { useTranslation } from "react-i18next";
import BreadCrumb from "../Global/BreadCrumb";

const HistoryBreadcrumb: React.FC<{ breadCrumb: any }> = ({ breadCrumb }) => {
  const { i18n, t } = useTranslation();

  return (
    <div>
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Growth history.Growth history")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Register.Register"), url: "/registers/all" },
          {
            name: `${breadCrumb?.data.date}-${
              breadCrumb?.data.department_name
            }-${
              i18n.language === "en"
                ? breadCrumb?.data.species_name_transes.en ||
                  breadCrumb?.data.species_name_transes.es
                : breadCrumb?.data.species_name_transes.es
            }-${breadCrumb?.data.unique_id}`,
            url: "",
          },
          { name: t("Growth history.Growth history"), url: "" },
        ]}
      />
    </div>
  );
};
export default HistoryBreadcrumb;
