import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Participants: React.FC<{
  register: any;
  setValue: any;
  clearErrors: any;
  getValues: any;
  participantsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ register, setValue, clearErrors, getValues, participantsChange }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="flex justify-between items-center  mt-7">
        {" "}
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.PSA students")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("psaStudent") > 0) {
                setValue("psaStudent", getValues("psaStudent") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("psaStudent")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("participants");
              setValue("psaStudent", Number(getValues("psaStudent")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-default-text mb-[6px] ">
          {t("Workshops.School students")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("schoolStudent") > 0) {
                setValue("schoolStudent", getValues("schoolStudent") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("schoolStudent")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("schoolStudent");
              setValue("schoolStudent", Number(getValues("schoolStudent")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.PSA tutors")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("psaTutor") > 0) {
                setValue("psaTutor", getValues("psaTutor") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("psaTutor")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("psaTutor");
              setValue("psaTutor", Number(getValues("psaTutor")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.School teachers")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("schoolTeacher") > 0) {
                setValue("schoolTeacher", getValues("schoolTeacher") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("schoolTeacher")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("schoolTeacher");
              setValue("schoolTeacher", Number(getValues("schoolTeacher")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.Other participants")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("other") > 0) {
                setValue("other", getValues("other") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("other")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("other");
              setValue("other", Number(getValues("other")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Participants;
