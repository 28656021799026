import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Department } from "../type/department";
import SpeciesList from "../type/speciesList";

type MapFilter = {
  dateFrom: string;
  dateTo: string;
  treeSpecies: SpeciesList | null | undefined;
  department: Department | null | undefined;
};
const initialState: {
  filterItem: MapFilter;
  url: string;
  filterSet: boolean;
  deleteFilter: "department" | "date" | "treeSpecies" | "";
} = {
  filterItem: {
    dateFrom: "",
    dateTo: "",
    department: null,
    treeSpecies: null,
  },
  url: "/map/?",
  filterSet: false,
  deleteFilter: "",
};

const mapFilterSlice = createSlice({
  name: "mapFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<MapFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removeDate: (state) => {
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.deleteFilter = "date";
    },
    removeTreeSpecies: (state) => {
      state.filterItem.treeSpecies = null;
      state.deleteFilter = "treeSpecies";
    },
    removeDepartment: (state) => {
      state.filterItem.department = null;
      state.deleteFilter = "department";
    },
    setURL: (state) => {
      let dep = "";
      let spe = "";
      if (
        state.filterItem.treeSpecies !== null &&
        state.filterItem.treeSpecies !== undefined
      ) {
        spe = state.filterItem.treeSpecies?.slug;
      }
      if (
        state.filterItem.department !== null &&
        state.filterItem.department !== undefined
      ) {
        dep = state.filterItem.department?.slug;
      }
      state.url = `/map/?date_from=${state.filterItem.dateFrom}&date_to=${state.filterItem.dateTo}&department=${dep}&species=${spe}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.treeSpecies = null;
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.filterItem.department = null;
      state.deleteFilter = "";
    },
    isFilterSet: (state) => {
      if (
        state.filterItem.dateFrom === "" &&
        state.filterItem.dateTo === "" &&
        (state.filterItem.department === null ||
          state.filterItem.department === undefined) &&
        (state.filterItem.treeSpecies === null ||
          state.filterItem.treeSpecies === undefined)
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const mapFilterAction = mapFilterSlice.actions;
export default mapFilterSlice;
