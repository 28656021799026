import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from "@fortawesome/pro-regular-svg-icons";
import { faPeriod } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const BreadCrumb: React.FC<{ breadcrumb: { name: string; url: string }[] }> = ({
  breadcrumb,
}) => {
  return (
    <div className="text-ph-gray flex items-center mt-2">
      <Link to="/statistics">
        <FontAwesomeIcon icon={faHouseChimney} className="w-5 h-4" />{" "}
      </Link>
      {breadcrumb.map((b, index) => {
        return b.url ? (
          <Fragment key={index}>
            <FontAwesomeIcon icon={faPeriod} className="w-3 h-3 mx-2" />
            <Link to={b.url} className="text-xs">
              {b.name}
            </Link>
          </Fragment>
        ) : (
          <Fragment key={index}>
            <FontAwesomeIcon icon={faPeriod} className="w-3 h-3 mx-2" />
            <p className="text-xs">{b.name}</p>
          </Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
