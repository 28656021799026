import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlantingOrganizerFilter = {
  isActive: string;
};
const initialState: {
  filterItem: PlantingOrganizerFilter;
  url: string;
  filterSet: boolean;
} = {
  filterItem: {
    isActive: "",
  },
  url: "/organizers/?",
  filterSet: false,
};

const plantingOrganizerFilterSlice = createSlice({
  name: "plantingOrganizerFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PlantingOrganizerFilter>) => {
      state.filterItem = action.payload;
    },
    setURL: (state) => {
      state.url = `/organizers/?is_active=${state.filterItem.isActive}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.isActive = "";
    },
    isFilterSet: (state) => {
      if (!state.filterItem.isActive) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const plantingOrganizerFilterAction =
  plantingOrganizerFilterSlice.actions;
export default plantingOrganizerFilterSlice;
