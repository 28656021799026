import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import SpeciesList from "../../../type/speciesList";
import { useParams } from "react-router-dom";
import AddHistoryForm from "./AddHistoryForm";

const AddGrowthHistory: React.FC<{
  onAdd: (data: any) => void;
  setActiveDate: (newActiveItem: string) => void;
  addViewHandler: () => void;
  selectOptions: {
    growth_stage: SpeciesList[];
    incident: SpeciesList[];
  };
}> = ({ onAdd, setActiveDate, addViewHandler, selectOptions }) => {
  const { t } = useTranslation();
  const { id } = useParams<any>();

  const { fetchData, response, error } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/histories/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  const cancelAddForm = () => {
    addViewHandler();
  };

  return (
    <div className="py-6 flex justify-center">
      <div className="w-full h-fit sc:w-[550px]">
        <AddHistoryForm
          onSubmit={onAdd}
          setActiveDate={setActiveDate}
          closeForm={cancelAddForm}
          selectOptions={selectOptions}
          send={fetchData}
          sendResponse={response}
          sendError={error}
          textButton={t("Register.Add")}
          title={t("Growth history.Add tree growth history")}
        />
      </div>
    </div>
  );
};
export default AddGrowthHistory;
