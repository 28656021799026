import { createTheme } from "@mui/material/styles";
import { colors } from "../../../styles/colors";

export const plantingTheme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: colors.light_gray,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: colors.default_text,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.gray_white,
          },
          "&.Mui-focused": {
            backgroundColor: "white",
            outline: "1px solid #efbf07",
            border: "none",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "10px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "7px  12px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.gray_white,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.light_gray,
          fontSize: "14px",
          fontFamily: "Inter,sans-serif",
          top: "-8.5px",
          "&.Mui-focused": {
            color: colors.light_gray,
            fontSize: "14px",
            fontFamily: "Inter,sans-serif",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.background_white,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          ":hover": {
            color: colors.default_highlight,
            backgroundColor: "white",
          },
          "&.Mui-selected": {
            color: colors.default_highlight,
            backgroundColor: "white",
            ":hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  },
});
