import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import Slider from "../../Global/Slider";
import AddWorkshopForm from "./AddWorkshopForm";
import { Department } from "../../../type/department";

const AddWorkshops = () => {
  const [departments, setDepartment] = useState<Department[]>([]);
  const { t } = useTranslation();

  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    true,
    false
  );

  const { fetchData: addWorkshop, response: addResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/workshop/new/",
    "POST",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (departmentRes) {
      setDepartment(departmentRes.data);
    }
  }, [departmentRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Workshops.Add Workshop")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          {
            name: t("Workshops.Workshops"),
            url: "/workshops",
          },
          {
            name: t("Workshops.Add Workshop"),
            url: "",
          },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddWorkshopForm
            departments={departments}
            send={addWorkshop}
            sendResponse={addResponse}
            textButton={t("Workshops.Add Workshop")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <Slider
            text={true}
            images={["/img/Add Workshop1.svg", "/img/Add Workshop2.svg"]}
          />
        </div>
      </div>
    </div>
  );
};

export default AddWorkshops;
