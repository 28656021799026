import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

const LanguagePoint: React.FC<{ lang?: string }> = ({ lang }) => {
  return (
    <span className="ml-2 text-ph-gray text-xs">
      <FontAwesomeIcon
        className={`${
          lang === "EN"
            ? "text-ph-blue-lang-point"
            : "text-ph-yellow-lang-point"
        } w-2 h-2 pb-[2px] mr-1`}
        icon={faCircle}
      />
      {lang === "EN" ? "EN" : "ES"}
    </span>
  );
};

export default LanguagePoint;
