import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import { Modal } from "@mui/material";

const AddOrganizerName: React.FC<{
  closeAddOrganizer: () => void;
  addOrganizer: (type: string, value: Department) => void;
  organizerType: string;
  openModal: boolean;
}> = ({ closeAddOrganizer, addOrganizer, organizerType, openModal }) => {
  const [name, setName] = useState<string>("");
  const [mandatoryError, isMandatoryError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/organizers/new/",
    "POST",
    false,
    `${t("Message.The record added successfully")}`,
    true,
    true
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (response) {
      addOrganizer(organizerType, {
        name: response.data.name,
        slug: response.data.slug,
      });
      closeAddOrganizer();
      setName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, dispatch]);

  const addCollaboratorName = () => {
    if (name) {
      fetchData({
        type: organizerType,
        name: name,
        is_active: true,
      });
    } else {
      isMandatoryError(true);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        closeAddOrganizer();
      }}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0"
    >
      <div className="bg-white w-full h-fit px-9 py-12 sm:w-[492px] sm:h-[327px] rounded-md shadow-md">
        <h2 className="font-semibold mb-6 text-default-text">
          {t("Plantings.Create new organizer")}
        </h2>
        <label className="text-sm text-default-text" htmlFor="addCollaborator">
          {t("Plantings.Enter the new organizer name")}
        </label>
        <input
          type="text"
          onChange={(e) => {
            setName(e.target.value);
            isMandatoryError(false);
          }}
          value={name}
          placeholder="Name"
          className="input-field text-sm pl-4 mt-4 py-[9px] placeholder-ph-light-gray"
        />
        {mandatoryError && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
        <button
          onClick={addCollaboratorName}
          type="button"
          className="bg-ph-btn float-right text-sm mt-16 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover font-medium"
        >
          {t("Plantings.Create")}
        </button>
        <button
          onClick={() => {
            closeAddOrganizer();
          }}
          type="button"
          className="text-default-text mr-4 float-right text-sm mt-16 rounded-3xl px-6 py-2 font-medium"
        >
          {t("Message.Cancel")}
        </button>
      </div>
    </Modal>
  );
};

export default AddOrganizerName;
