import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faUser,
  faUsers,
  faSeedling,
  faClipboardCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Planting } from "../../../type/planting";
import WorkshopSlider from "../../Workshops/WorkshopList/WorkshopSlider";
import WorkshopParticipants from "../../Workshops/WorkshopList/WorkshopParticipants";
import OrginizerName from "./OrginizerName";
import TreesDetail from "./TreeDetail";
import PlantingActionMenu from "./PlantingActionMenu";
import { Link } from "react-router-dom";
import InfoPopover from "../../Register/RegisterList/InfoPopover";
import { toast } from "react-toastify";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const PlantingGrid: React.FC<{
  plantings: Planting;
  fetchData: () => void;
}> = ({ plantings, fetchData: propFetchData }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${plantings.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (plantings.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(
        t("Message.You can’t delete the event. It contains some registers.")
      );
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  const name =
    plantings.name_transes.en === ""
      ? plantings.name_transes.es
      : plantings.name_transes.en;

  const desc =
    plantings.description_transes.en === ""
      ? plantings.description_transes.es
      : plantings.description_transes.en;

  const image = plantings.resources.filter((image) => image.is_image);
  return (
    <Fragment>
      <div className="w-full lg:w-[49%] mb-4 p-5 rounded-md shadow-sm border border-ph-iborder text-sm hover:shadow-lg">
        <div className="flex justify-between items-center">
          <Link
            className="font-semibold text-default-text hover:text-ph-hover"
            to={`/plantings/view-planting/${plantings.slug}`}
          >
            {i18n.language === "en" ? name : plantings.name_transes.es}
          </Link>
          <PlantingActionMenu
            deleteAction={deleteAction}
            plantedTrees={plantings.planted_trees}
            registeredTrees={plantings.registered_trees}
            slug={plantings.slug}
            initials={plantings?.community_initials}
            cSlug={plantings?.community_slug}
          />
        </div>
        {image?.length > 0 ? (
          <WorkshopSlider images={image} />
        ) : (
          <div className="w-full h-[269px] bg-ph-bg-white rounded-md mt-2 flex items-center justify-center">
            <img src="/img/No Pic placeholder illu.svg" alt="noPic" />
          </div>
        )}

        <p
          title={
            i18n.language === "en" ? desc : plantings.description_transes.es
          }
          className="py-2 grid-desc"
        >
          {i18n.language === "en" ? desc : plantings.description_transes.es}
        </p>
        <hr />
        <div className="mt-2">
          <div className="sm:flex py-2">
            <div className="w-full sm:w-[50%]">
              <span className="text-ph-gray">
                <FontAwesomeIcon icon={faCalendarDays} />{" "}
                {t("Plantings.Date of planting")}:
              </span>
              <p className="w-9 inline pl-6">{plantings.date}</p>
            </div>
            <div className="w-[250px] mt-5 sm:mt-0 h-5 whitespace-nowrap overflow-hidden text-ellipsis">
              <span className="text-ph-gray">
                <FontAwesomeIcon icon={faLocationDot} />{" "}
                {t("Plantings.Department")}:
              </span>
              <p className="inline ml-4">{plantings.department}</p>
            </div>
          </div>

          <div className="sm:flex items-center py-2">
            <div className="w-full sm:w-[50%] mt-2 sm:mt-0">
              <span className="text-ph-gray pr-5">
                <FontAwesomeIcon icon={faSeedling} />{" "}
                {t("Plantings.Planted tree(s)")}:
              </span>
              <p className="w-9 inline px-3">{plantings.planted_trees}</p>
            </div>
            <div className="mt-5 sm:mt-0">
              <span className="text-ph-gray">
                <FontAwesomeIcon icon={faUsers} /> {t("Plantings.Organizer(s)")}
                :
              </span>
              <div className="inline-block ml-2">
                <OrginizerName organizers={plantings.organizers} />
              </div>
            </div>
          </div>

          <div className="sm:flex items-center mt-1">
            <div className="w-full sm:w-[50%]">
              <span className="text-ph-gray pr-2">
                <FontAwesomeIcon icon={faClipboardCheck} />{" "}
                {t("Plantings.Registered tree(s)")}:
              </span>
              <div className="inline-block">
                {plantings.registered_trees > 0 ? (
                  <div className="flex items-center">
                    <TreesDetail
                      num={plantings.registered_trees}
                      trees={plantings.trees_info}
                    />
                    {plantings.registered_trees > plantings.planted_trees && (
                      <InfoPopover
                        desc={{
                          en: "The registered trees are more than planted trees",
                          es: "Los árboles registrados son más que los plantados",
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <p className="ml-[6px]">0</p>
                )}
              </div>
            </div>
            <div className="mt-5 sm:mt-0">
              <span className="text-ph-gray">
                <FontAwesomeIcon icon={faUser} />{" "}
                {t("PlantingFilter.Participants")}:
              </span>
              <p className="inline px-3">
                <WorkshopParticipants
                  totalNum={plantings.participants_total}
                  others={plantings.other_participants}
                  psaS={plantings.psa_students}
                  psaT={plantings.psa_tutors}
                  schoolS={plantings.school_students}
                  schoolT={plantings.school_tutors}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {showDelete && (
        <WarningRedAlert
          type={t("Plantings.Planting")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default PlantingGrid;
