import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "@mui/material";

const PlantingDateWarning: React.FC<{
  when: boolean;
  onOK: () => boolean;
  onCancel: () => boolean;
}> = ({ when, onOK, onCancel }) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (when) {
      setShowPrompt(true);
    }
  }, [when]);

  const handleOK = useCallback(() => {
    if (onOK()) {
      setShowPrompt(false);
    }
  }, [onOK]);

  const handleCancel = useCallback(() => {
    if (onCancel()) {
      setShowPrompt(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [onCancel]);

  return showPrompt ? (
    <Modal
      open={showPrompt}
      onClose={() => setShowPrompt(false)}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] sm:h-[435px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[92px] text-ph-orange-alert"
          icon={faTriangleExclamation}
        />
        <h1 className="mt-4 text-[25px] font-bold text-ph-orange-alert">
          {t("Message.Warning")}!
        </h1>
        <p className="mt-6">{t("Message.Edit planting date warning")}</p>
        <div className="mt-7 border-t border-gray-300 pt-8">
          <button
            onClick={handleCancel}
            className="mr-2 px-12 py-3 text-sm font-medium"
          >
            {t("Message.Cancel")}
          </button>
          <button
            onClick={handleOK}
            className="px-12 py-3 bg-ph-orange-alert text-white text-sm rounded-3xl font-medium"
          >
            {t("Message.Ok")}
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default PlantingDateWarning;
