import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import AddHistoryForm from "../AddHistory/AddHistoryForm";
import SpeciesList from "../../../type/speciesList";
import { GrowthHistory } from "../../../type/growthHistory";

const EditHistory: React.FC<{
  onUpdate: (data: any) => void;
  setActiveDate: (newActiveItem: string) => void;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  histories: GrowthHistory[];
  editViewHandler: () => void;
  selectedIndex: number;
  selectOptions: {
    growth_stage: SpeciesList[];
    incident: SpeciesList[];
  };
}> = ({
  onUpdate,
  setActiveDate,
  setIsEdit,
  histories,
  editViewHandler,
  selectOptions,
  selectedIndex,
}) => {
  const { t } = useTranslation();

  const { fetchData, response, error } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/histories/${histories[selectedIndex].slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const { response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/histories/${histories[selectedIndex].slug}/edit/`,
    "GET",
    true,
    "",
    true,
    false
  );

  const closeEditForm = () => {
    editViewHandler();
  };

  return (
    <div className="flex justify-center">
      <div className="w-full h-fit sc:w-[550px]">
        <AddHistoryForm
          onSubmit={onUpdate}
          setActiveDate={setActiveDate}
          setIsEdit={setIsEdit}
          closeForm={closeEditForm}
          sendError={error}
          selectOptions={selectOptions}
          send={fetchData}
          sendResponse={response}
          getInfo={getEditResponse}
          textButton={t("Tree Species.Update")}
          title={t("Growth history.Edit tree growth history")}
        />
      </div>
    </div>
  );
};

export default EditHistory;
