import AddSpecies from "./AddSpecies";
import { useTranslation } from "react-i18next";
import Slider from "../../Global/Slider";
import BreadCrumb from "../../Global/BreadCrumb";

const AddTreeSpecies = () => {
  const { t } = useTranslation();
  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree Species.Add species")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree Species.Tree species"),
            url: "/setting/tree-species",
          },
          { name: t("Tree Species.Add species"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddSpecies />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <Slider
            text={true}
            images={["/img/addSpe1.svg", "/img/addSpe2.svg"]}
          />
        </div>
      </div>
    </div>
  );
};

export default AddTreeSpecies;
