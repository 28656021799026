import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Municipality } from "../type/municipality";
import { Department } from "../type/department";

type CommunityFilter = {
  isActive: string;
  department: Department | null | undefined;
  municipality: Municipality | null | undefined;
};
const initialState: {
  filterItem: CommunityFilter;
  url: string;
  filterSet: boolean;
  deleteFilter: "isActive" | "department" | "municipality" | "";
} = {
  filterItem: {
    isActive: "",
    department: null,
    municipality: null,
  },
  url: "/communities/?",
  filterSet: false,
  deleteFilter: "",
};

const communityFilterSlice = createSlice({
  name: "communityFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<CommunityFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    setURL: (state) => {
      state.url = `/communities/?is_active=${
        state.filterItem.isActive
      }&department=${
        state.filterItem.department ? state.filterItem.department.slug : ""
      }&municipality=${
        state.filterItem.municipality ? state.filterItem.municipality.slug : ""
      }`;
    },
    removeStatus: (state) => {
      state.filterItem.isActive = "";
      state.deleteFilter = "isActive";
    },
    removeDepartment: (state) => {
      state.filterItem.department = null;
      state.filterItem.municipality = null;
      state.deleteFilter = "department";
    },
    removeMunicipality: (state) => {
      state.filterItem.municipality = null;
      state.deleteFilter = "municipality";
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.isActive = "";
      state.filterItem.department = null;
      state.filterItem.municipality = null;
    },
    isFilterSet: (state) => {
      if (
        !state.filterItem.isActive &&
        !state.filterItem.department &&
        !state.filterItem.municipality
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const communityFilterAction = communityFilterSlice.actions;
export default communityFilterSlice;
