import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../Navbar/Dropdown";
import { Link } from "react-router-dom";

const Logo = () => {
  const { i18n } = useTranslation();
  return (
    <Fragment>
      <div className="absolute right-0">
        <Dropdown />
      </div>
      <Link to="/">
        {" "}
        <img
          src={
            i18n.language === "en"
              ? "/img/EnglishLoginLogo.png"
              : "/img/TE logo Center.svg"
          }
          alt="logo"
          className="mx-auto"
        />
      </Link>
    </Fragment>
  );
};

export default Logo;
