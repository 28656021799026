import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type WorkshopFilter = {
  dateFrom: string;
  dateTo: string;
  partiFrom: string;
  partiTo: string;
};
const initialState: {
  filterItem: WorkshopFilter;
  url: string;
  filterSet: boolean;
  deleteFilter: "date" | "par" | "";
} = {
  filterItem: {
    dateFrom: "",
    dateTo: "",
    partiFrom: "",
    partiTo: "",
  },
  url: "/?",
  filterSet: false,
  deleteFilter: "",
};

const workshopFilterSlice = createSlice({
  name: "workshopFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<WorkshopFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removeDate: (state) => {
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.deleteFilter = "date";
    },
    removeParticipantsNum: (state) => {
      state.filterItem.partiFrom = "";
      state.filterItem.partiTo = "";
      state.deleteFilter = "par";
    },
    setURL: (state) => {
      state.url = `/?date_from=${state.filterItem.dateFrom}&date_to=${state.filterItem.dateTo}&participants_from=${state.filterItem.partiFrom}&participants_to=${state.filterItem.partiTo}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.filterItem.partiFrom = "";
      state.filterItem.partiTo = "";
      state.deleteFilter = "";
    },
    isFilterSet: (state) => {
      if (
        state.filterItem.dateFrom === "" &&
        state.filterItem.dateTo === "" &&
        state.filterItem.partiFrom === "" &&
        state.filterItem.partiTo === ""
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const workshopFilterAction = workshopFilterSlice.actions;
export default workshopFilterSlice;
