import { useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import CheckEmail from "./CheckEmail";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Logo from "./Logo";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [valid, isValid] = useState<boolean>(false);
  const [recaptchToken, setRecaptchToken] = useState<string>("");
  const [emailSent, isEmailSet] = useState<boolean>(false);
  const [showError, isShowError] = useState<boolean>(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { i18n, t } = useTranslation();
  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)) {
      isValid(true);
    } else {
      isValid(false);
    }
  };
  const onVerify = useCallback((token: string) => {
    setRecaptchToken(token);
  }, []);
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRefreshReCaptcha((r) => !r);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/forget-password/",
        {
          email: email,
          google_recaptcha: recaptchToken,
        },
        {
          headers: {
            "Content-Type": `application/json`,
            "Accept-Language": i18n.language,
          },
        }
      )
      .then(function (response) {
        isEmailSet(true);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          toast.error(t("Message.Our system thinks you might be a robot!"));
        }
        isEmailSet(false);
      });
  };
  return (
    <div className="w-full h-screen login-bg flex justify-center items-center min-h-[550px]">
      {emailSent ? (
        <CheckEmail email={email} />
      ) : (
        <div className="bg-white w-[451px] h-fit shadow-lg rounded-md p-9 relative">
          <Logo />
          <h1 className="font-semibold text-center mt-9 text-default-text">
            {t("Login.Forgot password?")}
          </h1>
          <form onSubmit={sendEmail} className="mt-9">
            <p className="text-sm text-center text-default-text">
              {t("Login.Please enter your registered email address.")}
            </p>

            <input
              onBlur={() => {
                isShowError(true);
              }}
              onChange={changeEmail}
              type="text"
              placeholder="Email"
              className="input-field text-sm py-3 pl-6 mt-9"
            />
            <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            />
            {showError && !valid && (
              <p className="error-text">
                {t("Message.Invalid email address format")}
              </p>
            )}

            <button
              disabled={!valid}
              type="submit"
              className={`${
                !valid ? "green-btn-disable" : "green-btn"
              } w-full mt-10 py-3 text-sm font-medium`}
            >
              {t("Login.Send email")}
            </button>
          </form>

          <div className="text-center mt-14">
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
