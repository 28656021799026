import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import ActionItem from "../../Global/ActionItem";
import { helpVideos } from "../../../type/helpVideos";
import InfoPopover from "../../Register/RegisterList/InfoPopover";
import { ThemeProvider } from "@emotion/react";
import { TableThem } from "../../../styles/TableThem";
import useAxios from "../../../hook/useAxios";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const HelpVideosTableItem: React.FC<{
  helpVideos: helpVideos;
  fetchData: () => void;
  lastIndex: number;
}> = ({ helpVideos, lastIndex, fetchData: propFetchData }) => {
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { i18n, t } = useTranslation();
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/help_videos/${helpVideos.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );
  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const hide = () => {
    isShowDelete(false);
  };
  const index = lastIndex > 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const title =
    helpVideos.title_transes.en === ""
      ? helpVideos.title_transes.es
      : helpVideos.title_transes.en;

  return (
    <ThemeProvider theme={TableThem}>
      <Fragment>
        <TableCell align="left">
          <div className="flex items-center">
            {i18n.language === "en" ? title : helpVideos.title_transes.es}
            {helpVideos.description_transes.es !== "" && (
              <InfoPopover
                index={index}
                scroll={scroll}
                desc={helpVideos.description_transes}
              />
            )}
          </div>
        </TableCell>
        <TableCell align="left">
          {i18n.language === "en"
            ? helpVideos.page_transes.en
            : helpVideos.page_transes.es}
        </TableCell>
        <TableCell align="left">
          <a
            className="hover:text-ph-hover block w-[240px] whitespace-nowrap overflow-ellipsis overflow-hidden "
            title={helpVideos.url}
            href={helpVideos.url}
          >
            {helpVideos.url}
          </a>
        </TableCell>
        <TableCell align="right">
          <div>
            <ActionItem
              delete={true}
              editLink={`/tools/help-videos/edit-videos/${helpVideos.slug}`}
              deleteAction={() => isShowDelete(true)}
              view=""
              viewLink=""
              historyLink=""
              viewHistory=""
              index={index}
              scroll={scroll}
            />
          </div>
        </TableCell>
        {showDelete && (
          <WarningRedAlert
            type={t("Help videos.Help videos")}
            hide={hide}
            action={deleteItem}
            isDelete={true}
            isSignOut={false}
          />
        )}
      </Fragment>
    </ThemeProvider>
  );
};

export default HelpVideosTableItem;
