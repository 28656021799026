import { useEffect, useState } from "react";
import Slider from "./Slider";
import { responseFile } from "../../type/responseFile";

const EditSlider: React.FC<{ images: string[]; response: any }> = ({
  images,
  response,
}) => {
  const [imageFile, setImageFile] = useState<responseFile[]>([]);

  useEffect(() => {
    if (response) {
      let resource: responseFile[] = [...response.data.files];
      if (response.data?.planted_tree_image) {
        resource.push({
          file_address: response.data?.planted_tree_image,
          id: 1,
          is_image: true,
          thumbnail_address: response.data?.planted_tree_image,
        });
      }
      getImageFiles(resource);
    }
  }, [response]);

  const getImageFiles = (value: responseFile[]) => {
    const image_files = value.filter((file) => file.is_image);
    setImageFile(image_files);
  };

  return (
    <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
      {imageFile.length > 0 || images.length > 1 ? (
        <Slider
          text={false}
          images={imageFile.length > 0 ? imageFile : images}
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img src={images[0]} alt="edit image" className="mt-4 ml-2 xl:ml-10" />
      )}
    </div>
  );
};

export default EditSlider;
