import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  responsibleFilters,
  responsibleFilterSet,
  responsibleURL,
} from "../../../store";
import AppliedItem from "../../Register/RegisterList/AppliedItem";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import ResponsibleFilterBox from "./ResponsibleFilterBox";
import { responsibleFilterAction } from "../../../store/responsibleFilter-slice";
import useAxios from "../../../hook/useAxios";
import { ResponsibleRecord } from "../../../type/responsibleRecord";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import GenericTable from "../../Global/GenericTable";
import ResponsibleTableItem from "./ResponsibleTableItem";

const RegisterResponsible = () => {
  const [responsibleList, setResponsibleList] = useState<ResponsibleRecord[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const dispatch = useDispatch();
  const filters = useSelector(responsibleFilters);
  const isFilter = useSelector(responsibleFilterSet);
  const url = useSelector(responsibleURL);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setResponsibleList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Tree register responsible.Register responsible type")}
        </h1>
        <Link
          to="/setting/responsible/add-responsible"
          className="green-btn px-[23px] py-[10px] text-sm"
        >
          {t("Tree register responsible.Add responsible type")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree register responsible.Register responsible type"),
            url: "",
          },
        ]}
      />
      <ResponsibleFilterBox />
      {isFilter && (
        <div className="bg-white p-4 rounded-md relative mt-3">
          <h1 className="text-ph-gray font-medium text-sm">
            {t("Tree Species.Applied Filters")}
          </h1>
          <div className="flex flex-wrap items-center">
            {filters.category && (
              <AppliedItem
                type={t("Register.Responsible category")}
                value={`${
                  filters.category === "ins" ? t("Register.Institution") : ""
                }  ${filters.category === "grp" ? t("Register.Group") : ""} 
            ${filters.category === "fun" ? "FUNDAEC" : ""}`}
                removeFilter={() => {
                  dispatch(responsibleFilterAction.removeCategory());
                  dispatch(responsibleFilterAction.setURL());
                  dispatch(responsibleFilterAction.isFilterSet());
                }}
              />
            )}

            {filters.isActive && (
              <AppliedItem
                type={t("Workshops.Workshop status")}
                value={
                  filters.isActive === "true"
                    ? t("Department.Active")
                    : t("Department.Inactive")
                }
                removeFilter={() => {
                  dispatch(responsibleFilterAction.removeIsActive());
                  dispatch(responsibleFilterAction.setURL());
                  dispatch(responsibleFilterAction.isFilterSet());
                }}
              />
            )}
          </div>
        </div>
      )}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          dataList={responsibleList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            {
              name: t("Tree register responsible.Responsible category"),
              value: "category",
            },
            {
              name: t("Tree register responsible.Responsible type"),
              value: "type",
            },
            { name: t("Workshops.Workshop status"), value: "is_active" },
          ]}
          renderItem={(responsible, fetchData, lastIndex) => (
            <ResponsibleTableItem
              responsible={responsible}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        {responsibleList.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-default-text">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + responsibleList.length
              } ${t("Message.of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default RegisterResponsible;
