import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddPlantingOrganizerForm from "../AddPlantingOrganizer/AddPlantingOrganizerForm";

const EditPlantingOrganizer = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/organizers/${id.substring(4)}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const { fetchData: getCollaborator, response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/organizers/${id.substring(4)}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    getCollaborator({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title;
  if (id.split("-")[0] === "psa") {
    title = t("Organizer.PSA groups");
  } else if (id.split("-")[0] === "ind") {
    title = t("Organizer.Individuals");
  } else {
    title = t("Organizer.Institutions");
  }

  let button_title;
  if (id.split("-")[0] === "psa") {
    button_title = t("Register.PSA group");
  } else if (id.split("-")[0] === "ind") {
    button_title = t("Planting organizers.individual");
  } else {
    button_title = t("Planting organizers.institution");
  }

  return (
    <div className="parent-container">
      <h1 className="font-bold mt-5 text-default-text">
        {t("PlantingFilter.Edit")}
        {button_title}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Planting organizers.Planting organizers"), url: "" },
          { name: title, url: `/setting/organizers/${id.split("-")[0]}` },
          { name: t("PlantingFilter.Edit") + button_title, url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddPlantingOrganizerForm
            send={fetchData}
            sendResponse={response}
            textButton={t("Tree Species.Update")}
            type={id.split("-")[0]}
            getInfo={getEditResponse}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add Collaborator.svg"
            alt="Edit planting organizer"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default EditPlantingOrganizer;
