import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { ImportRegisterRecord } from "../../type/importRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

const ImportRegisterTableItem: React.FC<{
  importItem: ImportRegisterRecord;
  fetchData: () => void;
}> = ({ importItem, fetchData: propFetchData }) => {
  const { i18n, t } = useTranslation();

  const handleExport = () => {
    const csv = importItem?.unimport_register_log;
    const BOMprefix = "\uFEFF";
    const blob = new Blob([`${BOMprefix}${csv}`], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "unImported_registers.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const eventName =
    importItem.planting_name_transes.en === ""
      ? importItem.planting_name_transes.es
      : importItem.planting_name_transes.en;

  let status = (
    <span className="block text-xs font-medium text-ph-orange-alert bg-[#FFE7C9] text-center px-4 py-2 rounded-3xl">
      {t("Import register.Partially imported")}
    </span>
  );
  if (importItem.status_transes.en === "Failed") {
    status = (
      <span className="block text-xs font-medium text-ph-red-warning bg-ph-toggle-bg-red text-center px-4 py-2 rounded-3xl">
        <div className="flex items-center justify-center">
          {t("Import register.Failed")}

          {!importItem.unimport_register_log && (
            <div className="status-btn">
              <FontAwesomeIcon className="w-4 h-4" icon={faInfoCircle} />
              <div className="status-text shadow-2xl">
                <p>
                  {t(
                    "Import register.The file format is incorrect. Please download and use the latest import register file."
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </span>
    );
  } else if (importItem.status_transes.en === "Completed") {
    status = (
      <span className="block text-xs font-medium text-ph-toggle-text-green bg-ph-toggle-bg-green text-center px-4 py-2 rounded-3xl">
        {t("Import register.Completed")}
      </span>
    );
  } else if (importItem.status_transes.en === "In Progress") {
    status = (
      <span className="w-[120px] block text-xs font-medium text-[#8268D7] bg-[#EEEAFD] text-center px-4 py-2 rounded-3xl">
        <FontAwesomeIcon
          className="animate-spin mr-2 w-[12px] h-[12px]"
          icon={faSpinnerThird}
        />
        {t("Import register.In progress")}
      </span>
    );
  }
  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "en"
          ? eventName
          : importItem.planting_name_transes.es}
      </TableCell>
      <TableCell align="left">{importItem.planting_date}</TableCell>
      <TableCell align="left">
        <a
          className="hover:text-ph-hover block w-[120px] whitespace-nowrap overflow-ellipsis overflow-hidden"
          title={importItem.import_file_address.split("/")[6]}
          href={importItem.import_file_address}
        >
          {importItem.import_file_address.split("/")[6]}
        </a>
      </TableCell>
      <TableCell align="left">{status}</TableCell>
      <TableCell align="left">
        <p>{importItem.import_rows_no}</p> <br />
        <p className="text-xs mt-[-12px]">
          {t("Import register.Total")}: {importItem.total_rows_no}
        </p>
      </TableCell>

      <TableCell align="left">
        {importItem?.completion_time && (
          <Fragment>
            <p>{importItem.completion_time.split(" ")[0]}</p> <br />
            <p className="mt-[-12px]">
              {importItem.completion_time.split(" ")[1]}
            </p>
          </Fragment>
        )}
      </TableCell>
      <TableCell align="left">
        <span
          className="hover:text-ph-hover cursor-pointer"
          onClick={handleExport}
        >
          {importItem.unimport_register_log && "unImported_registers.csv"}
        </span>
      </TableCell>
    </Fragment>
  );
};

export default ImportRegisterTableItem;
