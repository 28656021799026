import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { Fragment } from "react";

const Video: React.FC<{
  videoIndex: number;
  title: string;
  url: string;
  delete: boolean;
  removeAction: (videoIndex: number) => void;
}> = ({ videoIndex, title, url, delete: propDelete, removeAction }) => {
  const { t } = useTranslation();

  const removeFile = () => {
    removeAction(videoIndex);
  };
  return (
    <div className="w-full flex items-center rounded-md bg-ph-bg-light-white border border-ph-iborder relative mb-2">
      <span className="w-[60px] h-[60px] bg-ph-bg-white flex items-center justify-center">
        <FontAwesomeIcon
          className="text-ph-gray w-[30px] h-[30px]"
          icon={faVideo}
        />
      </span>

      <div className="ml-3">
        <p className="text-sm font-medium text-default-text h-5 w-[300px] whitespace-nowrap overflow-hidden text-ellipsis">
          {title}
        </p>
        <a href={url} target="_blank" rel="noreferrer">
          <p className="text-xs h-5 truncate w-32 sm:w-80 md:w-96 lg:w-48 xl:w-96">
            {url}
          </p>
        </a>
      </div>
      {propDelete && (
        <Fragment>
          <p
            onClick={removeFile}
            className="absolute right-3 text-default-text text-sm hover:text-ph-red-warning cursor-pointer"
          >
            <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
            {t("PlantingFilter.Delete")}
          </p>
        </Fragment>
      )}
    </div>
  );
};

export default Video;
