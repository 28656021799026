import { createTheme } from "@mui/material/styles";
import { colors } from "../../../styles/colors";

export const theme = createTheme({
  palette: {
    warning: {
      main: colors.default_highlight,
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: colors.light_gray,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Inter,sans-serif",
          fontSize: "14px",
          "&.Mui-focused": {
            outline: "1px solid #efbf07",
            backgroundColor: "white",
            border: "none",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingTop: "5.45px",
            paddingBottom: "5.45px",
            paddingLeft: "10px",
            "&.Mui-disabled": {
              color: colors.white,
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root.Mui-error": {
            color: colors.gray_theme,
          },
          "& .MuiOutlinedInput-input": {
            padding: "12.2px  14px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.light_gray,
          fontSize: "14px",
          fontFamily: "Inter,sans-serif",
          top: "-4.5px",
          "&.Mui-focused": {
            color: colors.light_gray,
            fontSize: "14px",
            fontFamily: "Inter,sans-serif",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.background_white,
        },
        notchedOutline: {
          borderColor: colors.gray_white,
        },
      },
    },
  },
});
