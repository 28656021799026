import { useTranslation } from "react-i18next";
import useAxios from "../../hook/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import TranslationField from "../../type/translationField";
import ChangePasswordForm from "./ChangePasswordForm";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginSliceActions } from "../../store/login-slice";
import Loading from "../Global/Loading";

type Profile = {
  slug: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  phone: string;
  role_transes: TranslationField;
  profile_picture: string;
};

const ChangePassword = () => {
  const [profile, setProfile] = useState<Profile>({
    slug: "",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    phone: "",
    role_transes: { en: "", es: "" },
    profile_picture: "",
  });
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { response: profileResponse, loading } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/user/profile/edit/",
    "GET",
    true,
    "",
    true,
    false
  );

  const { fetchData, response: changePasswordResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/user/change-password/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (profileResponse) {
      setProfile(profileResponse.data);
    }
    if (changePasswordResponse) {
      dispatch(LoginSliceActions.userLogOut());
      history.replace("/");
      history.block(() => {});
      toast.success(t("Message.Your password updated successfully"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileResponse, changePasswordResponse]);

  return (
    <Fragment>
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="parent-container">
          <h1 className="font-semibold text-default-text text-[16px] mt-5">
            {t("Change password.Change password")}
          </h1>
          <div className="flex flex-wrap justify-between mt-4">
            <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
              <div className="bg-ph-bg-white rounded-md p-6">
                <div className="flex flex-wrap justify-center">
                  {profile.profile_picture ? (
                    <img
                      className="object-contain w-[130px] h-[130px] rounded-md"
                      alt="profile"
                      src={profile.profile_picture}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="text-[#9D9A9ABD] w-[130px] h-[130px]"
                      icon={faUser}
                    />
                  )}
                  <div className="flex justify-between mt-5 sm:mt-0">
                    <div className="pr-8 sm:pr-[70px] sm:pl-12">
                      <p className="text-sm">{t("Users.First name")}</p>
                      <p className="text-sm mt-4">{t("Users.Last name")}</p>
                      <p className="text-sm mt-4">{t("Users.Role")}</p>
                      <p className="text-sm mt-4">{t("Users.Phone")}</p>
                      <p className="text-sm mt-4">{t("Users.Address")}</p>
                      <p className="text-sm mt-4">{t("Users.Email")}</p>
                    </div>
                    <div className="pr-4">
                      <p className="text-sm font-medium">
                        {profile.first_name}
                      </p>
                      <p className="text-sm mt-4 font-medium">
                        {profile.last_name}
                      </p>
                      <p className="text-sm mt-4 font-medium">
                        {i18n.language === "en"
                          ? profile.role_transes.en
                          : profile.role_transes.es}
                      </p>
                      <p className="text-sm mt-4 font-medium">
                        {profile.phone || "-"}
                      </p>
                      <p className="text-sm mt-4 font-medium">
                        {profile.address || "-"}
                      </p>
                      <p className="text-sm mt-4 font-medium">
                        {profile.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <ChangePasswordForm send={fetchData} />
            </div>
            <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
              <img
                src="/img/Change password.svg"
                alt="Change password"
                className="mt-4 ml-2"
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ChangePassword;
