/* eslint-disable no-useless-escape */
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import { ThemeProvider } from "@emotion/react";
import SelectLanguage from "../../Global/SelectLanguage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo
} from "@fortawesome/pro-light-svg-icons";
import LanguagePoint from "../../Global/LanguagePoint";
import { theme } from "../../Register/AddRegister/FormTheme";
import TranslationField from "../../../type/translationField";

type FormValues = {
  title: string;
  page: string;
  description?: string;
  url: string;
};

const AddVideosForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = ({ send, sendResponse, getInfo, textButton }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [dirty, isDirty] = useState<boolean>(true);
  const [lang, setLang] = useState<string>("ES");

  const [translatableInput, setTranslatableInput] = useState<{
    title: TranslationField;
    description: TranslationField;
  }>({
    title: { en: "", es: "" },
    description: { en: "", es: "" },
  });

  const {
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
    control,
  } = useForm<FormValues>();

  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };
  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${
          getInfo
            ? `${t("Message.The record updated successfully")}`
            : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace("/tools/help-videos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setTranslatableInput({
        description: getInfo.data.description_transes,
        title: getInfo.data.name_transes,
      });
      setLoading(true);
      setTranslatableInput({
        title: getInfo.data.title_transes,
        description: getInfo.data.description_transes,
      });
      setValue("page", getInfo.data.page);
      setValue("url", getInfo.data.url);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            en: event.target.value,
            es: prevState.description.es,
          },
        };
      });
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            en: prevState.description.en,
            es: event.target.value,
          },
        };
      });
    }
  };

  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          title: { en: event.target.value, es: prevState.title.es },
        };
      });
      if (event.target.value !== "") {
        clearErrors("title");
      }
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          title: { en: prevState.title.en, es: event.target.value },
        };
      });
      if (event.target.value !== "") {
        clearErrors("title");
      }
    }
  };

  const addHelpVideos = (data: FormValues) => {
    isDirty(false);
    send({
      title_transes: translatableInput.title,
      page: data.page,
      description_transes: translatableInput.description,
      url: data.url,
    });
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addHelpVideos)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-default-text mb-[6px]">
              {t("Help videos.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <div className="flex flex-wrap justify-between items-center mt-0 sm:mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2 ">
                <p className="text-sm text-default-text mb-[6px] mt-4 sm:mt-0">
                  {t("Help videos.Page")} *
                </p>
                <Controller
                  control={control}
                  name="page"
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && t("Help videos.Page")}
                      </InputLabel>
                      <Select
                        style={{ height: "48px" }}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || ""}
                        autoFocus={errors.page ? true : false}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="st">
                          {t("Help videos.Statistics")}
                        </MenuItem>
                        <MenuItem value="pe">
                          {t("Help videos.Planting events")}
                        </MenuItem>
                        <MenuItem value="ar">
                          {t("Help videos.Add register")}
                        </MenuItem>
                        <MenuItem value="tr">
                          {t("Help videos.Tree register")}
                        </MenuItem>
                        <MenuItem value="mp">{t("Help videos.Map")}</MenuItem>
                        <MenuItem value="ir">
                          {t("Help videos.Import register")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                <div className="h-5">
                  {errors.page && (
                    <p className="error-text ">{t("Message.Required field")}</p>
                  )}
                </div>
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-4 sm:mt-0">
                  {t("Help videos.Title")} * <LanguagePoint lang={lang} />
                </p>
                <input
                  name="title"
                  value={
                    lang === "EN"
                      ? translatableInput.title.en
                      : translatableInput.title.es
                  }
                  onChange={titleChange}
                  type="text"
                  className="input-field text-sm pl-4 py-3 mb- h-[48px] placeholder-ph-light-gray"
                  placeholder={t("Help videos.Title") + ""}
                />
                <div className="h-5">
                  {errors?.title?.type === "custom" && (
                    <p className="error-text">{t("Message.Required field")}</p>
                  )}
                </div>
              </div>
            </div>

            <p className="text-sm text-default-text mt-10 mb-[6px]">
              {t("Help videos.Description")} <LanguagePoint lang={lang} />
            </p>
            <textarea
              onChange={descriptionChange}
              value={
                lang === "EN"
                  ? translatableInput.description.en
                  : translatableInput.description.es
              }
              rows={7}
              className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
              placeholder={t("Help videos.Description") + ""}
            />
            <p className="text-sm text-default-text mb-[6px] mt-7">
              {t("Plantings.Video URL")} *
              <span
              title={
                t("Help videos.Valid YouTube link sample") + "\n https://www.youtube.com/watch=VIDEO_ID"
              }
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
            </p>
            <input
              {...register("url", {
                required: `${t("Message.Required field")}`,
                pattern:
                  /^(https?:\/\/|ftp:\/\/)([\w-]+(\.[\w-]+)+)([\w@?^=%&:/~+#-](?!.*,)[\w.,@?^=%&:/~+#-]*)$/,
              })}
              type="text"
              id="addCollaborator"
              placeholder={t("Plantings.Video URL") + ""}
              className="text-sm w-full pl-4 rounded-md py-3 bg-ph-input border focus:bg-white focus:outline-none focus:border-default-highlight border-ph-iborder placeholder-ph-light-gray"
            />
            {errors.url && <p className="error-text">{errors.url.message}</p>}
            {errors.url?.type === "pattern" && (
              <p className="error-text">{t("Message.Invalid URL")}</p>
            )}
            <button
              type="submit"
              className="green-btn text-sm font-medium px-6 py-2 mt-5 float-right"
              onClick={() => {
                if (
                  translatableInput.title.es.trim() === "" &&
                  translatableInput.title.en.trim() === ""
                ) {
                  setError("title", {
                    type: "custom",
                    message: `${t("Message.Required field")}`,
                  });
                }
              }}
            >
              {textButton}
            </button>
          </ThemeProvider>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddVideosForm;
