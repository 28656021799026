import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  departmentFilters,
  departmentFilterSet,
  departmentURL,
} from "../../../store";
import { departmentFilterAction } from "../../../store/departmentFilter-slice";
import AppliedItem from "../../Register/RegisterList/AppliedItem";
import DepartmentFilterBox from "./DepartmentFilterBox";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import GenericTable from "../../Global/GenericTable";
import DepartmentTableItem from "./DepartmentTableItem";
import useAxios from "../../../hook/useAxios";
import { DepartmentRecord } from "../../../type/departmentRecord";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";

const Departments = () => {
  const [departmentList, setDepartmentList] = useState<DepartmentRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const dispatch = useDispatch();
  const url = useSelector(departmentURL);
  const filters = useSelector(departmentFilters);
  const isFilter = useSelector(departmentFilterSet);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setDepartmentList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Navbar.Departments")}
        </h1>
        <Link
          to="/setting/departments/add-department"
          className="green-btn px-[23px] py-[10px] text-sm"
        >
          {t("Department.Add department")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Navbar.Departments"), url: "" },
        ]}
      />
      <DepartmentFilterBox />
      {isFilter && (
        <div className="bg-white p-4 rounded-md relative mt-3">
          <h1 className="text-ph-gray font-medium text-sm">
            {t("Tree Species.Applied Filters")}
          </h1>
          <div className="flex flex-wrap items-center">
            <AppliedItem
              type={t("Workshops.Workshop status")}
              value={
                filters.isActive === "true"
                  ? t("Department.Active")
                  : t("Department.Inactive")
              }
              removeFilter={() => {
                dispatch(departmentFilterAction.clearFilter());
                dispatch(departmentFilterAction.setURL());
              }}
            />
          </div>
        </div>
      )}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          dataList={departmentList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Plantings.Country"), value: "" },
            { name: t("PlantingFilter.Department"), value: "name" },
            { name: t("Workshops.Workshop status"), value: "is_active" },
          ]}
          renderItem={(department, fetchData, lastIndex) => (
            <DepartmentTableItem
              department={department}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        {departmentList.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-default-text">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + departmentList.length
              } ${t("Message.of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default Departments;
