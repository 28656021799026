import { useSelector } from "react-redux";
import { profileInfo } from "../../store";
import InvalidPage from "../ErrorAndAlert/InvalidPage";
import { useTranslation } from "react-i18next";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const profile = useSelector(profileInfo);
  const { t } = useTranslation();

  if (profile.role === "O" || profile.role === "V") {
    return (
      <InvalidPage
        src="/img/Error pack - Access Denied.svg"
        alt="Access Denied"
        header={t("Message.Access denied")}
      />
    );
  }

  return children;
};

export default ProtectedRoute;
