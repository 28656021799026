import { ThemeProvider } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectChangeEvent } from "@mui/material";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import NumberField from "../../Global/NumberField";
import SpeciesList from "../../../type/speciesList";
import CustomDate from "../../Global/CustomDate";
import { theme } from "../../Register/AddRegister/FormTheme";
import CustomAutocomplete from "../../Global/CustomAutocomplete";

type FormValues = {
  date: Date;
  height: number;
  diameter: number;
  growth_stage: SpeciesList;
  incident: SpeciesList[];
  is_dead: boolean;
};

const AddHistoryForm: React.FC<{
  setActiveDate: (newActiveItem: string) => void;
  setIsEdit?: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: any) => void;
  closeForm: () => void;
  send: (data: any) => void;
  selectOptions: {
    growth_stage: SpeciesList[];
    incident: SpeciesList[];
  };
  sendResponse: any;
  sendError: any;
  getInfo?: any;
  textButton: string;
  title: string;
}> = ({
  setActiveDate,
  setIsEdit,
  onSubmit,
  closeForm,
  send,
  selectOptions,
  sendResponse,
  sendError,
  getInfo,
  textButton,
  title,
}) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>("ES");
  const [translatableInput, setTranslatableInput] = useState<{
    observation: TranslationField;
    description: TranslationField;
  }>({
    observation: { en: "", es: "" },
    description: { en: "", es: "" },
  });
  const [resources, setResources] = useState<file[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [upload, isUpload] = useState<boolean>(false);
  const [dead, isDead] = useState<boolean>(false);
  const [oldResource, setOldResource] = useState<
    {
      id: number;
      file_address: string;
      thumbnail_address: string;
      is_image: boolean;
    }[]
  >([]);
  const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
  const [requestNumber, setRequestNumber] = useState<number>(1);
  const [dirty, isDirty] = useState<boolean>(true);
  const [fileIndex, setFileIndex] = useState<number>(0);

  const { fetchData: sendFile, response: fileResp } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/files/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const { fetchData: deleteImage } = useAxios(
    "",
    "DELETE",
    false,
    "",
    true,
    true
  );

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  useEffect(() => {
    if (sendResponse) {
      setRequestNumber((prevState) => prevState - 1);
      if (resources.length > 0) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex].data);
        formData.append("model_name", "RegisterHistory");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
      if (deleteRecourse.length > 0) {
        deleteRecourse.forEach((r) => {
          setRequestNumber((prevState) => prevState - 1);
          deleteImage(
            { id: r },
            process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (sendError) {
      if (sendError.response.status === 400) {
        isUpload(false);
      }
    }
  }, [sendError]);

  useEffect(() => {
    if (getInfo) {
      setOldResource(getInfo.data.files);
      setTranslatableInput({
        description: getInfo.data.description_transes,
        observation: getInfo.data.observation_transes,
      });
      const dateF: string[] = getInfo.data.date.split("/");
      setValue(
        "date",
        new Date(Number(dateF[2]), Number(dateF[1]) - 1, Number(dateF[0]))
      );
      if (getInfo.data?.growth_stage_slug) {
        setValue("growth_stage", {
          slug: getInfo.data?.growth_stage_slug,
          name_transes: getInfo.data?.growth_stage_transes,
        });
      } else {
        setValue("growth_stage", {
          slug: "",
          name_transes: { en: "", es: "" },
        });
      }
      setValue("incident", getInfo.data?.incidents);
      setValue("height", getInfo.data.height);
      setValue("diameter", getInfo.data.diameter);
      setValue("is_dead", getInfo.data.is_dead);
      isDead(getInfo.data.is_dead);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    if (fileResp) {
      setRequestNumber((prevState) => prevState - 1);
      setFileIndex(fileIndex + 1);
      if (resources.length - 1 >= fileIndex + 1) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex + 1].data);
        formData.append("model_name", "RegisterHistory");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResp]);

  const getActiveItem = (date: string) => {
    const [day, month, year] = date.split("/");
    const record = `${year}-${month}-${day}`;
    return record;
  };

  useEffect(() => {
    if (requestNumber === 0) {
      setActiveDate(getActiveItem(sendResponse.data.date));
      if (getInfo) {
        if (setIsEdit) {
          setIsEdit(true);
        }
      }
      reset();
      toast.success(
        `${
          getInfo
            ? `${t("Message.The record updated successfully")}`
            : `${t("Message.The record added successfully")}`
        }`
      );
      isUpload(false);
      setRequestNumber(1);
      onSubmit({});
      closeForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber]);

  const insertResources = (files: file[]) => {
    files.forEach((f) => {
      if (f.size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      } else {
        setRequestNumber((prevState) => prevState + 1);
        setResources((prevState) => [...prevState, f]);
      }
    });
  };

  const removeOldResource = (fileId: number) => {
    setDeleteResource((prevState) => [...prevState, fileId]);
    setOldResource((prevState) =>
      prevState.filter((prevItem) => prevItem.id !== fileId)
    );
    setRequestNumber((prevState) => prevState + 1);
  };
  const removeResource = (indexFile: number) => {
    setRequestNumber((prevState) => prevState - 1);
    setResources((prevState) =>
      prevState.filter((prevItem, index: number) => index !== indexFile)
    );
  };

  const observationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          observation: {
            en: event.target.value,
            es: prevState.observation.es,
          },
        };
      });
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          observation: {
            es: event.target.value,
            en: prevState.observation.en,
          },
        };
      });
    }
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            en: event.target.value,
            es: prevState.description.es,
          },
        };
      });
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            es: event.target.value,
            en: prevState.description.en,
          },
        };
      });
    }
  };

  const addHistoryHandler = (data: FormValues) => {
    if (
      (translatableInput.description.en && !translatableInput.description.es) ||
      (translatableInput.observation.en && !translatableInput.observation.es)
    ) {
      toast.error(
        `${t(
          "Message.While other languages have a translation, the default language cannot be empty."
        )}`
      );
    } else {
      isDirty(false);
      const inc: string[] = data.incident?.map((incident) => incident.slug);
      send({
        date: `${data.date.getDate()}/${
          data.date.getMonth() + 1
        }/${data.date.getFullYear()}`,
        growth_stage: data.growth_stage?.slug || null,
        incidents: inc,
        height: data.height || null,
        diameter: data.diameter || null,
        is_dead: dead,
        observation_transes: translatableInput.observation,
        description_transes: translatableInput.description,
      });
      isUpload(true);
    }
  };

  function editDateValidation(date1: string, date2: Date) {
    const [date1Day, date1Month, date1Year] = date1.split("/");

    const date2Year = date2.getFullYear();
    const date2Month = date2.getMonth() + 1;
    const date2Day = date2.getDate();
    if (
      Number(date1Year) === date2Year &&
      Number(date1Month) === date2Month &&
      Number(date1Day) === date2Day
    ) {
      return false;
    } else {
      return true;
    }
  }

  const addHistoryItem = (data: FormValues) => {
    if (
      getInfo &&
      !getInfo?.data.is_date_editable &&
      editDateValidation(getInfo.data.date, getValues("date"))
    ) {
      toast.error(`${t("Message.Not allowed edit register history date")}`);
    } else {
      addHistoryHandler(data);
    }
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addHistoryItem)}>
          <h1 className="font-bold text-default-text text-xl pb-6">{title}</h1>
          <ThemeProvider theme={theme}>
            <div className="w-1/2">
              <p className="text-sm text-default-text mb-[6px]">
                {t("AddPlanting.Language")}
              </p>
            </div>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <div className="flex justify-between mt-7 mb-[6px]">
              <div className="w-1/2 pr-3">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Workshops.Date")} *
                </p>
                <CustomDate
                  control={control}
                  name="date"
                  label={t("AddPlanting.Select date")}
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                />

                {errors.date && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-1/2 pl-3">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Tree height")}{" "}
                  <span className="text-ph-gray-text">
                    {t("Register.in “cm”")}
                  </span>
                </p>
                <NumberField
                  onChange={() => {}}
                  getValues={getValues}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  name="height"
                  placeholder={t("Register.Tree height") + ""}
                  register={register("height", {
                    pattern: /^[+]?\d+/,
                  })}
                  setValue={setValue}
                  styleClass="w-full"
                  readOnl={false}
                />
                {errors.height?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
              </div>
            </div>
            <div className="flex justify-between mt-7 mb-[6px]">
              <div className="w-1/2 pr-3">
                <p className="text-sm text-default-text mb-[6px] mt-5 sm:mt-0">
                  {t("Growth stages.Growth stage")}
                </p>
                <CustomAutocomplete
                  control={control}
                  name="growth_stage"
                  selectOptions={selectOptions.growth_stage}
                  placeholder={t("Growth stages.Growth stage")}
                  getOptionLabel={(option) =>
                    i18n.language === "en"
                      ? option.name_transes?.en === ""
                        ? option.name_transes?.es
                        : option.name_transes?.en
                      : option.name_transes?.es
                  }
                />
              </div>
              <div className="w-1/2 pl-3">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Diameter")}{" "}
                  <span className="text-ph-gray-text">
                    {t("Register.in “mm”")}
                  </span>
                </p>
                <NumberField
                  onChange={() => {}}
                  getValues={getValues}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  name="diameter"
                  placeholder={t("Register.Diameter") + ""}
                  register={register("diameter", {
                    pattern: /^[+]?\d+/,
                  })}
                  setValue={setValue}
                  styleClass="w-full"
                  readOnl={false}
                />
                {errors.diameter?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
              </div>
            </div>

            <div className="w-1/2 pr-3 mt-7">
              <p className="text-sm text-default-text mb-[6px] mt-5 sm:mt-0">
                {t("Incidents.Incident")}
              </p>
              <CustomAutocomplete
                control={control}
                name="incident"
                selectOptions={selectOptions.incident}
                placeholder={t("Incidents.Incident")}
                isMulti={true}
                getOptionLabel={(option) =>
                  i18n.language === "en"
                    ? option.name_transes?.en === ""
                      ? option.name_transes?.es
                      : option.name_transes?.en
                    : option.name_transes?.es
                }
              />
            </div>

            <input
              onChange={() => {
                isDead(!dead);
              }}
              className="accent-green-600 text-white mt-7"
              type="checkbox"
              checked={dead}
              id="dead"
            />
            <label htmlFor="dead" className="text-sm ml-1">
              {t("Growth history.Dead")}
            </label>

            <p className="text-sm text-default-text mt-7 mb-[6px]">
              {t("Growth history.Observation")} <LanguagePoint lang={lang} />
            </p>
            <textarea
              onChange={observationChange}
              value={
                lang === "EN"
                  ? translatableInput.observation.en
                  : translatableInput.observation.es
              }
              rows={7}
              className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
              placeholder={t("Growth history.Observation") + ""}
            />
          </ThemeProvider>

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("AddPlanting.Relevant resources")}{" "}
            <span
              title={
                t("Register.Tree image or any other relevant resources") + ""
              }
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <DragDropField
            files={resources}
            format="jpg, jpeg, png, txt, doc, docx, pdf, xls, xlsx (max 10 MB)"
            insertFile={insertResources}
            removeFile={removeResource}
          />
          {oldResource.map((or) => (
            <OldFile
              address={or.file_address}
              id={or.id}
              delete={true}
              removeOldFile={removeOldResource}
              key={or.id}
            />
          ))}
          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Tree Species.Description")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={descriptionChange}
            value={
              lang === "EN"
                ? translatableInput.description.en
                : translatableInput.description.es
            }
            rows={7}
            className="input-field resize-none text-sm pl-4 py-[10px] placeholder-ph-light-gray"
            placeholder={t("Tree Species.Description") + ""}
          />
          <div className="flex float-right">
            <button
              onClick={() => {
                closeForm();
              }}
              type="button"
              className="text-default-text text-sm mt-16rounded-3xl px-6 py-2 mt-5 font-medium"
            >
              {t("Message.Cancel")}
            </button>
            <button
              type="submit"
              className={`${
                upload ? "green-btn-disable" : "green-btn"
              } text-sm px-6 py-2 mt-5 font-medium`}
              disabled={upload ? true : false}
            >
              {upload ? t("Message.Wait for upload files") : textButton}
            </button>
          </div>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddHistoryForm;
