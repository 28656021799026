import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import Status from "../../Global/Status";

type FormValues = {
  name: string;
};

const AddPlantingOrganizerForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
  type: string;
}> = ({ send, sendResponse, getInfo, textButton, type }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${
          getInfo
            ? `${t("Message.The record updated successfully")}`
            : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace(`/setting/organizers/${type}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setLoading(true);
      setValue("name", getInfo.data.name);
      isActive(getInfo.data.is_active);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  const addCollaborator = (data: FormValues) => {
    isDirty(false);
    send({
      type: type,
      name: data.name,
      is_active: active,
    });
  };
  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-bold">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addCollaborator)}>
          <p className="text-default-text text-sm mb-[6px]">
            {t("Tree Species.Name")} *
          </p>
          <input
            {...register("name", {
              required: `${t("Message.Required field")}`,
            })}
            type="text"
            className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
            placeholder={t("Tree Species.Name") + ""}
          />
          {errors.name && <p className="error-text">{errors.name.message}</p>}
          <div className="mt-7">
            <Status
              active={active}
              isActive={(value) => {
                isActive(value);
              }}
            />
          </div>

          <button
            type="submit"
            className="green-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
          >
            {textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddPlantingOrganizerForm;
