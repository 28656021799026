import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import UserMyProfileForm from "./UserMyProfileForm";
import { Fragment } from "react";
import Loading from "../../Global/Loading";

const EditProfile = () => {
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/user/profile/edit/`,
    "PATCH",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const { response: getEditResponse, loading } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/user/profile/edit/`,
    "GET",
    true,
    "",
    true,
    false
  );

  return (
    <Fragment>
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="parent-container">
          <h1 className="font-semibold text-default-text text-[16px] mt-5">
            {t("Navbar.My profile")}
          </h1>
          <div className="flex flex-wrap justify-between mt-4">
            <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
              <UserMyProfileForm
                send={fetchData}
                sendResponse={response}
                getInfo={getEditResponse}
              />
            </div>
            <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
              <img
                src="/img/My profile.svg"
                alt="Edit profile"
                className="mt-4 ml-2 xl:ml-10"
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EditProfile;
