import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LoginSliceActions } from "../store/login-slice";
import { Link, useHistory } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import Logo from "./Forgot Password/Logo";

const Login = () => {
  const [email, setEmail] = useState<{ text: string; error: boolean }>({
    error: false,
    text: "",
  });
  const [password, setPassword] = useState<{ text: string; error: boolean }>({
    error: false,
    text: "",
  });
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [type, setType] = useState<string>("password");
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [rememberMe, isRememberMe] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const toggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail((prev) => {
      return {
        ...prev,
        text: event.target.value,
      };
    });
  };
  const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword((pState) => {
      return {
        ...pState,
        text: event.target.value,
      };
    });
  };
  let fontIcon = (
    <FontAwesomeIcon
      icon={faEyeSlash}
      className="font-light w-[16px] h-[13px]"
    />
  );

  if (type === "text") {
    fontIcon = (
      <FontAwesomeIcon icon={faEye} className="font-light w-[16px] h-[13px]" />
    );
  }
  const headers = {
    "Content-Type": "application/json",
  };
  const submitLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRefreshReCaptcha((r) => !r);
    isRememberMe(false);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/token/",
        {
          remember_me: rememberMe,
          google_recaptcha: recaptchaToken,
          email: email.text,
          password: password.text,
        },
        { headers }
      )
      .then(function (response) {
        dispatch(
          LoginSliceActions.userLogin({
            token: response.data.access,
            refresh: response.data.refresh,
          })
        );
        history.replace("/");
      })
      .catch(function (error) {
        if (error.response.status === 400 && recaptchaToken) {
          toast.error(t("Message.Our system thinks you might be a robot!"));
        } else if (error.response.status === 401) {
          toast.error(t("Login.Wrong username or password"));
          setPassword({ error: false, text: "" });
        } else if (error.response.status === 0) {
          toast.error("Network Error.");
        }
      });
  };
  return (
    <div className="w-full h-screen login-bg flex justify-center items-center min-h-[550px]">
      <div className="bg-white w-[451px] h-fit shadow-lg rounded-md p-9 relative">
        <Logo />
        <p className="mt-10 text-sm text-center text-default-text">
          {t("Login.Enter your details to login to your account")}
        </p>
        <form onSubmit={submitLogin}>
          <input
            onChange={changeEmail}
            onBlur={() => {
              setEmail((prevState) => {
                return {
                  ...prevState,
                  error: true,
                };
              });
            }}
            className="input-field pl-6 py-3 mt-10 text-sm placeholder-ph-light-gray"
            type="text"
            placeholder="Email"
            value={email.text}
          />
          {email.error &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.text) && (
              <p className="error-text">
                {email.text.trim() === ""
                  ? t("Message.Required field")
                  : t("Message.Invalid email address format")}
              </p>
            )}

          <div className="relative">
            <input
              value={password.text}
              onChange={changePassword}
              onBlur={() => {
                setPassword((prevState) => {
                  return {
                    ...prevState,
                    error: true,
                  };
                });
              }}
              className="input-field pl-6 pr-10 py-3 mt-4 text-sm placeholder-ph-light-gray"
              type={type}
              placeholder={t("Login.Password") + ""}
            />

            <span
              onClick={toggle}
              className="text-ph-gray cursor-pointer absolute top-[26px] right-4"
            >
              {fontIcon}
            </span>
          </div>
          {password.error && password.text.trim() === "" && (
            <p className="error-text mb-2">{t("Message.Required field")}</p>
          )}

          <input
            onChange={() => {
              isRememberMe(!rememberMe);
            }}
            className="accent-green-600 text-white mt-[14px]"
            type="checkbox"
            id="remember"
            name="remember"
            value="Remember me"
          />
          <label
            htmlFor="remember"
            className="text-sm ml-1 mt-[14px] text-default-text"
          >
            {t("Login.Remember me")}
          </label>
          <Link
            to="/forgotPassword"
            className="text-sm text-ph-hover float-right mt-[11px]"
          >
            {t("Login.Forgot password")}
          </Link>
          <br />
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
          <button
            type="submit"
            disabled={
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.text) ||
              password.text.trim() === ""
                ? true
                : false
            }
            className={`${
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.text) ||
              password.text.trim() === ""
                ? "green-btn-disable"
                : "green-btn"
            } w-full mt-10 py-3 text-sm font-medium`}
          >
            {t("Login.Login")}
          </button>
        </form>
        <div className="mt-6 sm:mt-9 text-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Login;
