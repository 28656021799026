import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUser } from "@fortawesome/pro-regular-svg-icons";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import { Workshops } from "../../../type/workshops";
import ActionItem from "../../Global/ActionItem";
import { useTranslation } from "react-i18next";
import WorkshopParticipants from "./WorkshopParticipants";
import WorkshopSlider from "./WorkshopSlider";
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Link } from "react-router-dom";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const WorkshopGrid: React.FC<{
  workshop: Workshops;
  fetchData: () => void;
}> = ({ workshop, fetchData: propFetchData }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/workshop/${workshop.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  const name =
    workshop.name_transes.en === ""
      ? workshop.name_transes.es
      : workshop.name_transes.en;
  const desc =
    workshop.description_transes.en === ""
      ? workshop.description_transes.es
      : workshop.description_transes.en;
  return (
    <Fragment>
      <div className="w-full lg:w-[49%] mb-4 p-5 rounded-md shadow-sm border border-ph-iborder text-sm hover:shadow-lg">
        <div className="flex justify-between items-center">
          <Link
            className="font-semibold text-default-text hover:text-ph-hover"
            to={`/workshops/view-workshop/${workshop.slug}`}
          >
            {i18n.language === "en" ? name : workshop.name_transes.es}
          </Link>
          <ActionItem
            delete={true}
            editLink={`/workshops/edit-workshop/${workshop.slug}`}
            deleteAction={deleteAction}
            view={t("Workshops.View workshop")}
            viewLink={`/workshops/view-workshop/${workshop.slug}`}
            historyLink=""
            viewHistory=""
          />
        </div>
        {workshop.images?.length > 0 ? (
          <WorkshopSlider images={workshop.images} />
        ) : (
          <div className="w-full h-[269px] bg-ph-bg-white rounded-md mt-2 flex items-center justify-center">
            <img src="/img/No Pic placeholder illu.svg" alt="noPic" />
          </div>
        )}

        <p
          title={
            i18n.language === "en" ? desc : workshop.description_transes.es
          }
          className="py-2 grid-desc"
        >
          {i18n.language === "en" ? desc : workshop.description_transes.es}
        </p>
        <hr />
        <div className="mt-2">
          <div className="sm:flex">
            <div className="w-full sm:w-[50%]">
              <span className="text-ph-gray">
                <FontAwesomeIcon icon={faCalendarDays} />{" "}
                {t("PlantingFilter.From")}:
              </span>
              <p className="w-9 inline px-3">
                {workshop.date_from} {workshop.time_from}
              </p>
            </div>
            <div className="w-[250px] h-5 mt-5 sm:mt-0 whitespace-nowrap overflow-hidden text-ellipsis">
              <span className="text-ph-gray pr-4">
                <FontAwesomeIcon icon={faLocationDot} />{" "}
                {t("Plantings.Department")}:
              </span>
              <p title={workshop.department} className="inline">
                {workshop.department}
              </p>
            </div>
          </div>

          <div className="sm:flex mt-1">
            <div className="w-full sm:w-[50%] mt-5 sm:mt-0">
              <span className="text-ph-gray pr-6">
                <FontAwesomeIcon icon={faCalendarDays} />{" "}
                {t("PlantingFilter.To")}:
              </span>
              <p className="w-9 inline px-3">
                {workshop.date_to} {workshop.time_to}
              </p>
            </div>
            <div className="mt-5 sm:mt-0">
              <span className="text-ph-gray">
                <FontAwesomeIcon icon={faUser} />{" "}
                {t("PlantingFilter.Participants")}:
              </span>
              <p className="inline px-3">
                <WorkshopParticipants
                  totalNum={workshop.participants_total}
                  others={workshop.other_participants}
                  psaS={workshop.psa_students}
                  psaT={workshop.psa_tutors}
                  schoolS={workshop.school_students}
                  schoolT={workshop.school_tutors}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {showDelete && (
        <WarningRedAlert
          type={t("Workshops.Workshop")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default WorkshopGrid;
