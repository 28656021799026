import { useTranslation } from "react-i18next";

const WorkshopParticipants: React.FC<{
  totalNum: number;
  psaS: number;
  psaT: number;
  schoolS: number;
  schoolT: number;
  others: number;
  index?: boolean;
  scroll?: boolean;
}> = ({ totalNum, psaS, psaT, schoolS, schoolT, others, index, scroll }) => {
  const { t } = useTranslation();

  return (
    <h3 className="p-tooltip inline">
      {totalNum}
      <table
        className={`p-table absolute z-10 right-[10px] h-fit shadow-lg rounded-md text-center bg-white  py-1  ${
          scroll
            ? "top-[20px]"
            : "bottom-[0px]" && index
            ? "bottom-[20px]"
            : "top-[20px]"
        }`}
      >
        <thead>
          <tr className="border-b border-b-gray-200 font-medium">
            <td>{t("Workshops.Participants of")}</td>
            <td className="pl-6">Nr.</td>
          </tr>
        </thead>
        <tbody className="text-[#939393]">
          <tr>
            <td className="py-2">{t("Workshops.PSA students")}</td>
            <td className="pl-6">{psaS}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.School students")}</td>
            <td className="pl-6">{schoolS}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.PSA tutors")}</td>
            <td className="pl-6">{psaT}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.School teachers")}</td>
            <td className="pl-6">{schoolT}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.Other participants")}</td>
            <td className="pl-6">{others}</td>
          </tr>
        </tbody>
      </table>
    </h3>
  );
};

export default WorkshopParticipants;
