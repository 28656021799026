import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const GeneralAlert: React.FC<{
  alert: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  body: string;
  confirmText?: string;
  cancelText?: string;
}> = ({
  alert,
  handleCancel: propHandleCancel,
  handleConfirm: propHandleConfirm,
  body,
  confirmText,
  cancelText,
}) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (alert) {
      setShowPrompt(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = () => {
    setShowPrompt(false);
    propHandleConfirm();
  };

  const handleCancel = () => {
    setShowPrompt(false);
    propHandleCancel();
  };

  return showPrompt ? (
    <div className="w-full h-full bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 z-50">
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] sm:h-[411x] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[92px] text-ph-orange-alert"
          icon={faTriangleExclamation}
        />
        <h1 className="mt-4 text-[25px] font-bold text-ph-orange-alert">
          {t("Message.Alert")}
        </h1>
        <p className="mt-6 text-ph-dark-gray-text">{body}</p>
        <div className="mt-10">
          {cancelText && (
            <button
              onClick={handleCancel}
              className="px-12 py-3 text-default-text text-sm rounded-3xl font-medium"
            >
              {cancelText}
            </button>
          )}
          {confirmText && (
            <button
              onClick={handleConfirm}
              className="px-12 py-3 bg-ph-orange-alert text-white text-sm rounded-3xl font-medium"
            >
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default GeneralAlert;
