import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import TranslationField from "../../../type/translationField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { LoginSliceActions } from "../../../store/login-slice";
import AddProfilePic from "./AddProfilePic";
import { profileInfo } from "../../../store";
import SelectLanguage from "../../Global/SelectLanguage";
import { SelectChangeEvent } from "@mui/material";
import i18next from "i18next";

type FormValues = {
  fName: string;
  lName: string;
  phone: string;
  address: string;
  email: string;
  role: TranslationField;
  language: string;
};

const EditProfileForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
}> = ({ send, sendResponse, getInfo }) => {
  const { i18n, t } = useTranslation();
  const [profilePicture, setProfilePicture] = useState<any>();
  const [oldProfilePicture, setOldProfilePicture] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [showPopUp, isShowPopUp] = useState<boolean>(false);
  const [uploading, isUploading] = useState<boolean>(false);
  const history = useHistory();
  const profile = useSelector(profileInfo);
  const dispatch = useDispatch();
  const [lang, setLang] = useState<string>(
    getInfo.data.language === "es" ? "ES" : "EN"
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (sendResponse) {
      isUploading(false);
      toast.success(
        `${getInfo ? `${t("Profile.The profile updated successfully")}` : ``}`
      );
      if (newEmail.length !== 0) {
        dispatch(LoginSliceActions.userLogOut());
        history.replace("/");
        history.block(() => {});
      } else {
        dispatch(
          LoginSliceActions.profile({
            email: sendResponse.data.email,
            first_name: sendResponse.data.first_name,
            role: profile.role,
            profile_picture: sendResponse.data.profile_picture,
            is_root_admin: profile.is_root_admin,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setLoading(true);
      setValue("fName", getInfo.data.first_name);
      setValue("lName", getInfo.data.last_name);
      setValue("email", getInfo.data.email);
      setValue("phone", getInfo.data.phone);
      setValue("address", getInfo.data.address);
      setValue("role", {
        en: getInfo.data.role_transes.en,
        es: getInfo.data.role_transes.es,
      });
      setValue("language", lang === "ES" ? "es" : "en");
      setOldProfilePicture(getInfo.data.profile_picture);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const insertProfilePic = (newFile: any) => {
    isShowPopUp(false);
    setProfilePicture(newFile);
    if (newFile) {
      setOldProfilePicture(URL.createObjectURL(newFile));
    } else {
      setOldProfilePicture("");
    }
  };

  const emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value);
  };

  const editProfile = (data: FormValues) => {
    isDirty(false);
    const formData = new FormData();
    if (data.phone && data.phone !== getInfo.data.phone) {
      formData.append("phone", data.phone);
    } else if (!data.phone) {
      formData.append("phone", "");
    }
    if (data.address && data.address !== getInfo.data.address) {
      formData.append("address", data.address);
    } else if (!data.address) {
      formData.append("address", "");
    }
    formData.append("first_name", data.fName);
    formData.append("last_name", data.lName);
    formData.append("email", data.email);
    formData.append("language", lang === "ES" ? "es" : "en");
    if (oldProfilePicture !== getInfo.data.profile_picture) {
      if (profilePicture) {
        formData.append("profile_picture", profilePicture);
      } else if (!profilePicture) {
        formData.append("profile_picture", "");
      }
    }
    isUploading(true);
    send(formData);
    if (lang === "ES") {
      i18next.changeLanguage("es");
    } else {
      i18next.changeLanguage("en");
    }
  };
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(editProfile)}>
          <div
            className="relative flex items-center justify-center rounded-md w-[140px] h-[140px] overflow-hidden group avatar "
            onClick={() => {
              isShowPopUp(true);
            }}
          >
            {profilePicture || oldProfilePicture ? (
              <img
                className="object-contain"
                alt="profile"
                src={oldProfilePicture || URL.createObjectURL(profilePicture)}
              />
            ) : (
              <FontAwesomeIcon
                className="text-[#9D9A9ABD] text-8xl"
                icon={faUser}
              />
            )}
            <div
              className="absolute bottom-1 transition-all transform
                                translate-y-8 opacity-0
                                group-hover:opacity-100
                                group-hover:translate-y-0"
            >
              <p className="text-xs text-white">{t("Message.Edit")}</p>
            </div>
          </div>

          <p className="text-sm text-default-text mb-[6px] mt-6">
            {t("Users.Preferred language")}
          </p>
          <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
          <div className="flex flex-wrap justify-between mt-7">
            <div className="w-1/2 pr-2">
              <p className="text-sm text-default-text mb-[6px] ">
                {t("Users.First name")} *
              </p>
              <input
                {...register("fName", {
                  required: `${t("Message.Required field")}`,
                })}
                type="text"
                placeholder={t("Users.First name") + ""}
                className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
              />
              {errors.fName && (
                <p className="error-text">{errors.fName.message}</p>
              )}
            </div>

            <div className="w-1/2 pl-2">
              <p className="text-sm text-default-text mb-[6px]">
                {t("Users.Last name")} *
              </p>
              <input
                {...register("lName", {
                  required: `${t("Message.Required field")}`,
                })}
                type="text"
                className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
                placeholder={t("Users.Last name") + ""}
              />
              {errors.lName && (
                <p className="error-text">{errors.lName.message}</p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap justify-between mt-7">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p className="text-sm text-default-text mb-[6px] mt-5 sm:mt-0">
                {t("Users.Role")}
              </p>
              <input
                type="text"
                value={
                  i18n.language === "en"
                    ? getValues("role.en")
                    : getValues("role.es")
                }
                readOnly
                className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-3"
              />
            </div>

            <div className="w-full sm:w-1/2 sm:pl-2">
              <p className="text-sm text-default-text mb-[6px] mt-5 sm:mt-0">
                {t("Users.Phone")}
              </p>
              <input
                {...register("phone")}
                type="text"
                className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-ph-light-gray"
                placeholder={t("Users.Phone") + ""}
              />
            </div>
          </div>
          <p className="text-sm text-default-text mb-[6px] mt-7">
            {t("Users.Address")}
          </p>
          <input
            {...register("address")}
            type="text"
            className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-ph-light-gray"
            placeholder={t("Users.Address") + ""}
          />

          <p className="text-sm text-default-text mb-[6px] mt-7">
            {t("Users.Email")} *
          </p>
          <input
            {...register("email", {
              required: `${t("Message.Required field")}`,
            })}
            onChange={emailChange}
            type="text"
            className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-ph-light-gray"
            placeholder={t("Users.Email") + ""}
          />
          {errors.email && <p className="error-text">{errors.email.message}</p>}

          <button
            type="submit"
            className={`${
              uploading ? "green-btn-disable" : "green-btn"
            } px-6 mt-9 py-2 text-sm float-right font-medium`}
          >
            {uploading
              ? t("Message.Wait for upload files")
              : t("Tree Species.Update")}
          </button>
        </form>
      )}
      {showPopUp && (
        <AddProfilePic
          profile_picture={oldProfilePicture}
          closePopUP={() => {
            isShowPopUp(false);
          }}
          insertProfilePic={insertProfilePic}
        />
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default EditProfileForm;
