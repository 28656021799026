import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import GenericTable from "../../Global/GenericTable";
import { ResponsibleRecord } from "../../../type/responsible";
import ResponsibleTableItem from "./ResponsibleTableItem";
import AddResponsible from "../AddResponsible/AddResponsible";
import { toast } from "react-toastify";

const Responsible = () => {
  const [responsibleList, setResponsibleList] = useState<ResponsibleRecord[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { id } = useParams<any>();

  const {
    fetchData: getResponsible,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${id}/responsible_names/?ordering=${ordering}&page=${page}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: addData, response: addResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/${id}/responsible_names/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setResponsibleList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    if (addResponse) {
      getResponsible({});
      toast.success(t("Message.The record added successfully"));
      setShowAddModal(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleAddResponsible = () => {
    setShowAddModal(true);
  };

  const handleModalClose = () => {
    setShowAddModal(false);
  };
  return (
    <div className="p-4 bg-white mt-3 rounded-md w-full">
      <button
        onClick={handleAddResponsible}
        className="mb-5 green-btn px-[23px] py-[10px] text-sm float-right"
      >
        {t("Responsible.Add responsible")}
      </button>
      <GenericTable
        dataList={responsibleList}
        fetchData={() => {
          getResponsible({});
        }}
        loading={loading}
        setOrderingList={order}
        titles={[
          { name: t("Tree Species.Name"), value: "name" },
          {
            name: t("Community.Initials"),
            value: "initials",
          },
          {
            name: t("Register.Category"),
            value: "category",
          },
          { name: t("Tree Species.Type"), value: "type" },
          { name: t("Department.Status"), value: "is_active" },
        ]}
        renderItem={(responsibleRecord, fetchData, lastIndex) => (
          <ResponsibleTableItem
            responsibleItem={responsibleRecord}
            fetchData={fetchData}
            lastIndex={lastIndex}
          />
        )}
      />
      {responsibleList.length > 0 && (
        <div className="flex justify-between items-center mt-4">
          <ThemeProvider theme={TableThem}>
            <TablePagination
              count={Math.ceil(count / 10)}
              handleChange={handleChange}
            />
          </ThemeProvider>
          <p className="text-sm text-default-text">
            {" "}
            {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
              (page - 1) * 10 + responsibleList.length
            } ${t("Message.of")} ${count}`}
          </p>
        </div>
      )}
      <br />
      {showAddModal && (
        <AddResponsible
          handleModalClose={handleModalClose}
          send={addData}
          textButton={t("Responsible.Add responsible")}
          title={t("Responsible.Add responsible")}
        />
      )}
    </div>
  );
};

export default Responsible;
