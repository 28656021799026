import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player";
import { helpVideos } from "../../../type/helpVideos";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { faVideo } from "@fortawesome/pro-light-svg-icons";
import "@vidstack/react/player/styles/base.css";
import i18n from "../../../i18n";

const VideoModal: React.FC<{
  pageName: string;
  needBorder: boolean;
}> = ({ pageName, needBorder }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<number>(0);
  const [videoList, setVideoList] = useState<helpVideos[]>([]);
  const { t } = useTranslation();
  const { response } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/help_videos/?page_name=${pageName}&page=1`,
    "GET",
    true,
    "",
    false,
    false
  );
  useEffect(() => {
    if (response !== null) {
      setVideoList(response.data.results);
    }
  }, [response]);

  let videosLength = videoList.length;

  const handleClick = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      if (openModal) {
        document.body.style.overflow = "auto";
      }
    };
  }, [openModal]);
  return (
    <Fragment>
      {videosLength > 0 && (
        <span>
          <button
            title={t("Help videos.Show help videos") + ""}
            className="cursor-pointer text-ph-btn"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              className="mr-4 w-5 h-[16px] mb-[1px]"
              icon={faQuestion}
            />
          </button>
          {needBorder && (
            <span className="border-l-[1px] solid text-ph-iborder mr-4" />
          )}
        </span>
      )}

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
        >
          <div className="bg-white w-[360px] h-[450px] md:w-[868px] md:h-[610px] overflow-y-scroll scrollbar-hide p-8 rounded-md shadow-md flex flex-col">
            <h2 className="font-semibold mb-8 md:mb-4 text-default-text">
              {t("Help videos.Help videos")}
            </h2>
            <div className="flex md:justify-between flex-col md:flex-row md:overflow-none">
              <span className="flex flex-col cursor-pointer">
                <div
                  className={`${
                    videosLength <= 1
                      ? "md:w-[810px] md:h-[430px]"
                      : "md:w-[598px] md:h-[430px]"
                  } w-[300px] h-[300px]  text-black rounded-md flex items-center shadow-md justify-center`}
                >
                  {ReactPlayer.canPlay(videoList[selectedVideo].url) ? (
                    <ReactPlayer
                      title={
                        i18n.language === "es"
                          ? videoList[selectedVideo].title_transes.es
                          : videoList[selectedVideo].title_transes.en ||
                            videoList[selectedVideo].title_transes.es
                      }
                      url={videoList[selectedVideo].url}
                      controls={true}
                    />
                  ) : (
                    <span className="flex-col w-full h-full bg-ph-bg-white flex items-center justify-center">
                      <FontAwesomeIcon
                        className="text-ph-gray w-[30px] h-[30px] m-2"
                        icon={faVideo}
                      />
                      <span className="text-default-text text-[16px]">
                        {t("Help videos.Can't play current video")}
                      </span>
                    </span>
                  )}
                </div>
                <span className="mt-2 mb-3">
                  <h2 className="text-[14px]">
                    {i18n.language === "es"
                      ? videoList[selectedVideo].title_transes.es
                      : videoList[selectedVideo].title_transes.en ||
                        videoList[selectedVideo].title_transes.es}
                  </h2>
                  <a
                    href={videoList[selectedVideo].url}
                    className="text-[12px] text-default-text"
                  >
                    {window.innerWidth >= 700
                      ? videoList[selectedVideo].url
                      : videoList[selectedVideo].url.substring(0, 40)}
                  </a>
                </span>
              </span>
              <span
                className={`${
                  videosLength <= 1 ? "hidden" : "flex"
                } md:flex-col flex-row overflow-x-scroll md:overflow-x-clip  mt-3 items-center justify-start`}
              >
                {videoList.map((video, index) => (
                  <span
                    key={index}
                    className="flex flex-col justify-center w-[175px] h-[140px] mb-4 cursor-pointer mr-2 "
                    onClick={() => setSelectedVideo(index)}
                  >
                    <span className="w-[174px] h-[96px] text-black rounded-md shadow-md">
                      {video.url.includes("shorts") ||
                      !video.url.includes("youtube") ||
                      !video.url.includes("watch") ? (
                        <span className="w-full h-full bg-ph-bg-white flex items-center justify-center">
                          <FontAwesomeIcon
                            className="text-ph-gray w-[30px] h-[30px]"
                            icon={faVideo}
                          />
                        </span>
                      ) : (
                        <MediaPlayer
                          className="w-[174px] h-[96px] text-black rounded-md shadow-md "
                          title={
                            i18n.language === "es"
                              ? video.title_transes.es
                              : video.title_transes.en || video.title_transes.es
                          }
                          src={video.url}
                          controls={false}
                        >
                          <MediaProvider />
                        </MediaPlayer>
                      )}
                    </span>

                    <div className="mt-1">
                      <h2 className="text-[14px]">
                        {i18n.language === "es"
                          ? video.title_transes.es
                          : video.title_transes.en || video.title_transes.es}
                      </h2>
                      <a
                        href={video.url}
                        className=" text-[12px] text-default-text opacity-100"
                      >
                        {video.url.substring(0, 24)}
                      </a>
                    </div>
                  </span>
                ))}
              </span>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
export default VideoModal;
