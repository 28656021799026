import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { communityFilters } from "../../../store";
import { communityFilterAction } from "../../../store/communityFilter-slice";
import AppliedItem from "../../Register/RegisterList/AppliedItem";

const CommunityAppliedFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(communityFilters);

  return (
    <div className="bg-white p-4 rounded-md relative mt-3">
      <h1 className="text-ph-gray font-medium text-sm">
        {t("Tree Species.Applied Filters")}
      </h1>
      <div className="flex flex-wrap items-center">
        {filters.isActive && (
          <AppliedItem
            type={t("Workshops.Workshop status")}
            value={
              filters.isActive === "true"
                ? t("Department.Active")
                : t("Department.Inactive")
            }
            removeFilter={() => {
              dispatch(communityFilterAction.removeStatus());
              dispatch(communityFilterAction.setURL());
              dispatch(communityFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.department && (
          <AppliedItem
            type={t("PlantingFilter.Department")}
            value={filters.department.name}
            removeFilter={() => {
              dispatch(communityFilterAction.removeDepartment());
              dispatch(communityFilterAction.setURL());
              dispatch(communityFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.municipality && (
          <AppliedItem
            type={t("Community.Municipality")}
            value={filters.municipality.name}
            removeFilter={() => {
              dispatch(communityFilterAction.removeMunicipality());
              dispatch(communityFilterAction.setURL());
              dispatch(communityFilterAction.isFilterSet());
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CommunityAppliedFilter;
