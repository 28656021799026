import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddWorkshopForm from "../AddWorkshop/AddWorkshopForm";
import { Department } from "../../../type/department";
import EditSlider from "../../Global/EditSlider";
import Loading from "../../Global/Loading";

const EditWorkshops = () => {
  const [departments, setDepartment] = useState<Department[]>([]);
  const { id } = useParams<any>();

  const { fetchData: editWorkshop, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/workshop/${id}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const {
    response: getEditResponse,
    fetchData: getWorkshop,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/workshop/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { t } = useTranslation();

  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (departmentRes) {
      setDepartment(departmentRes.data);
    }
  }, [departmentRes]);

  useEffect(() => {
    getWorkshop({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Workshops.Edit workshop")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          {
            name: t("Workshops.Workshops"),
            url: "/workshops",
          },
          {
            name: t("Workshops.Edit workshop"),
            url: "",
          },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddWorkshopForm
              departments={departments}
              send={editWorkshop}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
            />
          </div>
          <EditSlider
            images={["/img/Add Workshop1.svg", "/img/Add Workshop2.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default EditWorkshops;
