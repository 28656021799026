import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { plantingFilterSet, plantingURL, profileInfo } from "../../../store";
import EventFilterBox from "./PlantingFilterBox";
import { faGrid2, faListUl } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlantingAppliedFilter from "./PlantingAppliedFilter";
import useAxios from "../../../hook/useAxios";
import { Planting } from "../../../type/planting";
import { TableThem } from "../../../styles/TableThem";
import { ThemeProvider } from "@emotion/react";
import PlantingGrid from "./PlantingGrid";
import TablePagination from "../../Global/TablePagination";
import SearchField from "../../Global/SearchField";
import Export from "../../ExportLists/Export";
import Loading from "../../Global/Loading";
import PlantingTableItem from "./PlantingTableItem";
import GenericTable from "../../Global/GenericTable";
import VideoModal from "../../HelpVideos/listVideos/VideoModal";

const Plantings = () => {
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const [gridView, isGridView] = useState<boolean>(true);
  const [plantingList, setPlantingList] = useState<Planting[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const url = useSelector(plantingURL);
  const isFilter = useSelector(plantingFilterSet);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (loading && plantingList.length > 0) {
      setIsLoaded(false);
    } else {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setPlantingList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleLoading = () => {
    setExportLoading(!exportLoading);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Plantings.Plantings")}
        </h1>
        <div>
          <VideoModal pageName="pe" needBorder={false} />
          <Export
            url={url}
            name="plantings"
            title={t("Plantings.Export plantings") + ""}
            handleLoading={handleLoading}
            needBorder={true}
          />
          {(profile.role === "A" || profile.role === "C") && (
            <Link
              to="/plantings/add-planting"
              className="green-btn px-[23px] py-[10px] text-sm font-medium"
            >
              {t("Plantings.Add planting")}
            </Link>
          )}

          <span
            onClick={() => {
              isGridView(!gridView);
            }}
            title={
              gridView
                ? t("Workshops.Switch to list view") + ""
                : t("Workshops.Switch to grid view") + ""
            }
            className="ml-3 cursor-pointer text-ph-btn"
          >
            <FontAwesomeIcon icon={gridView ? faListUl : faGrid2} size="lg" />
          </span>
        </div>
      </div>

      <EventFilterBox />
      {isFilter && <PlantingAppliedFilter />}

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        {gridView ? (
          <div className="flex justify-between flex-wrap">
            {plantingList.length > 0 ? (
              plantingList.map((planting: Planting) => (
                <PlantingGrid
                  plantings={planting}
                  key={planting.slug}
                  fetchData={() => {
                    fetchData({});
                  }}
                />
              ))
            ) : (
              <Loading loading={loading} />
            )}
          </div>
        ) : (
          <GenericTable
            dataList={plantingList}
            fetchData={() => {
              fetchData({});
            }}
            loading={loading}
            setOrderingList={order}
            titles={[
              { name: t("Tree Species.Name"), value: "name" },
              { name: t("Plantings.Date of planting"), value: "date" },
              { name: t("Plantings.Department"), value: "department" },
              { name: t("Plantings.Municipality"), value: "municipality" },
              { name: t("Plantings.Community"), value: "community" },
              { name: t("Plantings.Organizer(s)"), value: "" },
              {
                name: t("Plantings.Planted tree(s)"),
                value: "planted_trees",
              },
              {
                name: t("Plantings.Registered tree(s)"),
                value: "registered_trees",
              },
              {
                name: t("PlantingFilter.Participants"),
                value: "participants",
              },
            ]}
            renderItem={(planting, fetchData, lastIndex) => (
              <PlantingTableItem
                planting={planting}
                fetchData={fetchData}
                lastIndex={lastIndex}
              />
            )}
          />
        )}

        {plantingList.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-default-text">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + plantingList.length
              } ${t("Message.of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
      {exportLoading && (
        <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
          <Loading loading={true} />
        </div>
      )}
      {!isLoaded && (
        <div className="w-full h-full z-[9990] bg-white bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
          <Loading loading={true} fullSize={true} />
        </div>
      )}
    </div>
  );
};

export default Plantings;
