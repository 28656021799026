import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { communityFilterSet, communityURL } from "../../../store";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import GenericTable from "../../Global/GenericTable";
import useAxios from "../../../hook/useAxios";
import { CommunityRecord } from "../../../type/communityRecord";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import CommunityFilterBox from "./CommunityFilterBox";
import CommunityTableItem from "./CommunityTableItem";
import CommunityAppliedFilter from "./CommunityAppliedFilter";

const Community = () => {
  const [communityList, setCommunityList] = useState<CommunityRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const url = useSelector(communityURL);
  const isFilter = useSelector(communityFilterSet);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setCommunityList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Community.Communities")}
        </h1>
        <Link
          to="/setting/communities/add-community"
          className="green-btn px-[23px] py-[10px] text-sm"
        >
          {t("Community.Add community")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Community.Communities"), url: "" },
        ]}
      />
      <CommunityFilterBox />
      {isFilter && <CommunityAppliedFilter />}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          dataList={communityList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Community.Community"), value: "community" },
            { name: t("PlantingFilter.Department"), value: "department" },
            { name: t("Community.Municipality"), value: "municipality" },
            { name: t("Community.Initials"), value: "initials" },
            { name: t("Department.Status"), value: "is_active" },
          ]}
          renderItem={(community, fetchData, lastIndex) => (
            <CommunityTableItem
              community={community}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        {communityList.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-default-text">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + communityList.length
              } ${t("Message.of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default Community;
