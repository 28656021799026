import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const PasswordStrength: React.FC<{ strengthNum: number; password: string }> = ({
  strengthNum,
  password,
}) => {
  const { t } = useTranslation();
  const criteria = (
    <span className="info-icon">
      <FontAwesomeIcon icon={faCircleInfo} className="text-ph-gray" />
      <span className="info-text">
        <span className="flex items-start">
          {password.length > 7 ? (
            <FontAwesomeIcon
              className="text-ph-greenValBar text-lg mr-3"
              icon={faCheck}
            />
          ) : (
            <FontAwesomeIcon
              className="text-ph-red-warning text-lg mr-3"
              icon={faXmark}
            />
          )}
          <p>{t("Login.Your password must contain at least 8 characters.")} </p>
        </span>
        <span className="flex items-start mt-2">
          {/^(?=.*[@$!%*#?&])/.test(password) ? (
            <FontAwesomeIcon
              className="text-ph-greenValBar text-lg mr-3"
              icon={faCheck}
            />
          ) : (
            <FontAwesomeIcon
              className="text-ph-red-warning text-lg mr-3"
              icon={faXmark}
            />
          )}
          <p>
            {t(
              "Login.Your password must contain at least one special character."
            )}
          </p>
        </span>
        <span className="flex items-start mt-2">
          {/^(?=.*?[0-9])/.test(password) ? (
            <FontAwesomeIcon
              className="text-ph-greenValBar text-lg mr-3"
              icon={faCheck}
            />
          ) : (
            <FontAwesomeIcon
              className="text-ph-red-warning text-lg mr-3"
              icon={faXmark}
            />
          )}
          <p>{t("Login.Your password must contain at least one number.")} </p>
        </span>
      </span>
    </span>
  );
  return (
    <Fragment>
      {strengthNum === 0 && (
        <Fragment>
          <div className="w-[45px] h-1 inline-block rounded-2xl mr-1 bg-ph-red-warning"></div>
          <div className="w-[45px] h-1 rounded-2xl mr-1 bg-ph-red-warning hidden sm:inline-block"></div>
          <p className="text-sm text-ph-red-warning float-right">
            {t("Login.Weak")}
            {criteria}
          </p>
        </Fragment>
      )}
      {strengthNum > 0 && strengthNum < 5 && (
        <Fragment>
          <div className="w-[45px] h-1 inline-block rounded-2xl mr-1 bg-default-highlight"></div>
          <div className="w-[45px] h-1 inline-block rounded-2xl mr-1 bg-default-highlight"></div>
          <div className="w-[45px] h-1 rounded-2xl mr-1 bg-default-highlight hidden sm:inline-block"></div>
          <div className="w-[45px] h-1 rounded-2xl mr-1 bg-default-highlight hidden sm:inline-block"></div>
          <p className="text-sm text-default-highlight float-right">
            {t("Login.Medium")}
            {criteria}
          </p>
        </Fragment>
      )}
      {strengthNum === 5 && (
        <Fragment>
          <div className="w-[45px] h-1 inline-block rounded-2xl mr-1 bg-ph-greenValBar"></div>
          <div className="w-[45px] h-1 inline-block rounded-2xl mr-1 bg-ph-greenValBar"></div>
          <div className="w-[45px] h-1 inline-block rounded-2xl mr-1 bg-ph-greenValBar"></div>
          <div className="w-[45px] h-1 rounded-2xl mr-1 bg-ph-greenValBar hidden sm:inline-block"></div>
          <div className="w-[45px] h-1 rounded-2xl mr-1 bg-ph-greenValBar hidden sm:inline-block"></div>
          <div className="w-[45px] h-1 rounded-2xl mr-1 bg-ph-greenValBar hidden sm:inline-block"></div>
          <p className="text-sm text-ph-greenValBar float-right">
            {t("Login.Strong")}
            {criteria}
          </p>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PasswordStrength;
