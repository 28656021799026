import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import SpeciesList from "../../../type/speciesList";
import BreadCrumb from "../../Global/BreadCrumb";
import AddRegisterForm from "../AddRegister/AddRegisterForm";
import EditSlider from "../../Global/EditSlider";
import Loading from "../../Global/Loading";
import PlantingList from "../../../type/PlantingList";
import { Tags } from "../../../type/tag";
import { ResponsibleNames } from "../../../type/responsibleNames";

const EditRegister = () => {
  const [selectOption, setSelectOption] = useState<{
    treeSpecies: SpeciesList[];
    incidents: SpeciesList[];
    nursery: SpeciesList[];
    plantings: PlantingList[];
    tags: Tags[];
    resName: ResponsibleNames[];
  }>({
    treeSpecies: [],
    incidents: [],
    nursery: [],
    plantings: [],
    tags: [],
    resName: [],
  });

  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const {
    response: getEditResponse,
    fetchData: getRegister,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const [slugs, setSlugs] = useState<{
    plantingSlug: string;
    communitySlug: string;
  }>({
    plantingSlug: "",
    communitySlug: "",
  });
  const [tagUnknown, setTagUnknown] = useState<boolean>(
    getEditResponse?.data?.is_tag_unknown
  );

  const {
    fetchData: unknownFetch,
    response: unknownRes,
    error,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/${id}/edit/${tagUnknown}/`,
    "PATCH",
    false,
    "",
    true,
    true
  );

  const { fetchData: getEventId, response: breadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${getEditResponse?.data?.planting_slug}/breadcrumb-data/?register_slug=${id}`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { response: treeSpeciesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: plantingAllRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/plantings/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: originRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/origins/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: tagsRes, fetchData: tagsData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${slugs.plantingSlug}/tags/all/`,
    "GET",
    false,
    "",
    true,
    true
  );
  const { response: responsibleRes, fetchData: responsibleData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${slugs.communitySlug}/responsible_names/all`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (treeSpeciesRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          treeSpecies: treeSpeciesRes.data,
        };
      });
    }
    if (plantingAllRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          plantings: plantingAllRes.data,
        };
      });
    }
    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incidents: incidentRes.data,
        };
      });
    }
    if (originRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          nursery: originRes.data,
        };
      });
    }
    if (tagsRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          tags: tagsRes.data,
        };
      });
    }
    if (responsibleRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          resName: responsibleRes.data,
        };
      });
    }
  }, [
    treeSpeciesRes,
    incidentRes,
    originRes,
    plantingAllRes,
    responsibleRes,
    tagsRes,
  ]);

  useEffect(() => {
    getRegister({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getEditResponse) {
      getEventId({});
      setSlugs({
        plantingSlug: getEditResponse?.data?.planting_slug,
        communitySlug: getEditResponse?.data?.community_slug,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditResponse]);

  useEffect(() => {
    if (slugs?.plantingSlug && slugs?.communitySlug) {
      tagsData({});
      responsibleData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugs]);

  const handleUnknownChange = (unknown: boolean) => {
    setTagUnknown(unknown);
  };

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Register.Edit register")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Register.Register"), url: "/registers/all" },
          {
            name: `${breadCrumb?.data.date}-${
              breadCrumb?.data.department_name
            }-${
              i18n.language === "en"
                ? breadCrumb?.data.species_name_transes.en ||
                  breadCrumb?.data.species_name_transes.es
                : breadCrumb?.data.species_name_transes.es
            }-${breadCrumb?.data.unique_id}`,
            url: "",
          },
          { name: t("Register.Edit register"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddRegisterForm
              selectOptions={selectOption}
              send={unknownFetch}
              sendResponse={unknownRes}
              sendError={error}
              getInfo={getEditResponse}
              handleUnknown={handleUnknownChange}
            />
          </div>
          <EditSlider
            images={["/img/p1.svg", "/img/p2.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default EditRegister;
