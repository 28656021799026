import { Fragment, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import AddOrganizerName from "./AddOrganizerName";
import { colors } from "../../../styles/colors";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon sx={{ color: colors.green_checkbox }} fontSize="small" />
);

const Orginizer: React.FC<{
  selectOptions: {
    department: Department[];
    psa: Department[];
    ins: Department[];
    ind: Department[];
  };
  orginizer: { type: string; collaborators: Department[] };
  index: number;
  addOrginizer: (type: string, value: Department) => void;
  typeChange: (index: number, value: string) => void;
  nameChange: (index: number, value: Department[]) => void;
  indSelect: (value: boolean) => void;
  isIndSelect: boolean;
  showError: boolean;
}> = ({
  selectOptions: selectOption,
  orginizer,
  typeChange,
  nameChange,
  indSelect,
  showError,
  isIndSelect,
  index,
  addOrginizer,
}) => {
  const [addCollaboratorName, isAddCollaboratorName] = useState<boolean>(false);
  const { t } = useTranslation();
  const indData = selectOption.ind;
  const insData = selectOption.ins;
  const psaData = selectOption.psa;
  const addNewOrginizerName = (type: string, value: Department) => {
    addOrginizer(type, value);
    let name: Department[] = [];
    if (type === "ind") {
      name = [...orginizer.collaborators, value];
    } else {
      name = [value];
    }
    nameChange(index, name);
  };
  return (
    <div className="flex flex-wrap justify-between mt-5">
      <div className="w-full sm:w-1/2 md:pr-2">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Tree Species.Type")} *
        </p>
        <FormControl style={{ width: "100%" }}>
          <InputLabel shrink={false}>
            {orginizer.type === "" && t("Tree Species.Type")}
          </InputLabel>
          <Select
            sx={{
              border: "1px solid #eeecec",
              borderRadius: "4px",
              "& fieldset": { border: "none" },
            }}
            style={{ height: "48px" }}
            IconComponent={ExpandMoreIcon}
            variant="outlined"
            value={orginizer.type}
            onChange={(e) => {
              if (!isIndSelect || e.target.value !== "ind") {
                typeChange(index, e.target.value);
              }
              if (e.target.value === "ind") {
                indSelect(true);
              }
              if (orginizer.type === "ind" && e.target.value !== "ind") {
                indSelect(false);
              }
            }}
          >
            <MenuItem value="psa">{t("Register.PSA group")}</MenuItem>
            <MenuItem value="ind">{t("Register.Individual")}</MenuItem>
            <MenuItem value="ins">{t("Register.Institution")}</MenuItem>
          </Select>
        </FormControl>
        {showError && !orginizer.type && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
      </div>
      <div className="w-full sm:w-1/2 sm:pl-2 mt-7 sm:mt-0">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Tree Species.Name")} *
        </p>
        {orginizer.type === "ind" && (
          <Autocomplete
            popupIcon={
              <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />
            }
            multiple={true}
            limitTags={1}
            id="checkboxes-tags-demo"
            options={indData}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(prop, option, { selected }) => (
              <Fragment>
                {option.slug === indData[0].slug && (
                  <div className="px-7 border-b border-ph-iborder">
                    <button
                      onClick={() => isAddCollaboratorName(true)}
                      className="text-sm w-full items-center flex justify-between text-ph-btn py-2 "
                    >
                      {t("Plantings.Create new organizer")}
                      <FontAwesomeIcon className="ml-2" icon={faPlus} />
                    </button>
                  </div>
                )}
                <li {...prop} key={option.slug}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              </Fragment>
            )}
            sx={{
              width: "100%",
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
              "& .Mui-focused": {
                outline: "1px solid #efbf07",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: "1px solid #eeecec",
                  borderRadius: "4px",
                  "& fieldset": { border: "none" },
                }}
                placeholder={
                  orginizer.collaborators?.length === 0
                    ? t("Tree Species.Name") + ""
                    : ""
                }
              />
            )}
            onChange={(event, newValue) => {
              nameChange(index, newValue);
            }}
            value={orginizer.collaborators}
          />
        )}
        {orginizer.type !== "ind" && (
          <Autocomplete
            popupIcon={
              <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />
            }
            id="combo-box-demo"
            disabled={orginizer.type === "" ? true : false}
            options={orginizer.type === "psa" ? psaData : insData}
            getOptionLabel={(option) => option.name}
            sx={{
              width: "100%",
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
              "& .Mui-focused": {
                outline: "1px solid #efbf07",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                sx={{
                  border: "1px solid #eeecec",
                  borderRadius: "4px",
                  "& fieldset": { border: "none" },
                }}
                placeholder={t("Tree Species.Name") + ""}
              />
            )}
            onChange={(event, newValue) => {
              nameChange(index, [newValue as Department]);
            }}
            renderOption={(prop, option, { selected }) => (
              <Fragment>
                {(option.slug === psaData[0].slug ||
                  option.slug === insData[0].slug) && (
                  <div className="px-4 border-b border-ph-iborder">
                    <button
                      onClick={() => isAddCollaboratorName(true)}
                      className="text-sm w-full items-center flex justify-between text-ph-btn py-2 "
                    >
                      {t("Plantings.Create new organizer")}
                      <FontAwesomeIcon className="ml-2" icon={faPlus} />
                    </button>
                  </div>
                )}
                <li {...prop} key={option.slug}>
                  {option.name}
                </li>
              </Fragment>
            )}
            value={orginizer?.collaborators[0] || null}
          />
        )}
        {showError && !orginizer.collaborators[0] && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
      </div>
      {addCollaboratorName && (
        <AddOrganizerName
          addOrganizer={addNewOrginizerName}
          closeAddOrganizer={() => {
            isAddCollaboratorName(false);
          }}
          openModal={addCollaboratorName}
          organizerType={orginizer.type}
        />
      )}
    </div>
  );
};

export default Orginizer;
