import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type responsibleFilter = {
  category: string;
  isActive: string;
};
const initialState: {
  filterItem: responsibleFilter;
  url: string;
  filterSet: boolean;
  deleteFilter: "category" | "isActive" | "";
} = {
  filterItem: {
    category: "",
    isActive: "",
  },
  url: "/responsible/?",
  filterSet: false,
  deleteFilter: "",
};

const responsibleFilterSlice = createSlice({
  name: " responsibleFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<responsibleFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    setURL: (state) => {
      state.url = `/responsible/?category=${
        state.filterItem.category || ""
      }&is_active=${state.filterItem.isActive}`;
    },
    removeCategory: (state) => {
      state.filterItem.category = "";
      state.deleteFilter = "category";
    },
    removeIsActive: (state) => {
      state.filterItem.isActive = "";
      state.deleteFilter = "isActive";
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.category = "";
      state.filterItem.isActive = "";
    },
    isFilterSet: (state) => {
      if (!state.filterItem.isActive && !state.filterItem.category) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const responsibleFilterAction = responsibleFilterSlice.actions;
export default responsibleFilterSlice;
