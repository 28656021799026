import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "@mui/material";

const Warning: React.FC<{
  when: boolean;
  onOK: () => boolean;
  onCancel: () => boolean;
}> = ({ when, onCancel, onOK }) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>("");
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute && currentPath !== history.location.pathname) {
        history.block(() => {});
        history.push(currentPath);
      } else {
        setShowPrompt(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      open={showPrompt}
      onClose={() => setShowPrompt(false)}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] sm:h-[435px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[92px] text-ph-orange-alert"
          icon={faTriangleExclamation}
        />
        <h1 className="mt-4 text-[25px] font-bold text-ph-orange-alert">
          {t("Message.Warning")}!
        </h1>
        <p className="mt-6">
          {t("Message.Are you sure you want to leave before saving?")}
        </p>
        <div className="mt-7 border-t border-gray-300 pt-8">
          <button
            onClick={handleCancel}
            className="mr-2 px-12 py-3 text-sm font-medium"
          >
            {t("Message.Stay")}
          </button>
          <button
            onClick={handleOK}
            className="px-12 py-3 bg-ph-orange-alert text-white text-sm rounded-3xl font-medium"
          >
            {t("Message.Leave")}
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default Warning;
