import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddUserForm from "./AddUserForm";

const AddUser = () => {
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/users/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Users.Add user")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Users.Users"), url: "/setting/users" },
          { name: t("Users.Add user"), url: "" },
        ]}
      />

      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddUserForm
            send={fetchData}
            sendResponse={response}
            textButton={t("Users.Add user")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/user.svg"
            alt="Add user"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddUser;
